import React,{useEffect} from 'react';
import { useAppSelector } from '@/hooks/redux';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import FormGroup from '@/components/molecules/FormGroup';
 

interface LoginProps {
  onSubmit: (params?: any) => any;
}
interface FormValues {
  email: string;
  
}
interface MyFormProps {
  initialEmail?: string;
   
}

 

const InnerForm = ({ handleSubmit, setFieldValue, values, errors, touched }: FormikProps<FormValues>) => {

 
 

  useEffect(() => {
  
    
    

  }, []);

  const { loading, error } = useAppSelector(state => state.user);

  return (
    <>
    <Form noValidate onSubmit={handleSubmit}  >
        <div className="mb-3">
     
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Por favor ingrese su correo electrónico. 
                      </label>
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                         <strong> A continuación recibirá en su bandeja de entrada un correo con el código de acceso para utilizar en el siguiente paso.  </strong>
                      </label>
                      <FormGroup
                              controlId="formCIEmail"
                              inputType="Control"
                              type="text"
                              placeholder="Email"
                              value={values.email}
                              onChange={e => setFieldValue('email', e?.target.value)}
                              isValid={touched.email && !errors.email}
                              isInvalid={!!errors.email}
                              error={errors.email}
                            />
                  </div>
                  
 
       
       
      {!loading && error && <div className="invalid-feedback d-block mb-3">{error}</div>}

        <div className="mb-3 d-grid">
          <Button variant="primary" type="submit" className="btn btn-primary"> 
          Solicitar Clave Temporal
          </Button>
        </div>
        </Form>
      </>
  );
};

const ForgottPassword: React.FC<LoginProps> = ({ onSubmit }) => {
  const MyFormWithFormik = withFormik<MyFormProps, FormValues>({

    
    mapPropsToValues: props => ({
      email: props.initialEmail || '',
    }),

    validationSchema: Yup.object().shape({
      email: Yup.string().email('El email no es válido').required('El email es obligatorio'),
  
    }),

    handleSubmit({ email  }: FormValues) {
      onSubmit({ Email: email  });
    },
  })(InnerForm);

  return <MyFormWithFormik />;
};

export default ForgottPassword;
