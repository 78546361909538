import React, {  useEffect, useState, useRef } from 'react';
import { format } from 'date-fns';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useNavigate} from 'react-router-dom';
 
import {
  getMisEventos,MisEventosData 
} from '@/store/eventos/eventosActions';
 

const MisActividades: React.FC = () => {
 
 
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const dropdownRefs = useRef<HTMLDivElement[]>([]);

  const dataMisEventos = useAppSelector((state) => state.eventos?.itemsMisData);
 
  useEffect(() => {
    dispatch(getMisEventos({}));
  }, [dispatch]);
  
  const [showDropdown, setShowDropdown] = useState<number | null>(null);
  const [openDropdown, setOpenDropdown] = useState<boolean[]>([]);


  const handleDropdownToggle = (index: number) => {
    setOpenDropdown((prevOpenDropdown) => {
      const updatedOpenDropdown = [...prevOpenDropdown];
      updatedOpenDropdown[index] = !prevOpenDropdown[index];
      return updatedOpenDropdown;
    });
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      for (let i = 0; i < dropdownRefs.current.length; i++) {
        if (
          dropdownRefs.current[i] &&
          !dropdownRefs.current[i].contains(event.target as Node)
        ) {
          setOpenDropdown((prevOpenDropdown) => {
            const updatedOpenDropdown = [...prevOpenDropdown];
            updatedOpenDropdown[i] = false;
            return updatedOpenDropdown;
          });
        }
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);
  return (
    <>
    {/* Breadcrumb */}
    <section className="container d-none d-lg-block mt-2">
      <div className="row">
        <div className="col-12">
          <div className="border-top py-2">
            <nav style={{}} aria-label="breadcrumb" className="d-none d-md-block">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Inicio</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Mis Actividades
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>
    {/* Content */}
    <section className="container mt-2 mb-2  mt-md-2 mb-md-5">
      <div className="row align-items-center">
        <div className="col-md-6">
          <h1 className="display-4 mb-4">Mis Actividades</h1>
        </div>
        <div className="col-md-6 text-md-end">
          <a href="#" className="btn btn-primary">
            Ver todas las actividades online
          </a>
        </div>
        <div className="col-md-12">
          <table className="table table-hover text-primary">
            <thead>
              <tr>
                <th scope="col">Actividad</th>
                <th scope="col">Progreso</th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
        
              {dataMisEventos &&  dataMisEventos.evento_usuarios.map((data, index) => (  
                 <tr  key={`e_${data.IdEvento}_${index}`} >
                      <td>{data.evento.Titulo}</td>
                      <td>
                      { data.Pago==0?("No Procesado"): 
                          data.Paso==0   ? ("No Iniciado"): 
                          (
                            <div
                            ref={(el) => (dropdownRefs.current[index] = el as HTMLDivElement)}
                            className={`dropdown ${openDropdown[index] ? 'show' : ''}`}
                          >
                          <button
                            className="btn btn-link dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded={openDropdown[index] ? 'true' : 'false'}
                            onClick={() => handleDropdownToggle(index)}
                          >
                             {(data.Finalizado)?("Actividad Finalizada"):`${data.Paso} Unidades Completas`}
                            
                          </button>
                          <ul className={`dropdown-menu ${openDropdown[index] ? 'show' : ''}`}>

                          {data.evento.evento_unidades &&  data.evento.evento_unidades.map((data2:any, index2:any) => (  
                            <li>
                              <a key={`v_${data2.IdEventoUnidad}_${index}_${index2}`} className="dropdown-item" href={`/unidades/${data2.IdEvento}?p=${data2.Orden}`}>
                                Paso {data2.Orden} - Ver video
                              </a>
                            </li>
                          ))}
                          </ul>
                        </div>)}
                      </td>
                    
                      <td className="text-end">
                      {data.Finalizado==0 && data.Pago!=0?

                        (  data.Paso==0)?
                        (
                          <a className="btn btn-link" href={`/unidades/${data.IdEvento}`}>
                            Iniciar Actividad
                          </a>):
                          (
                        <a className="btn btn-link" href={`/unidades/${data.IdEvento}`}>
                          Continuar Actividad
                        </a>)
                        : <a className="btn btn-link" style={{padding: "1em"}} >
                      {' '}
                      </a>}
                      </td>
              </tr>
              ))} 
              
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </>
  
 
  );
};

export default MisActividades;
