import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import env from '@beam-australia/react-env';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useNavigate } from 'react-router-dom';
import {
  EventoData,
  getEventoById
} from '@/store/eventos/eventosActions';
import { parseCopete, parseCuerpo, slugify, parseFecha } from '@/components/utils';

const EventoDetalle: React.FC = () => {


  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { eventoId } = useParams();
  const dataEvento = useAppSelector((state) => state.eventos?.itemIdData);
  const { userInfo } = useAppSelector(state => state.user);

  useEffect(() => {
    if (eventoId) {
      dispatch(getEventoById({ id: parseInt(eventoId.split("-")[0]) }));
    }
  }, [dispatch]);

  const viewEvento = (item: EventoData) => {
    
    let path = `/evento-inscripcion-01/${item.IdEvento}-${slugify(item.Titulo)}`;
    const isOnline = dataEvento?.Modalidad === 'Online'
    if (!userInfo && isOnline) {
      path = '/registro?back=' + `/evento-inscripcion-02/${item.IdEvento}-${slugify(item.Titulo)}`
    }

    if (isOnline && userInfo)
    {
      path = `/evento-inscripcion-02/${item.IdEvento}-${slugify(item.Titulo)}`;
    }

    navigate(path);
  }



  return (

    <>
      {/* Breadcrumb */}
      <section className="container d-none d-lg-block mt-2">
        <div className="row">
          <div className="col-12">
            <div className="border-top py-2">
              <nav style={{}} aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Inicio</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/actividades">Actividades</a>
                  </li>
                 
                  <li className="breadcrumb-item active" aria-current="page">
                    {(dataEvento) ? dataEvento.Titulo : ''}

                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* Header */}
      {dataEvento && (
        <>
          <header className="container my-2 my-md-5">
            <div className="row g-4">
              <div className="col-md-12">
                <h1 className="display-4 mb-4">
                  {dataEvento.Titulo}
                </h1>
                {dataEvento.Modalidad !== 'Online' && (
                <a
                  className="btn btn-outline-secondary icon-calendar me-1 mb-2"
                  href="#"
                  role="button"
                >
                  {(dataEvento.Fecha) ? parseFecha(dataEvento.Fecha.toString()) : ''}
                </a>)}
                <a
                  className={
                    dataEvento.Modalidad === 'Online'
                      ? 'btn btn-outline-secondary   icon-video  me-1 mb-2'
                      : dataEvento.Modalidad === 'Presencial'
                        ? 'btn btn-outline-secondary   icon-presencial  me-1 mb-2'
                        : dataEvento.Modalidad === 'Video'
                          ? 'btn btn-outline-secondary   icon-video  me-1 mb-2'
                          : 'btn btn-outline-secondary   '
                  }
                  href="#"
                  role="button"
                >
                  {dataEvento.Modalidad==='Video'?'Zoom':dataEvento.Modalidad}
                </a>

                {dataEvento.Oradores && dataEvento.Oradores.split(';').map(dataOrador => (

                  <a
                    className="btn btn-outline-secondary icon-speaker me-1 mb-2"
                    href="#"
                    role="button"
                  >
                    {dataOrador}
                  </a>
                ))}


                <p className="lead my-4" dangerouslySetInnerHTML={{ __html: parseCopete(dataEvento) }} />
                <hr />
              </div>
            </div>
          </header>
          <section className="container mb-5">
            <div className="row g-4">
              <div className="col-md-6 d-flex align-items-stretch">
                <div className="card" style={{ width: '100%' }}>
                  {dataEvento.imagenes.length > 0 && (
                    <img

                      src={`${String(env('BASE_IMAGENES_URL'))}/${dataEvento.imagenes[0].Imagen}`}
                      className="card-img-top img-fluid"
                      alt="..."
                    />
                  )}
                  <div className="card-body">
                    <h2 className="card-title">
                      {dataEvento.Titulo}
                    </h2>
                    <ul className="evento-info">
                      {dataEvento.Modalidad !== 'Online' && (
                      <li className="icon-calendar d-flex align-items-center my-1">
                        {(dataEvento.Fecha) ? parseFecha(dataEvento.Fecha.toString()) : ''}
                      </li>)}
                      <li
                        className={
                          dataEvento.Modalidad === 'Online'
                            ? 'icon-video d-flex align-items-center my-1 '
                            : dataEvento.Modalidad === 'Presencial'
                              ? 'icon-presencial d-flex align-items-center my-1'
                              : dataEvento.Modalidad === 'Video'
                                ? 'icon-video d-flex align-items-center my-1'
                                : 'icon-video d-flex align-items-center my-1'
                        }
                      >
                         {dataEvento.Modalidad==='Video'?'Zoom':dataEvento.Modalidad}
                      </li>
                      {dataEvento.Oradores && dataEvento.Oradores.split(';').map(dataOrador => (
                        <li className="icon-speaker d-flex align-items-center my-1">
                          {dataOrador}
                        </li>
                      ))}

                    </ul>
                    {dataEvento.evento_sociales && dataEvento.evento_sociales.length>0 && dataEvento.evento_sociales[0].Cupo > 0  &&(
                    <button type="button"
                      onClick={() => viewEvento(dataEvento)}
                      className="btn btn-primary icon-subscribe">
                      inscribirse a la actividad
                    </button>
                      )}

                {dataEvento.evento_sociales &&  dataEvento.evento_sociales.length>0 &&  dataEvento.evento_sociales[0].Cupo == 0  &&(
                     <div className=" d-flex align-items-center my-1">
                      El cupo de este evento está completo
                    </div>
                      )}
                     {(!dataEvento.evento_sociales || dataEvento.evento_sociales.length==0)  &&(
                    <button type="button"
                      onClick={() => viewEvento(dataEvento)}
                      className="btn btn-primary icon-subscribe">
                      inscribirse a la actividad
                    </button>
                      )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-stretch">
                <div>
                  <p dangerouslySetInnerHTML={{ __html: parseCuerpo(dataEvento) }} >
                  </p>
 
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>

  );
};

export default EventoDetalle;
