import React from 'react';
import Form from 'react-bootstrap/Form';
import FormInput from '@/components/atoms/FormInput';

interface FormGroupProps {
  controlId: string;
  label?: string;
  inputType: string;
  type: string;
  placeholder?: string;
  value: string | string[] | boolean | number | undefined;
  isValid?: boolean | undefined;
  isInvalid?: boolean | undefined;
  readOnly?: boolean | undefined;
  error?: string | undefined;
  onChange?: (e?: React.ChangeEvent<HTMLInputElement>) => void | Promise<any>;
}

interface OptionalsProps {
  label?: string;
  isValid?: boolean;
  isInvalid?: boolean;
  readOnly?: boolean;
}

const FormGroup: React.FC<FormGroupProps> = ({
  controlId,
  label,
  inputType = 'Control',
  type = 'text',
  placeholder = '',
  value = '',
  isValid = false,
  isInvalid = false,
  readOnly = false,
  error,
  onChange,
}) => {
  const props: OptionalsProps = {};
  if (label) {
    props.label = label;
  }
  if (isValid) {
    props.isValid = isValid;
  }
  if (isInvalid) {
    props.isInvalid = isInvalid;
  }
  if (readOnly) {
    props.readOnly = readOnly;
  }

  return (
    <Form.Group
      className={`${label ? 'form-floating' : ''} mb-3${inputType === 'Check' ? ' d-inline-block' : ''}`}
      controlId={controlId}>
      {inputType !== 'Check' && label ? (
        <Form.FloatingLabel label={label} className="mb-3">
          <FormInput inputType={inputType} type={type} placeholder={placeholder} value={value} onChange={onChange} {...props} />
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.FloatingLabel>
      ) : inputType !== 'Check' ? (
        <>
          <FormInput inputType={inputType} type={type} placeholder={placeholder} value={value} onChange={onChange} {...props} />
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </>
      ) : (
        <FormInput inputType={inputType} type={type} placeholder={placeholder} value={value} onChange={onChange} {...props} />
      )}
    </Form.Group>
  );
};

export default FormGroup;
