import React, {  useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { logoutUser } from '@/store/user/userActions';

const MyNav2: React.FC   = () => {
  const navigate = useNavigate();
  const { userInfo } = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();
  const [isOpen, setisOpen] = useState(false);
  const [isOpenMenu, setisOpenMenu] = useState(false);

  const menuClass = `dropdown-menu${isOpen ? " show" : ""}`;
  const menuMenuClass = `collapse navbar-collapse flex-column${isOpenMenu ? " show" : ""}`;
  const toggleOpen = () => setisOpen( !isOpen);
  const toggleOpenMenu = () => setisOpenMenu( !isOpenMenu);

  return (
  <>
  {/*Navbar Dark*/}
  <nav
    className="navbar navbar-expand-lg navbar-dark bg-gradient-black"
    aria-label="Navbar Humano Puente"
  >
    <div className="container">
      <a href="/">
        <img
          src="./img/hp-logo_w.svg"
          alt="Humano Puente"
          className="nav-logo ms-2 ms-lg-0"
        />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        onClick={toggleOpenMenu}
        data-bs-toggle="collapse"
        data-bs-target="#navbarHumanoPuente"
        aria-controls="navbarHumanoPuente"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div
       
        className={menuMenuClass}
      >
        <div className="container">
          <div className="row text-center text-lg-end mt-3 mb-0 mt-lg-2">
            <div className="col">
              
              {!userInfo?
               <div className="d-grid d-lg-inline gap-2">
                <button
                   onClick={() => {window.location.href = '/login';}}
                  type="button"
                  className="btn btn-sm btn-outline-light icon-login"
                >
                  Iniciar Sesión
                </button>
              </div>
              : <div className="d-grid d-lg-inline gap-2">
              <button type="button" 
                onClick={() => {window.location.href = '/misactividades';}}
              className="btn btn-link link-light me-lg-2">
                Mis Actividades
              </button>
              <button type="button" 
                     onClick={() => {dispatch(logoutUser({})); window.location.href = '/';}}
                className="btn btn-sm btn-outline-light icon-logout">
                Cerrar Sesión
              </button>
            </div>}
              <ul className="list-inline nav-social d-none d-lg-inline ms-2">
                <li className="list-inline-item">
                <a     href="https://www.facebook.com/apalmazan/" target="_blank">
                  <button type="button" className="btn btn-link link-light">
                    <i className="bi bi-facebook" />
                  </button>
                  </a>
                </li>
                <li className="list-inline-item">
                 <div className="dropdown" onClick={toggleOpen} >
                    <button
                      className="btn btn-link link-light dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="bi bi-instagram" />
                    </button>
                    <ul className={menuClass}>
                      <li>
                        <a className="dropdown-item text-primary"    target="_blank"  href="https://instagram.com/pabloalmazanhumanopuente">
                          <i className="bi bi-instagram" /> Pablo Almazán
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item text-primary"     target="_blank" href="https://www.instagram.com/lucreciabianchi_humanopuente/">
                          <i className="bi bi-instagram" /> Lucrecia Bianchi
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="list-inline-item">
                      <a
                        className="btn btn-link link-light"
                        href="https://www.tiktok.com/@humanopuente/"
                        target="_blank"
                        role="button"
                      >
                        <i className="bi bi-tiktok" />
                      </a>
                </li>
                <li className="list-inline-item">
                  <a     target="_blank" href="https://wa.me/+5491124980681">
                    <button type="button" className="btn btn-link link-light">
                      <i className="bi bi-whatsapp" />
                    </button>
                  </a>
                </li>
                <a href="#"></a>
                <li className="list-inline-item">
                  <a href="#" />
                  <a  href="https://www.youtube.com/@humanopuente"    target="_blank">
                    <button type="button" className="btn btn-link link-light">
                      <i className="bi bi-youtube" />
                    </button>
                  </a>
                </li>
                <a href="#"></a>
                <li className="list-inline-item">
                  <a href="#" />
                  <a  href="https://open.spotify.com/show/2RR5yIdRaz0NzBQe8kTFQ2?si=d56fa760f3654585"    target="_blank">
                    <button type="button" className="btn btn-link link-light">
                      <i className="bi bi-spotify" />
                    </button>
                  </a>
                </li>
                <a href="#"></a>
              </ul>
              <a href="#"></a>
            </div>
            <a href="#"></a>
          </div>
          <a href="#"></a>
        </div>
        <a href="#"></a>
        <ul className="navbar-nav ms-auto mb-2 mb-md-0 text-center">
          <a href="#"></a>
          <li className="nav-item">
            <a href="#"></a>
            <a className="nav-link" href="https://comunidad.humanopuente.com.ar/" target="_blank">
            Comunidad
          </a>
          </li>
          <li className="nav-item">
                <a className="nav-link" href="/campus"  >
                  Campus
                </a>
              </li>
          <li className="nav-item">
            <a className="nav-link" href="/actividades">
              Actividades
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/consultores">
              Consultores
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/empresas">
              Empresas y Economía
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/video">
              Videos
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/contacto">
              Contacto
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</>
 );
  
};

export default MyNav2;
