import { initMercadoPago, Wallet } from "@mercadopago/sdk-react";
import env from '@beam-australia/react-env';
initMercadoPago(`${String(env('MP_PUBLIC_KEY'))}`);

interface MercadoPagoButtonProps {
  preferenceId: string;
}

const MercadoPagoButton: React.FC<MercadoPagoButtonProps> = ({ preferenceId }) => {
  return <Wallet  locale="es-AR" initialization={{ preferenceId }} />
};


export default MercadoPagoButton;
