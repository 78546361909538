import { createAsyncThunk } from '@reduxjs/toolkit';
import { ValidationErrors } from '@/interfaces/common';
import { axiosData } from '@/axios';

import type { AxiosError, AxiosResponse } from 'axios';


const modulePrefix = '/libros';

export interface LibroData {
  imagen:  string;
  id:  number;
  nombre:  string;
  precio: number;
  descripcion:  string;
  link_version_ebook:  string;
  link_envio_al_exterior:  string;
  autor:  string;
  video:  string;
}

export interface LibroResponse {
  data: LibroData[];
}

export interface LibroProps {
  order?: string;
  activo?: number;
  id?: number;
}

export const getLibros  = createAsyncThunk(
  `${modulePrefix}/getLibros`,
  async ({ order, activo }: LibroProps, { rejectWithValue }) => {
    try {
      const data: AxiosResponse = await axiosData.get(`/libros`, {

      });
      const result: LibroResponse = data.data;
      return result;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);


export const getLibrosById = createAsyncThunk(
  `${modulePrefix}/getLibrosById`,
  async ({ id }: LibroProps, { rejectWithValue }) => {
    try {
      const data: AxiosResponse = await axiosData.get(`libros/${id!.toString()}`, {
      });
      const result: LibroData = data.data.item;
      return result;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);


