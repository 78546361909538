import React, {  useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useParams } from 'react-router-dom';
import {
  getConsultorById,
  ConsultorData  
} from '@/store/consultores/consultoresActions';

const Consultor: React.FC = () => {
  const { consultorId } = useParams();
  const dispatch = useAppDispatch();
  const dataConsultor = useAppSelector((state) => state.consultores?.consultorIdData);
  localStorage.setItem("consultores","true");
    
  useEffect(() => {
    if (consultorId)
    {
     dispatch(getConsultorById({idConsultor: parseInt(consultorId.split("-")[0])}));
    }
  }, [dispatch]);
 

  return (
    <>
  {/* Breadcrumb */}
  <section className="container d-none d-lg-block mt-2">
    <div className="row">
      <div className="col-12">
        <div className="border-top py-2">
          <nav style={{}} aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Inicio</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/consultores">Consultores</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
              {(dataConsultor)?dataConsultor.Nombre:''}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </section>
  {/* Header */}
  <section className="container my-2 my-md-5">
    <div className="row g-4">
      <div className="col-4 col-md-1">
        <img
          src="./img/consultor_profile.jpg"
          className="border border-1 rounded-circle img-fluid"
          alt=""
        />
      </div>
      { dataConsultor &&  (  
        <>
      <div className="col-md-6 text-start" >
        <h1>{dataConsultor.Nombre}</h1>
        <ul className="profile-info">
          <li className="icon-location d-flex align-items-center my-1">
          {dataConsultor.localidad?dataConsultor.localidad.Nombre:''}, {dataConsultor.provincia?dataConsultor.provincia.Nombre:''}, {dataConsultor.pais?dataConsultor.pais.Nombre:''}
          </li>
          <li className="icon-phone d-flex align-items-center my-1">
          {dataConsultor.Telefono}
          </li>
          <li className="icon-mail d-flex align-items-center my-1">
          {dataConsultor.Email}
          </li>
        </ul>
      </div>
      <div className="col-12">
        <hr />
      </div>
      <div className="col-12 mb-5">
        {dataConsultor &&  dataConsultor.certificados?.map(certificado => (  

        <a
          className="btn btn-outline-secondary me-1 mb-2"
          href="#"
          key={certificado.Nombre}
          role="button"
        >
          {certificado.Nombre}
        </a>
       ))} 
      </div>
      </>
      )}
    </div>
  </section>
</>


 
  );
};

export default Consultor;
