import React, {  useEffect, useState } from 'react';
 
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useNavigate} from 'react-router-dom';
import {
  getVideos,
} from '@/store/videos/videosActions';
import EmpresaForm from '@/components/organisms/EmpresaForm';
 

const Video: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
 
  const dataVideos = useAppSelector((state) => state.videos?.itemsData);
 

  useEffect(() => {
    dispatch(getVideos({activo:1}));
  }, [dispatch]);


  return (
    <>
    {/* Breadcrumb */}
    <section className="container d-none d-lg-block mt-2">
      <div className="row">
        <div className="col-12">
          <div className="border-top py-2">
            <nav style={{}} aria-label="breadcrumb" className="d-none d-md-block">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Inicio</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Videos
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>
    {/* Header */}
    <header className="container my-2 my-md-3">
      <div className="row text-center mb-5">
        <div className="col-md-12">
          <h1 className="display-3 mb-4">Videos</h1>
          <p className="lead mb-5">
            <a href="https://www.youtube.com/@humanopuente" target="_blank">
              <i className="bi bi-youtube" /> Suscribite a nuestro canal de
              YouTube
            </a>
          </p>
        </div>
        {dataVideos &&  dataVideos.data.map(data => (  
        <div  key={data.IdVideo}  className="col-12 col-sm-6 col-md-4 mb-4">
          <div className="embed-container border rounded">
            <iframe
              src={data.video_url}
              frameBorder={0}
         
            />
          </div>
        </div>
          ))} 
      </div>
    </header>
  </>
  );
};

export default Video;
