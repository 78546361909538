import React, { useState, useEffect } from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { logoutUser } from '@/store/user/userActions';
import  MyNav from '@/components/templates/MyNav';
import { NavItemProps } from '@/interfaces/navbar';
import NavbarCollapse from '@/components/molecules/NavbarCollapse';
import logo from '@/assets/img/hp_iso.svg';

import NavItem from '@/components/atoms/NavItem';
import MyNavLight from '../templates/MyNavLight';

interface HeaderProps {
  Light?: boolean;
}


const Header: React.FC<HeaderProps> = ({ Light = false }) => {
  const dispatch = useAppDispatch();

  const [navItems, setNavItems] = useState<NavItemProps[]>([]);

  const { loading, error, userInfo } = useAppSelector(state => state.user);

   
 
  return (
    <> 
        {(Light)?<MyNavLight/>:<MyNav/>}
    </>
  );
};

export default Header;
