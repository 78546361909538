import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Alert, Button, Container, Form } from 'react-bootstrap';
import { Navigate, useNavigate } from 'react-router-dom';
import { empresaUser } from '../../store/user/userActions';
 
import { reset, resetUserCreate } from '@/store/user/userSlice';
import * as Yup from 'yup';
import TabNavigation from '../molecules/TabNavigation';

export interface NewUserValues {
  nombre: string;
  telefono: string;
  pais: string;
  email: string;
  mensaje: string;
}

const EmpresaForm = () => {
  const initialValues: NewUserValues = { 
    nombre:'',
    telefono:'',
    pais: '',
    email:'',
    mensaje:'',
   };
  const dispatch = useAppDispatch();
  const { newEmpresaError, newEmpresaLoading, newEmpresaSuccess } = useAppSelector(state => state.user);
  const [addError, setAddError] = useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Este campo es obligatorio')
      .email('Este campo tiene que ser un email')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    nombre: Yup.string()
      .required('Este campo es obligatorio')
      .matches(/^([^0-9]*)$/, 'Ingresa un formato correcto')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    telefono: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    pais: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    mensaje: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
  });

  useEffect(() => {
    if (newEmpresaError) {
      setAddError(true);
    }
    if (newEmpresaSuccess) {
      formik.resetForm();
      formik.setValues(initialValues);
      setAddSuccess(true);
    
     }
  }, [newEmpresaError, newEmpresaSuccess]);

  const formik = useFormik({
    initialValues,
    onSubmit: async values => {
      setAddError(false);
      setAddSuccess(false);
      await dispatch(empresaUser(values));
    },
    validationSchema: validationSchema,
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: false,
  });
 

  return (
    <>
      <Container>
        
     
          <div className="row g-4">
            
                   <Form.Group className="col-md-12">
                        <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Nombre de contacto{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Form.Control
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.nombre ? true : false}
                              required
                              onChange={formik.handleChange}
                              placeholder="Nombre"
                              value={formik.values.nombre}
                              name="nombre"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.nombre}</Form.Control.Feedback>
                      </Form.Group>
  
                     <Form.Group className="col-md-12">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Email de contacto{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Form.Control
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.email ? true : false}
                              required
                              placeholder="Teléfono"
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              name="email"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
                      </Form.Group>

                   

                       <Form.Group className="col-md-12">
                            <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Teléfono {" "}
                                <span className="text-danger">*</span>
                            </label>
                            <Form.Control
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.telefono ? true : false}
                              required
                              placeholder="Teléfono "
                              value={formik.values.telefono}
                              onChange={formik.handleChange}
                              name="telefono"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.telefono}</Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="col-md-12">
                            <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                País  {" "}
                                <span className="text-danger">*</span>
                            </label>
                            <Form.Control
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.pais ? true : false}
                              required
                              placeholder="País"
                              value={formik.values.pais}
                              onChange={formik.handleChange}
                              name="pais"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.pais}</Form.Control.Feedback>
                      </Form.Group>
                      
                       <Form.Group className="col-md-12">
                       <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                               Contanos en que te podemos ayudar{" "}
                                <span className="text-danger">*</span>
                            </label>
                                <Form.Control
                                  onBlur={formik.handleBlur}
                                  isInvalid={formik.errors.mensaje ? true : false}
                                  required
                                  as="textarea"
                                  rows={6}
                                  maxLength={2000}
                                  value={formik.values.mensaje}
                                  placeholder="Mensaje"
                                  onChange={formik.handleChange}
                                  name="mensaje"
                                />
                                <Form.Control.Feedback type="invalid">{formik.errors.mensaje}</Form.Control.Feedback>
                          </Form.Group>
                 
               
                  <div className="col d-grid mb-5">
                        <Button className="btn btn-primary"   onClick={() => formik.handleSubmit()}>
                        Enviar
                        </Button>
                  </div>
        
        </div>
        {addSuccess && (
          <Alert variant="success" dismissible onClose={() => setAddSuccess(false)}>
            <Alert.Heading>¡Muchas Gracias!</Alert.Heading>
            <p>Se envio su consulta correctamente</p>
          </Alert>
        )}
        {addError && (
          <Alert variant="danger" dismissible onClose={() => setAddError(false)}>
            <Alert.Heading>¡Ups, algo salió mal!</Alert.Heading>
            <p>Intenta nuevamente</p>
          </Alert>
        )}

      </Container>
      {newEmpresaLoading && (
        <div>Aguarde un instante...</div>
      )}
    </>
  );
};

export default EmpresaForm;
