import React from 'react';
import Header from '@/components/organisms/Header';
import Footer from '@/components/organisms/Footer';

interface LayoutProps {
  children: JSX.Element;
}

const Layout: React.FC<LayoutProps> = ({ children }) => (
  <div  >
    <Header />
    <main>{children}</main>
    <Footer />
  </div>
);

export default Layout;
