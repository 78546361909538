import { createSlice } from '@reduxjs/toolkit';
import { EmpresaResponse, getDatosEmpresasById, EmpresaData } from './datosEmpresasActions';

interface InitialState {
  itemsSuccess: boolean;
  itemsData: undefined | EmpresaResponse;
  itemsLoading: boolean;
  itemsError: boolean;

  itemIdSuccess: boolean;
  itemIdData: undefined | EmpresaData;
  itemIdLoading: boolean;
  itemIdError: boolean;

}

const initialState: InitialState = {
  itemsSuccess: false,
  itemsData: undefined,
  itemsLoading: false,
  itemsError: false,

  itemIdSuccess: false,
  itemIdData: undefined,
  itemIdLoading: false,
  itemIdError: false,
};

const DatosEmpresasSlice = createSlice({
  name: 'DatosEmpresas',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getDatosEmpresasById.rejected, (state: InitialState) => {
      state.itemIdError = true;
      state.itemIdLoading = false;
      state.itemIdSuccess = false;
      state.itemIdData = undefined;
    });
    builder.addCase(getDatosEmpresasById.pending, (state: InitialState) => {
      state.itemIdError = false;
      state.itemIdSuccess = true;
      state.itemIdLoading = false;
    });
    builder.addCase(getDatosEmpresasById.fulfilled, (state: InitialState, action) => {
      state.itemIdData = action.payload;
      state.itemIdError = false;
      state.itemIdSuccess = false;
      state.itemIdLoading = true;
    });
  },
});

export default DatosEmpresasSlice.reducer;
