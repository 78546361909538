import React, { useCallback } from 'react';
import { loginUser } from '@/store/user/userActions';
import { LoginUserProps } from '@/interfaces/user';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import LoginForm from '@/components/organisms/LoginForm';
import MyNav2 from '@/components/templates/MyNav2';
 

const Somos: React.FC = () => {
  const dispatch = useAppDispatch();

  const { loading, userInfo } = useAppSelector(state => state.user);

  const handleLogin = useCallback((values: LoginUserProps) => dispatch(loginUser(values)), [dispatch]);

  return (
 <>
<header className="somos">

<MyNav2/>

  {/* Breadcrumb */}
  <section className="container d-none d-md-block">
    <div className="row">
      <div className="col-12">
        <div className="border-top py-2">
          <nav style={{}} aria-label="breadcrumb">
            <ol className="breadcrumb breadcrumb-light">
              <li className="breadcrumb-item">
                <a href="/">Inicio</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Somos Humano Puente
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </section>
  {/* Titulo */}
  <section className="container text-white">
    <div className="row mt-5 justify-content-center">
      <div className="col-lg-8 my-0 my-md-5 text-center">
        <h1 className="display-3 mt-5">Somos Humano Puente</h1>
        <p className="lead mb-5">
          Un Humano Puente es simplemente un ser que, gracias a sus dolencias,
          al haber escuchado el mensaje profundo de sus síntomas, ha logrado
          sanar y nacer a una nueva existencia, la de su propia creación.
        </p>
      </div>
    </div>
  </section>
</header>
 
 <section className="container py-0 py-md-5">
  <div className="row justify-content-center">
    <div className="col-lg-8 text-center text-primary my-5">
      <h6>¿Quienes somos?</h6>
      <h1>
        Es un honor que estés aquí.
        <br />
        Bienvenid@ a Humano Puente.
      </h1>
    </div>
    <div className="col-lg-8 mb-5">
      <p>
        Es probable que hayas llegado aquí porque deseas hacer este profundo
        camino para ser un/a Humano Puente, pero también es probable que hayas
        llegado debido a que estás transitando un síntoma, una dificultad, una
        historia de vida no deseada. Y si eso que parece tan malo no lo fuera? Y
        si es simplemente que hasta ahora no has sabido interpretar lo que estas
        situaciones han venido a decirte? Si sólo deseas dejar atrás tu síntoma
        escuchando su mensaje podemos acompañarte, pero puedo asegurarte que un
        síntoma viene a algo mucho mas bello. Viene a devolverte ese plan
        original de vida que has perdido en el camino debido a que por
        superviviencia has renunciado a tu propia escencia.
      </p>
      <p>
        Humano Puente tiene un profundo propósito que ya está manifestándose, el
        de ser una familia global, una familia de seres que, habiendo llegado
        desde distintas situaciones, síntomas, dolencias, historias de vidas
        difíciles, clanes que han transitado grandes dificultades y otras
        experiencias, han logrado despertar a su propia existencia. Seres que se
        reconocen un fractal de la gran mente.
      </p>
      <p>
        Ya son mas de DOS MIL los Consultores que, desde diferentes países, se
        han acercado a este lugar para convertirse de actores u observadores en
        verdaderos directores de su propia obra, de su propia vida y, a través
        de ese paso, tambén muchos están ya ayudando a más y más seres de
        diferentes lugares de la Madre Tierra a sanar sus síntomas, sus
        historias, sus clanes y sus cuerpos. TODO ESTE CAMINO SE PUEDE HACER
        ONLINE.
      </p>
      <p>
        Humano Puente pretende no ser sólo un lugar de tránsito sino una casa de
        encuentro que sigue acompañando a los Humanos Puente que hacen su camino
        aquí en los pasos que ellos requieran, a través de diferentes acciones
        de apoyo y promoción de sus Consultores, creando en foros especiales
        para los Consultores de Humano Puente lo que ya consideramos la más
        completa enciclopedia de este conocimiento jamás escrita. Un lugar donde
        todos los Consultores interactuan pidiendo y brindando asesoramiento a
        sus colegas y compartiendo sus experiencias de Consulta DESDE TODO EL
        MUNDO!. También los acompañamos al lanzar los libros de Pablo Almazán
        libros junto, divulgando sus consultas maravillosas, sus casos de
        sanación, esos que llamamos milagros lógicos. Para nosotros un Consultor
        es un Humano Puente hijo de la Madre Tierra que viene a ejercer su rol
        sagrado, el de reconocerse un Ser Humano, un trozo de la Madre Tierra
        que ha tomado las riendas de su propia creación y ahora puede ayudar a
        sus espejos para que éstos, a su vez, se sanen y lo sigan sanando. Otro
        punto bello y sinérgico es que los Consultores que lo desean son también
        distribuidores de los libros de Humano Puente, generando un vínculo con
        la gente de su lugar que potencia su intercambio.
      </p>
      <p>
        Para Pablo Almazán y Lucrecia Bianchi esto es un viejo sueño, un sueño
        que alguien, como parte de él mismo en algún campo energético y momento
        soñó y que ahora, simplemente sintonizando con ese deseo, ese lugar, del
        que seguramente él y todos somos parte, ha logrado convertir en
        realidad. Ver cada vez más seres ayudando y despertando a un nuevo nivel
        de conciencia, gracias al profundo mensaje de los síntomas, es un gran
        milagro dentro de los milagros lógicos. Como solemos decir refiriéndonos
        a todos aquellos que han llegado luego de experiencias tristes, síntomas
        dolorosos, problemas con los hijos, con la salud, con el dinero, con las
        parejas y hoy disfrutan de sus nuevas vidas y dan la posibilidad a
        tantas personas de reconectar con ellos mismos, TODOS podemos hacer
        milagros y eso es simplemente porque al venir todos del mismo lugar, al
        ser verdaderamente UN SOLO SER, es imposible que seamos distintos.
      </p>
      <p>
        Si llegaste a este lugar por un síntoma, por tu deseo de ya dejarlo
        atrás, la invitación que te haremos es mucho más profunda, te proponemos
        que escuches el mensaje que tu síntoma viene a traerte y que compruebes,
        como lo han hecho ya miles de seres, que un síntoma nunca puede
        equivocarse, que viene desde tu inconsciente y tu ser más profundo, que
        porta mensajes ancestrales, (desde tus padres hasta las estrelas son tus
        ancestros, recuérdalo) que viene a que despiertes y recuerdes quien
        eres: Un Ser Humano que vino a trascender historias muy viejas,
        distorsiones que han quedado escalones arriba en el clan y que hoy
        generan las situaciones que llamamos difíciles. Un ser que se declara UN
        CREADOR de su propio Universo y que asume ese rol comenzando a crear
        conscientemente su realidad.
      </p>
      <p>
        ¿Sabías que aquí, en Humano Puente, a la enfermedad la llamamos
        Informedad?
      </p>
      <p>
        ¿Sabías que aquí llamamos síntoma a todo aquello que hace que una vida
        sea vivida como no plena?
      </p>
      <p>
        ¿Sabías que hemos comprobado en TODOS los casos que todo síntoma es un
        recuerdo no sanado?
      </p>
      <p>
        Será un placer que seas parte de esta enorme red que ya abarca más de 20
        países. La Madre Tierra necesita de seres despiertos que la acompañen en
        este hermoso proceso de despertar que desde Lemuria y Rapa Nui resuena.
        La manera de sanar el Universo individual es sanando todo lo que hay en
        él, sanándonos.
      </p>
      <p>Gracias y bienvenid@s.</p>
    </div>
  </div>
</section>

</>

  );
};

export default Somos;
