

import React from 'react';

const ConsultorCard: React.FC = () => {


  return (
    <div className="card placeholder-glow">
      <div className="card-header d-flex align-items-center p-3">
        <div className="col-12">
          <p className="card-text">
            <span className="placeholder col-12"></span>
          </p>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12">
            <p className="card-text">
              <span className="placeholder col-12"></span>
            </p>
          </div>
        </div>
      </div>
      <div className="card-footer pb-4 pt-4">
        <div className="row">
          <div className="col-2">
                <span className="placeholder col-12"></span>
          </div>
          <div className="col-2">
                <span className="placeholder col-12"></span>
          </div>
          <div className="col-8 text-end">
              <span className="placeholder col-12"></span>
          </div>
        </div>
      </div>
    </div>


  );
};

export default ConsultorCard;




