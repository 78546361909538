import React,{useEffect} from 'react';
import { useAppSelector } from '@/hooks/redux';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import FormGroup from '@/components/molecules/FormGroup';
 

interface LoginProps {
  onSubmit: (params?: any) => any;
}
interface FormValues {
  email: string;
  password: string;
  rememberMe: boolean;
  OS: string;
  Browser: string;
  Language: string;
  TimeZone: string;
  ScreenPrint: string;
  LocalStorage: string;
  CPU: string;
  OSVersion: string;
}
interface MyFormProps {
  initialEmail?: string;
  initialPassword?: string;
  initialRememberMe?: boolean;
  initialOS?: string;
  initialBrowser?: string;
  initialLanguage?: string;
  initialTimeZone?: string;
  initialScreenPrint?: string;
  initialLocalStorage?: string;
  initialCPU?: string;
  initialOSVersion?: string;
}

const detectBrowser = () => {
  const userAgent = window.navigator.userAgent;
  const browsers = ['Chrome', 'Firefox', 'Safari', 'Opera', 'IE', 'Edge'];
  
  for (const browser of browsers) {
    if (userAgent.includes(browser)) {
      return browser;
    }
  }
  return 'Unknown';
};

const getOsversion  = () => {
  const userAgent = window.navigator.userAgent;
    const windowsMatch = userAgent.match(/Windows NT ([0-9.]+)/);
    if (windowsMatch) {
      return windowsMatch[1];
    }

    const macMatch = userAgent.match(/Mac OS X ([0-9._]+)/);
    if (macMatch) {
      return macMatch[1].replace(/_/g, '.');
    }

    if (userAgent.includes('Linux')) {
      return 'Linux';
    }

    return 'Unknown';
};


const InnerForm = ({ handleSubmit, setFieldValue, values, errors, touched }: FormikProps<FormValues>) => {

 
 

  useEffect(() => {
  
    
    setFieldValue('OS', window.navigator.platform);
    setFieldValue('Browser', detectBrowser());
    setFieldValue('Language', window.navigator.language);
 
    const timeZoneMatch = new Date().toString().match(/([A-Z]+[-][0-9]+)/);
    setFieldValue('TimeZone', timeZoneMatch ? timeZoneMatch[1] : 'Unknown');
    setFieldValue('OSVersion',getOsversion());
    setFieldValue('ScreenPrint',`${window.screen.width}x${window.screen.height}`);
    const localStorageSupports = typeof window.localStorage !== 'undefined';
    setFieldValue( 'LocalStorage', localStorageSupports);
    setFieldValue( 'CPU', navigator.hardwareConcurrency || 'N/A');

   

  }, []);

  const { loading, error } = useAppSelector(state => state.user);

  return (
    <>
    <Form noValidate onSubmit={handleSubmit}  >
        <div className="mb-3">
     
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Nombre de usuario
                      </label>
                      <FormGroup
                              controlId="formCIEmail"
                              inputType="Control"
                              type="text"
                              placeholder="Email"
                              value={values.email}
                              onChange={e => setFieldValue('email', e?.target.value)}
                              isValid={touched.email && !errors.email}
                              isInvalid={!!errors.email}
                              error={errors.email}
                            />
                  </div>
          <div className="col-md-12 mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Contraseña
                      </label>
                      <FormGroup
                            controlId="formPassword"
                            inputType="Control"
                            type="password"
                            value={values.password}
                            placeholder="Contraseña"
                            onChange={e => setFieldValue('password', e?.target.value)}
                            isValid={touched.password && !errors.password}
                            isInvalid={!!errors.password}
                            error={errors.password}
                          />
                    
          </div>
       
       
      {!loading && error && <div className="invalid-feedback d-block mb-3">{error}</div>}

        <div className="mb-3 d-grid">
          <Button variant="primary" type="submit" className="btn btn-primary"> 
          Enviar
          </Button>
        </div>
        </Form>
      </>
  );
};

const LoginForm: React.FC<LoginProps> = ({ onSubmit }) => {
  const MyFormWithFormik = withFormik<MyFormProps, FormValues>({

    
    mapPropsToValues: props => ({
      email: props.initialEmail || '',
      password: props.initialPassword || '',
      rememberMe: props.initialRememberMe || false,
      OS: props.initialOS || '',
      Browser: props.initialBrowser || '',
      Language: props.initialLanguage || '',
      TimeZone: props.initialTimeZone || '',
      ScreenPrint: props.initialScreenPrint || '',
      LocalStorage: props.initialLocalStorage || '',
      CPU: props.initialCPU || '',
      OSVersion: props.initialOSVersion || '',
    }),

    validationSchema: Yup.object().shape({
      email: Yup.string().email('El email no es válido').required('El email es obligatorio'),
      password: Yup.string().required('El campo es requerido').min(6, 'La contraseña debe tener como minimo 6 digitos'),
    }),

    handleSubmit({ email, password, rememberMe, OS,Browser, Language, TimeZone, ScreenPrint,  LocalStorage,CPU, OSVersion  }: FormValues) {
      onSubmit({ Email: email, Contrasenia: password, rememberMe, OS : OS,Browser, Language, TimeZone, ScreenPrint,  LocalStorage,CPU, OSVersion });
    },
  })(InnerForm);

  return <MyFormWithFormik />;
};

export default LoginForm;
