import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import env from '@beam-australia/react-env';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useNavigate } from 'react-router-dom';
import { getLibrosById, LibroData } from '@/store/libros/librosActions';


const LibroCompraGracias: React.FC = () => {


  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { libroId } = useParams();
  const dataLibro = useAppSelector((state) => state.libros?.itemIdData);
  const { userInfo } = useAppSelector(state => state.user);
  const params = new URLSearchParams(window.location.search);
  const idtransaccion = params.get('id');
   

  useEffect(() => {
    if (libroId) {
      dispatch(getLibrosById({ id: parseInt(libroId.split("-")[0]) }));
    }

   

  }, [dispatch, libroId]);


  return (
    <>
      {/* Breadcrumb */}
      <section className="container d-none d-lg-block mt-2">
        <div className="row">
          <div className="col-12">
            <div className="border-top py-2">
              <nav style={{}} aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Inicio</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/libros">Libros de Pablo Almazán</a>
                  </li>
                  <li className="breadcrumb-item active"> {(dataLibro) ? dataLibro.nombre : ''}</li>

                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* Header */}
      {dataLibro && (
        <>
          <header className="container my-2 my-md-3">
            <div className="row g-4">
              <div className="col-md-12">
                <h1 className="display-4 mb-4">
                  {dataLibro.nombre}
                </h1>
                <hr />
              </div>
            </div>
          </header>
          <section className="container mb-5">
            <div className="row g-4">
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <h6 className="text-secondary">Libro</h6>
                    <h2 className="card-title">
                      {dataLibro.nombre}
                    </h2>
                    <hr />
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="card p-2">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-8">
                        <h6 className="text-primary">Compra de libro</h6>
                      </div>
                      <div className="col-4 text-end text-muted">
                        <h6>Paso 3 de 3</h6>
                      </div>
                    </div>
                  </div>
                 {(idtransaccion) ? (
                    <div className="card-body py-5">
                    <h1 className="mb-5 text-primary">Gracias por tu compra.</h1>
                    <p>
                    Gracias
Su compra fue confirmada correctamente. Numero de transacción <strong>{idtransaccion}</strong> 
 
                      
                    </p>
                    <p>
                    En unas horas nos comunicaremos con usted. Por cualquier consulta comuniquese a  
                   
                    </p>
                    <p>
                    <a href="mailtohplibros@gmail.com">
                      hplibros@gmail.com
                      </a>
                    </p>
                    </div>

                 ) : (
                  <div className="card-body py-5">
                    <h1 className="mb-5 text-primary">Gracias por tu compra.</h1>
                    <p>
                      Gracias, le recordamos que le enviamos un mail con los datos para
                      realizar el pago.
                    </p>
                    <p>
                      Por favor, luego de realizar un pago recuerde enviar los datos del
                      mismo e imagen del comprobante a{" "}
                      <a href="mailto:organizacionhp@gmail.com">
                        organizacionhp@gmail.com
                      </a>
                      .
                    </p>
                    <p>
                      <strong>Nota:</strong> Recuerde que cuenta con 7 días para realizar
                      el pago de su inscripción.
                    </p>
                  </div>
                  )}
                  <div className="row">
          
                    <div className="col-12 mb-3 d-grid">
                      <a href="/" className="btn btn-primary">
                        Finalizar
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </>
      )}
    </>

  );
};

export default LibroCompraGracias;
