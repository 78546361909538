import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/app.scss';
import App from './App';
import { store } from '@/store';
import { Provider } from 'react-redux';
import { injectStore } from './axios';
import { GetCountryGroupByIP } from './store/iplocation/ipLocationActions';
// import { initInterceptors } from '@/axios';
 
//import reportWebVitals from './reportWebVitals';
 
injectStore(store);
store.dispatch(GetCountryGroupByIP());

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
