import { createSlice } from '@reduxjs/toolkit';
import { CategoriaResponse, getCategoriaByCode, getCategorias, CategoriaData } from './categoriasActions';
 
interface InitialState {
  itemsSuccess: boolean;
  itemsData: undefined | CategoriaResponse;
  itemsLoading: boolean;
  itemsError: boolean;

  itemIdSuccess: boolean;
  itemIdData: undefined | CategoriaData;
  itemIdLoading: boolean;
  itemIdError: boolean;
 
}

const initialState: InitialState = {
  itemsSuccess: false,
  itemsData: undefined,
  itemsLoading: false,
  itemsError: false,

  itemIdSuccess: false,
  itemIdData: undefined,
  itemIdLoading: false,
  itemIdError: false,
};

const LibrosSlice = createSlice({
  name: 'Categorias',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCategorias.rejected, (state: InitialState) => {
      state.itemsError = true;
      state.itemsLoading = false;
      state.itemsSuccess = false;
      state.itemsData = undefined;
    });
    builder.addCase(getCategorias.pending, (state: InitialState) => {
      state.itemsError = false;
      state.itemsLoading = true;
      state.itemsSuccess = false;
    });
    builder.addCase(getCategorias.fulfilled, (state: InitialState, action) => {
      state.itemsData = action.payload;
      state.itemsError = false;
      state.itemsLoading = false;
      state.itemsSuccess = true;
    });

    builder.addCase(getCategoriaByCode.rejected, (state: InitialState) => {
      state.itemIdError = true;
      state.itemIdLoading = false;
      state.itemIdSuccess = false;
      state.itemIdData = undefined;
    });
    builder.addCase(getCategoriaByCode.pending, (state: InitialState) => {
      state.itemIdError = false;
      state.itemIdSuccess = true;
      state.itemIdLoading = false;
    });
    builder.addCase(getCategoriaByCode.fulfilled, (state: InitialState, action) => {
      state.itemIdData = action.payload;
      state.itemIdError = false;
      state.itemIdSuccess = false;
      state.itemIdLoading = true;
    });
   
  },
});

export default LibrosSlice.reducer;
