import React from 'react';
import Footer from '@/components/organisms/Footer';

interface LayoutWithOutNavProps {
  children: JSX.Element;
}

const LayoutWithOutNav: React.FC<LayoutWithOutNavProps> = ({ children }) => (
  <div  >
    <main>{children}</main>
    <Footer />
  </div>
);

export default LayoutWithOutNav;
