import axios, { AxiosRequestConfig } from 'axios';
import { logoutUser, refreshTokenAction } from '@/store/user/userActions';
import env from '@beam-australia/react-env';
import { EnhancedStore } from '@reduxjs/toolkit';

/* eslint-disable */

let store: any;

export const injectStore = (_store: EnhancedStore) => {
  store = _store;
};

const API_VERSION = 'v1';

export const axiosAuth = axios.create({
  baseURL: `${String(env('BASE_API_URL'))}/${API_VERSION}/auth`,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 15000,
  timeoutErrorMessage: 'No hemos podido cargar datos escenciales',
});

export const axiosData = axios.create({
  baseURL: `${String(env('BASE_API_URL'))}/${API_VERSION}/data`,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 15000,
  timeoutErrorMessage: 'No hemos podido cargar datos escenciales',
});


export const axiosPagos = axios.create({
  baseURL: `${String(env('BASE_API_URL'))}/${API_VERSION}`,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 15000,
  timeoutErrorMessage: 'No hemos podido cargar datos escenciales',
});

export const axiosAuthPrivate = axios.create({
  baseURL: `${String(env('BASE_API_URL'))}/${API_VERSION}/auth`,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 15000,
  timeoutErrorMessage: 'No hemos podido cargar datos escenciales',
});

export const axiosPrivate = axios.create({
  baseURL: `${String(env('BASE_API_URL'))}/${API_VERSION}/data`,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 15000,
  timeoutErrorMessage: 'No hemos podido cargar datos escenciales',
});

axiosAuthPrivate.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const { accessToken } = store?.getState().user;
    if (accessToken && config.headers) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

axiosAuthPrivate.interceptors.response.use(
  response => response,
  async error => {
   // console.log({ error.response. });
   if (error.response.data.message)
   {
    sessionStorage.setItem('last_error', error.response.data.message);
   }
    
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest.tryAgain) {
     // console.log('entro');
      originalRequest.tryAgain = true;
      try {
        const newToken = await refreshTokens();
        if (newToken) {
          axios.defaults.headers.common.Authorization = `Bearer ${newToken}`;
          return await axiosPrivate(originalRequest);
        }
      } catch (err) {
        return Promise.reject();
      }
    }
    return Promise.reject(error);
  },
);

axiosPrivate.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const { accessToken } = store?.getState().user;
    if (accessToken && config.headers) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

axiosPrivate.interceptors.response.use(
  response => response,
  async error => {
    console.log({ error });
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest.tryAgain) {
      originalRequest.tryAgain = true;

      try {
        const newToken = await refreshTokens();
        if (newToken) {
          axios.defaults.headers.common.Authorization = `Bearer ${newToken}`;
          return await axiosPrivate(originalRequest);
        }
      } catch (err) {
        await store.dispatch(logoutUser({}));
        return Promise.reject();
      }
    }
    return Promise.reject(error);
  },
);

async function refreshTokens() {
  const user = store?.getState()?.user;

  if (user) {
    const { userInfo, refreshToken } = user;
    const { refreshTokenID: refreshTokenId } = userInfo;

    try {
      console.log('refresh-token in progress');

      await store.dispatch(refreshTokenAction({ refreshTokenID: refreshTokenId, refreshToken }));

      const newAccessToken = store?.getState()?.user?.accessToken as string;

      return newAccessToken;
    } catch (error: any) {
      console.log({ error });
      return Promise.reject();
    }
  }
}
