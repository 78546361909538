export const MERCADO_PAGO = "mercadopago";
export const PAYPAL = "paypal";
export const COMAFI = "comafi";
export const GALICIA = "galicia";
export const FINANCE_BANK = "financebank";
export const FRANCES = "frances";
export const STRIPE = "stripe";

export const PRODUCT_ITEM = 'pitem'
export const COMPRA_USER_DETALLE = 'comprasuserdetalle';
export const PAYPAL_ORDER = 'pporder';
export const PAYPAL_ORDER_ID = 'pporderId';
export const MERCADO_PAGO_PREFERENCE_ID = 'mpid';
export const EVENTO_COMPRA = 'evento';
export const LIBRO_COMPRA = 'libro';
