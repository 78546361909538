import React, { useCallback } from 'react';
import { loginUser } from '@/store/user/userActions';
import { LoginUserProps } from '@/interfaces/user';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import LoginForm from '@/components/organisms/LoginForm';
import MyNav2 from '@/components/templates/MyNav2';
import { useNavigate, useLocation } from 'react-router-dom';

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loading, userInfo } = useAppSelector(state => state.user);

  const handleLogin = useCallback((values: LoginUserProps) => dispatch(loginUser(values)), [dispatch]);

  const params = new URLSearchParams(window.location.search);
  const back = params.get('back');
  let path = "/registro";
  if (back)
  {
    path = "/registro?back="+ back;

  }

  return (
  
 <>
  {/* Breadcrumb */}
  <section className="container d-none d-lg-block mt-2">
    <div className="row">
      <div className="col-12">
        <div className="border-top py-2">
          <nav style={{}} aria-label="breadcrumb" className="d-none d-md-block">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Inicio</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Iniciar sesión
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </section>
  {/* Header */}
  <header className="container my-2 my-md-5">
    <div className="row">
      <div className="col-md-6">
        <h1 className="display-3 mb-4">Accedé a las Actividades Online</h1>
      </div>
      <div className="col-md-6 text-start">
        {/* Login CARD */}
        <div className="col-12 d-grid align-items-stretch sticky-md-top mb-5">
          <div className="card">
            <div className="card-header d-flex align-items-center">
              <h3 className="icon-actividades text-secondary">
                Iniciar Sesión
              </h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                 <LoginForm onSubmit={handleLogin} />
                  
                </div>
                <div className="col-12 col-md-6 d-grid my-2">
                  <button type="button" 
                    onClick={() => navigate("/recuperar-acceso")}
                    className="btn btn-outline-primary">
                    ¿Olvidó su contraseña?
                  </button>
                </div>
                <div className="col-12 col-md-6 d-grid my-2">
                  <button type="button" 
                   onClick={() => navigate(path)}
                   className="btn btn-outline-secondary">
                    Crear nuevo usuario
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</>



 
  );
};

export default Login;
