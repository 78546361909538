import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Alert, Button, Container, Form } from 'react-bootstrap';
import { Navigate, useNavigate } from 'react-router-dom';
import { createUser } from '../../store/user/userActions';
 
import { reset, resetUserCreate } from '@/store/user/userSlice';
import * as Yup from 'yup';
import TabNavigation from '../molecules/TabNavigation';

export interface NewUserValues {
  Nombre: string;
  Telefono: string;
  Email: string;
  CuitCuil: string;
  Contrasenia: string;
  ReContrasenia: string;
  CodigoPostal: string;
  Pais: string;
  Localidad: string;
  Provincia: string;
  DomicilioCalle: string;
  DomicilioNumero: string;
  DomicilioDpto: string;
  DomicilioPiso: string;
  Validacion: boolean;
}

const CreateUser = () => {
  const initialValues: NewUserValues = { 
  Nombre:'',
  Telefono:'',
  Email:'',
  CuitCuil:'',
  Contrasenia:'',
  ReContrasenia: '',
  CodigoPostal:'',
  Pais:'',
  Localidad:'',
  Provincia:'',
  Validacion:false,
  DomicilioCalle:'',
  DomicilioNumero:'',
  DomicilioDpto:'',
  DomicilioPiso:'' };
  const dispatch = useAppDispatch();
  const { newUserError, newUserLoading, newUserSuccess, userInfo } = useAppSelector(state => state.user);
  const [addError, setAddError] = useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const back = params.get('back');
  let path = "/";
  if (back)
  {
 
    path = back;
    
  }

 

  const validationSchema = Yup.object().shape({
    Email: Yup.string()
      .required('Este campo es obligatorio')
      .email('Este campo tiene que ser un email')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    Nombre: Yup.string()
      .required('Este campo es obligatorio')
      .matches(/^([^0-9]*)$/, 'Ingresa un formato correcto')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    Contrasenia: Yup.string().required('El campo es requerido').min(6, 'La contraseña debe tener como minimo 6 digitos'),  
    ReContrasenia: Yup.string()
    .when('Contrasenia', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('Contrasenia')], 'No existe coincidencia'),
    })
    .required('El campo es requerido'),
    Telefono: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    Pais: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    Localidad: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    CuitCuil: Yup.string()
      .required('Este campo es obligatorio')
      .max(25, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    Provincia: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    CodigoPostal: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    DomicilioCalle: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
      DomicilioNumero: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
      Validacion: Yup.boolean()
      .oneOf([true], "Debe aceptar los término y condiciones")
  });

  useEffect(() => {
    if (newUserError) {
      setAddError(true);
    }
    if (newUserSuccess) {
      setAddSuccess(true);

      setTimeout(() => {
        dispatch(resetUserCreate());
       // console.log(path);
         navigate(path);
      }, 1000);
    }
  }, [newUserError, newUserSuccess]);

  const formik = useFormik({
    initialValues,
    onSubmit: async values => {
      setAddError(false);
      setAddSuccess(false);
      await dispatch(createUser(values));
    },
    validationSchema: validationSchema,
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: false,
  });
 

  return (
    <>
      <Container>
        {addSuccess && (
          <Alert variant="success" dismissible onClose={() => setAddSuccess(false)}>
            <Alert.Heading>¡Todo salió bien!</Alert.Heading>
            <p>Se agregó el usuario correctamente</p>
          </Alert>
        )}
        {addError && (
          <Alert variant="danger" dismissible onClose={() => setAddError(false)}>
            <Alert.Heading>¡Ups, algo salió mal!</Alert.Heading>
            <p>{sessionStorage.getItem('last_error')}</p>
          </Alert>
        )}
        <div>
          <div className="mt-5 d-flex gap-3 ">
 
                <div className="row">
                  <div className="col-md-6 mb-3">
                   

                      <Form.Group className="w-3">
                            <Form.Label>Nombre y Apellido *</Form.Label>
                            <Form.Control
                                autoComplete='new-password' 
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.Nombre ? true : false}
                              required
                              onChange={formik.handleChange}
                              name="Nombre"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.Nombre}</Form.Control.Feedback>
                      </Form.Group>

                  </div>
                  <div className="col-md-6 mb-3">

                     <Form.Group className="w-3">
                            <Form.Label>Número de Documento ( DNI / CI / ID ) *</Form.Label>
                            <Form.Control
                                autoComplete='new-password' 
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.CuitCuil ? true : false}
                              required
                              onChange={formik.handleChange}
                              name="CuitCuil"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.CuitCuil}</Form.Control.Feedback>
                      </Form.Group>

                  
                  </div>
                  <div className="col-md-6 mb-3">

                       <Form.Group className="w-3">
                            <Form.Label>Teléfono *</Form.Label>
                            <Form.Control
                                autoComplete='new-password' 
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.Telefono ? true : false}
                              required
                              onChange={formik.handleChange}
                              name="Telefono"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.Telefono}</Form.Control.Feedback>
                      </Form.Group>
 
                  </div>
                  <div className="col-md-6 mb-3">

                           <Form.Group className="w-3">
                            <Form.Label>Mail *</Form.Label>
                            <Form.Control
                                autoComplete='new-password' 
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.Email ? true : false}
                              required
                              onChange={formik.handleChange}
                              name="Email"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.Email}</Form.Control.Feedback>
                      </Form.Group>
                  </div>
                  <div className="col-md-6 mb-3">
                     <Form.Group className="w-3">
                            <Form.Label>País *</Form.Label>
                            <Form.Control
                                autoComplete='new-password' 
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.Pais ? true : false}
                              required
                              onChange={formik.handleChange}
                              name="Pais"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.Pais}</Form.Control.Feedback>
                      </Form.Group>

 
                  </div>
                  <div className="col-md-6 mb-3">

                      <Form.Group className="w-3">
                            <Form.Label>Provincia *</Form.Label>
                            <Form.Control
                                autoComplete='new-password' 
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.Provincia ? true : false}
                              required
                              onChange={formik.handleChange}
                              name="Provincia"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.Provincia}</Form.Control.Feedback>
                      </Form.Group>

 
                  </div>
                  <div className="col-md-6 mb-3">
                     <Form.Group className="w-3">
                            <Form.Label>Localidad *</Form.Label>
                            <Form.Control
                                autoComplete='new-password' 
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.Localidad ? true : false}
                              required
                              onChange={formik.handleChange}
                              name="Localidad"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.Localidad}</Form.Control.Feedback>
                      </Form.Group>
 
 
                  </div>
                  <div className="col-md-6 mb-3">
                       <Form.Group className="w-3">
                            <Form.Label>Calle *</Form.Label>
                            <Form.Control
                                autoComplete='new-password' 
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.DomicilioCalle ? true : false}
                              required
                              onChange={formik.handleChange}
                              name="DomicilioCalle"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.DomicilioCalle}</Form.Control.Feedback>
                      </Form.Group>

 
                  </div>
                  <div className="col-md-6 mb-3">

                    <Form.Group className="w-3">
                            <Form.Label>Número *</Form.Label>
                            <Form.Control   
                             autoComplete='new-password' 
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.DomicilioNumero ? true : false}
                              required
                              onChange={formik.handleChange}
                              name="DomicilioNumero"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.DomicilioNumero}</Form.Control.Feedback>
                      </Form.Group>

                  
                  </div>
                  <div className="col-md-6 mb-3">

                     <Form.Group className="w-3">
                            <Form.Label>Piso</Form.Label>
                            <Form.Control
                                autoComplete='new-password' 
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.DomicilioPiso ? true : false}
                              onChange={formik.handleChange}
                              name="DomicilioPiso"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.DomicilioPiso}</Form.Control.Feedback>
                      </Form.Group>

                    
                  </div>
                  <div className="col-md-6 mb-3">

                  <Form.Group className="w-3">
                            <Form.Label>Dpto</Form.Label>
                            <Form.Control
                                autoComplete='new-password' 
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.DomicilioDpto ? true : false}
                              onChange={formik.handleChange}
                              name="DomicilioDpto"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.DomicilioDpto}</Form.Control.Feedback>
                      </Form.Group>
 
                  </div>
                  <div className="col-md-6 mb-3">
                      
                    <Form.Group className="w-3">
                            <Form.Label>Código Postal *</Form.Label>
                            <Form.Control
                                      autoComplete='new-password' 
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.CodigoPostal ? true : false}
                              required
                              onChange={formik.handleChange}
                              name="CodigoPostal"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.CodigoPostal}</Form.Control.Feedback>
                      </Form.Group>

                  
                  </div>
                  <div className="col-md-6 mb-3">

                     <Form.Group className="w-3">
                            <Form.Label>Contraseña *</Form.Label>
                            <Form.Control
                            autoComplete='new-password' 
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.Contrasenia ? true : false}
                              required
                              type="password"
                              onChange={formik.handleChange}
                              name="Contrasenia"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.Contrasenia}</Form.Control.Feedback>
                      </Form.Group>
 
                  </div>
                  <div className="col-md-6 mb-3">


                         <Form.Group className="w-3">
                            <Form.Label>Verificar Contraseña *</Form.Label>
                            <Form.Control
                                autoComplete='new-password' 
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.ReContrasenia ? true : false}
                              required
                              type="password"
                              onChange={formik.handleChange}
                              name="ReContrasenia"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.ReContrasenia}</Form.Control.Feedback>
                      </Form.Group>
   
                  </div>
                  <div className="col-12 mb-3">

                      <Form.Group className="w-3">
                    
                      <div className="form-check"> 
                            <Form.Control
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.Validacion ? true : false}
                              required
                              type="checkbox"
                              className="form-check-input"
                              onChange={formik.handleChange}
                              name="Validacion"
                              id="flexCheckDefault"
                            />
                              <Form.Label  htmlFor="flexCheckDefault" 
                              className="form-check-label">  Acepto que el sistema reconozca la computadora con la que
                        accedo por primera vez a mi cuenta y, sea solo a traves
                        desde esa misma computadora con la que pueda acceder a mi
                        cuenta y eventos online.</Form.Label>
                        <Form.Control.Feedback type="invalid">{formik.errors.Validacion}</Form.Control.Feedback>
                            </div>
                          
                      </Form.Group>
            
                  </div>
                  <div className="mb-3 d-grid">
                  <Button className="btn btn-primary"   onClick={() => formik.handleSubmit()}>
                  Enviar
                  </Button>
  
                  </div>
                </div>
        
           
          </div>
        
        </div>
      </Container>
      {newUserLoading && (
        <div>Aguarde un instante...</div>
      )}
    </>
  );
};

export default CreateUser;
