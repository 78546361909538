import { useAppSelector } from '@/hooks/redux';
import { useFormik } from 'formik';
import { Container, Form } from 'react-bootstrap';
import { ComprasUserDetalleProps } from '@/interfaces/compras';
import { EventoData } from '@/store/eventos/eventosActions';
import * as Yup from 'yup';
import { COMPRA_USER_DETALLE } from '../constants';
import { parseLegales } from '@/components/utils';

interface CreateComprasUserDetalleProps {
  viewEvento: (item: EventoData) => void;
  dataEvento: EventoData;
  validacion?: boolean;
}

const CreatePurchaseEventoForm: React.FC<CreateComprasUserDetalleProps> = ({ viewEvento, dataEvento, validacion = false }) => {
  const { userInfo } = useAppSelector(state => state.user);
  const initialValues: ComprasUserDetalleProps = {
    user_id: userInfo ? userInfo.id : null,
    nombre_y_apellido: '',
    dni: '',
    pais: '',
    provincia: '',
    localidad: '',
    telefono: '',
    email: '',
    calle: '',
    numero: '',
    piso: '',
    departamento: '',
    codigo_postal: '',
    Validacion: validacion
  };
  // const dispatch = useAppDispatch();

  const validationSchema = Yup.object().shape({
    nombre_y_apellido: Yup.string()
      .required('Este campo es obligatorio')
      .matches(/^([^0-9]*)$/, 'Ingresa un formato correcto')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    dni: Yup.string()
      .required('Este campo es obligatorio')
      .max(25, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    telefono: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    email: Yup.string()
      .required('Este campo es obligatorio')
      .email('Este campo tiene que ser un email')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    pais: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    localidad: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    provincia: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    codigo_postal: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    calle: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    numero: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    Validacion: Yup.boolean()
      .oneOf([true], "Debe aceptar los término y condiciones")
  });

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      localStorage.setItem(COMPRA_USER_DETALLE, JSON.stringify(values));
      viewEvento(dataEvento)
    },
    validationSchema: validationSchema,
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: false,
  });


  return (
    <>
      <Container>
        <div>
          <div className="card-body">
            <div className="row justify-content-end">
              <div className="col-md-6 mb-3">
                <Form.Group className="w-3">
                  <Form.Label>Nombre y Apellido *</Form.Label>
                  <Form.Control
                   autoComplete='new-password' 
                    onBlur={formik.handleBlur}
                    isInvalid={formik.errors.nombre_y_apellido ? true : false}
                    required
                    onChange={formik.handleChange}
                    name="nombre_y_apellido"
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.nombre_y_apellido}</Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Group className="w-3">
                  <Form.Label>Número de Documento ( DNI / CI / ID ) *</Form.Label>
                  <Form.Control
                   autoComplete='new-password' 
                    onBlur={formik.handleBlur}
                    isInvalid={formik.errors.dni ? true : false}
                    required
                    onChange={formik.handleChange}
                    name="dni"
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.dni}</Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Group className="w-3">
                  <Form.Label>Teléfono *</Form.Label>
                  <Form.Control
                   autoComplete='new-password' 
                    onBlur={formik.handleBlur}
                    isInvalid={formik.errors.telefono ? true : false}
                    required
                    onChange={formik.handleChange}
                    name="telefono"
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.telefono}</Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Group className="w-3">
                  <Form.Label>Mail *</Form.Label>
                  <Form.Control
                   autoComplete='new-password' 
                    onBlur={formik.handleBlur}
                    isInvalid={formik.errors.email ? true : false}
                    required
                    onChange={formik.handleChange}
                    name="email"
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Group className="w-3">
                  <Form.Label>País *</Form.Label>
                  <Form.Control
                   autoComplete='new-password' 
                    onBlur={formik.handleBlur}
                    isInvalid={formik.errors.pais ? true : false}
                    required
                    onChange={formik.handleChange}
                    name="pais"
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.pais}</Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Group className="w-3">
                  <Form.Label>Provincia *</Form.Label>
                  <Form.Control
                   autoComplete='new-password' 
                    onBlur={formik.handleBlur}
                    isInvalid={formik.errors.provincia ? true : false}
                    required
                    onChange={formik.handleChange}
                    name="provincia"
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.provincia}</Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Group className="w-3">
                  <Form.Label>Localidad *</Form.Label>
                  <Form.Control
                   autoComplete='new-password' 
                    onBlur={formik.handleBlur}
                    isInvalid={formik.errors.localidad ? true : false}
                    required
                    onChange={formik.handleChange}
                    name="localidad"
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.localidad}</Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Group className="w-3">
                  <Form.Label>Calle *</Form.Label>
                  <Form.Control
                   autoComplete='new-password' 
                    onBlur={formik.handleBlur}
                    isInvalid={formik.errors.calle ? true : false}
                    required
                    onChange={formik.handleChange}
                    name="calle"
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.calle}</Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Group className="w-3">
                  <Form.Label>Número *</Form.Label>
                  <Form.Control
                   autoComplete='new-password' 
                    onBlur={formik.handleBlur}
                    isInvalid={formik.errors.numero ? true : false}
                    required
                    onChange={formik.handleChange}
                    name="numero"
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.numero}</Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Group className="w-3">
                  <Form.Label>Piso</Form.Label>
                  <Form.Control
                   autoComplete='new-password' 
                    onBlur={formik.handleBlur}
                    isInvalid={formik.errors.piso ? true : false}
                    onChange={formik.handleChange}
                    name="piso"
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.piso}</Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Group className="w-3">
                  <Form.Label>Dpto</Form.Label>
                  <Form.Control
                   autoComplete='new-password' 
                    onBlur={formik.handleBlur}
                    isInvalid={formik.errors.departamento ? true : false}
                    onChange={formik.handleChange}
                    name="departamento"
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.departamento}</Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Group className="w-3">
                  <Form.Label>Código Postal *</Form.Label>
                  <Form.Control
                   autoComplete='new-password' 
                    onBlur={formik.handleBlur}
                    isInvalid={formik.errors.codigo_postal ? true : false}
                    required
                    onChange={formik.handleChange}
                    name="codigo_postal"
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.codigo_postal}</Form.Control.Feedback>
                </Form.Group>
              </div>


              <div className="col-md-12 mb-3">
                <div className="terminos border rounded">
                  <h6 className="text-primary mb-3">
                    Términos y condiciones
                  </h6>
                  <small>
                  <div>
                      {dataEvento.evento_sociales.length > 0 && (
                        <div dangerouslySetInnerHTML={{ __html: parseLegales(dataEvento, dataEvento.evento_sociales[0].Legales) }} />
                      )}
                    </div>
                     
                  </small>
                </div>
              </div>


              <div className="col-12 mb-3">
                <Form.Group className="w-3">
                  <div className="form-check">
                    <Form.Control
                      onBlur={formik.handleBlur}
                      isInvalid={formik.errors.Validacion ? true : false}
                      required
                      type="checkbox"
                      className="form-check-input"
                      onChange={formik.handleChange}
                      name="Validacion"
                      id="flexCheckDefault"
                    />
                    <Form.Label htmlFor="flexCheckDefault"
                      className="form-check-label"> Acepto los terminos y condiciones</Form.Label>
                    <Form.Control.Feedback type="invalid">{formik.errors.Validacion}</Form.Control.Feedback>
                  </div>
                </Form.Group>
              </div>

              <div className="col-6 mb-3 d-grid">
                <button type="button" className="btn btn-outline-primary"
                  onClick={() => formik.handleSubmit()}
                >
                  Siguiente <i className="bi bi-chevron-right" />
                </button>
              </div>
            </div>
          </div>

        </div>
      </Container>
    </>
  );
};

export default CreatePurchaseEventoForm;
