import { createSlice } from '@reduxjs/toolkit';
import { ConsultoresResponse, getConsultores, getConsultorById, ConsultorData, ConsultoresEResponse,getConsultoresE } from './consultoresActions';


interface InitialState {
  consultoresSuccess: boolean;
  consultoresData: undefined | ConsultoresResponse;
  consultoresLoading: boolean;
  consultoresError: boolean;

  consultorIdSuccess: boolean;
  consultorIdData: undefined | ConsultorData;
  consultorIdLoading: boolean;
  consultorIdError: boolean;

  consultoresESuccess: boolean;
  consultoresEData: undefined | ConsultoresEResponse;
  consultoresELoading: boolean;
  consultoresEError: boolean;

}

const initialState: InitialState = {
  consultoresSuccess: false,
  consultoresData: undefined,
  consultoresLoading: false,
  consultoresError: false,

  consultorIdSuccess: false,
  consultorIdData: undefined,
  consultorIdLoading: false,
  consultorIdError: false,

  consultoresESuccess: false,
  consultoresEData: undefined,
  consultoresELoading: false,
  consultoresEError: false,
 
};

const consultoresSlice = createSlice({
  name: 'consultores',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getConsultores.rejected, (state: InitialState) => {
      state.consultoresError = true;
      state.consultoresLoading = false;
      state.consultoresSuccess = false;
      state.consultoresData = undefined;
    });
    builder.addCase(getConsultores.pending, (state: InitialState) => {
      state.consultoresError = false;
      state.consultoresLoading = true;
      state.consultoresSuccess = false;
    });
    builder.addCase(getConsultores.fulfilled, (state: InitialState, action) => {
      state.consultoresData = action.payload;
      state.consultoresError = false;
      state.consultoresLoading = false;
      state.consultoresSuccess = true;
    });
    builder.addCase(getConsultorById.rejected, (state: InitialState) => {
      state.consultorIdError = true;
      state.consultorIdLoading = false;
      state.consultorIdSuccess = false;
      state.consultorIdData = undefined;
    });
    builder.addCase(getConsultorById.pending, (state: InitialState) => {
      state.consultorIdError = false;
      state.consultorIdLoading = true;
      state.consultorIdSuccess = false;
    });
    builder.addCase(getConsultorById.fulfilled, (state: InitialState, action) => {
      state.consultorIdData = action.payload;
      state.consultorIdError = false;
      state.consultorIdLoading = false;
      state.consultorIdSuccess = true;
    });

    builder.addCase(getConsultoresE.rejected, (state: InitialState) => {
      state.consultoresEError = true;
      state.consultoresELoading = false;
      state.consultoresESuccess = false;
      state.consultoresEData = undefined;
    });
    builder.addCase(getConsultoresE.pending, (state: InitialState) => {
      state.consultoresEError = false;
      state.consultoresELoading = true;
      state.consultoresESuccess = false;
    });
    builder.addCase(getConsultoresE.fulfilled, (state: InitialState, action) => {
      state.consultoresEData = action.payload;
      state.consultoresEError = false;
      state.consultoresELoading = false;
      state.consultoresESuccess = true;
    });
    
  },
});


export default consultoresSlice.reducer;
