import { createAsyncThunk } from '@reduxjs/toolkit';
import { ValidationErrors } from '@/interfaces/common';
import { ComprasUserDetalleProps, ItemPreferenceProps, LibroPreferenceProps } from '@/interfaces/compras';

import { axiosData, axiosPagos } from '@/axios';
import type { AxiosError, AxiosResponse } from 'axios';
import { MERCADO_PAGO_PREFERENCE_ID } from '@/components/constants';

export interface GenericResponse {
  message: string;
}

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const modulePrefix = 'cud';
export const createComprasUserDetalle = createAsyncThunk(
  `${modulePrefix}/create`,
  async (data: ComprasUserDetalleProps, { rejectWithValue }) => {
    try {
      const { message: message }: GenericResponse = await axiosData.post('/cud/create', data);
      return { message };
    } catch (err: unknown) {
      if (err instanceof Error) {
        const error: AxiosError<ValidationErrors> = err as AxiosError<ValidationErrors>;
        if (!error.response) {
          throw err;
        }
        return rejectWithValue(error.response.data);
      }
      throw err;
    }
  },
);

export interface MPPreferenceResponse {
  id: string;
}

export const createMercadoPagoPreferenceId = createAsyncThunk(
  'mercadopago/create_preference',
  async (data: ItemPreferenceProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse = await axiosPagos.post('mercadopago/create_preference', data);
      const r: MPPreferenceResponse = response.data;
      localStorage.setItem(MERCADO_PAGO_PREFERENCE_ID, r.id);
      await delay(50);
      return { id: r.id };
    } catch (err: unknown) {
      if (err instanceof Error) {
        const error: AxiosError<ValidationErrors> = err as AxiosError<ValidationErrors>;
        if (!error.response) {
          throw err;
        }
        return rejectWithValue(error.response.data);
      }
      throw err;
    }
  },
);

export const createMercadoPagoPreferenceLibroId = createAsyncThunk(
  'mercadopago/create_preference',
  async (data: LibroPreferenceProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse = await axiosPagos.post('mercadopago/create_preference_libro', data);
      const r: MPPreferenceResponse = response.data;
      localStorage.setItem(MERCADO_PAGO_PREFERENCE_ID, r.id);
      await delay(50);
      return { id: r.id };
    } catch (err: unknown) {
      if (err instanceof Error) {
        const error: AxiosError<ValidationErrors> = err as AxiosError<ValidationErrors>;
        if (!error.response) {
          throw err;
        }
        return rejectWithValue(error.response.data);
      }
      throw err;
    }
  },
);
