import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { axiosPagos } from '@/axios';
import { getStorageKey, slugify } from '@/components/utils';
import { COMPRA_USER_DETALLE, EVENTO_COMPRA, LIBRO_COMPRA, MERCADO_PAGO, MERCADO_PAGO_PREFERENCE_ID, PRODUCT_ITEM, STRIPE } from '@/components/constants';
import { store } from '@/store';


const CompraCallbackStripe: React.FC = () => {

  const location = useLocation();
  const navigate = useNavigate();


  const getQueryParam = (name:string) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(name);
  };

  useEffect(() => {
 
    const sessionId = getQueryParam('session_id');

    if (sessionId) {
   
      let compraUserDetalle = getStorageKey(COMPRA_USER_DETALLE);
      const pitem = getStorageKey(PRODUCT_ITEM);
       localStorage.removeItem(PRODUCT_ITEM);
      
      if (pitem.item.type == EVENTO_COMPRA  && getStorageKey(EVENTO_COMPRA).Modalidad=="Online" )
      {
        compraUserDetalle =  {"user_id":  store?.getState().user?.userInfo?.id,"nombre_y_apellido":"-","dni":"-","pais":"-","provincia":"-","localidad":"-","telefono":"-","email":store?.getState().user?.userInfo?.email,"calle":"-","numero":"-","piso":"-","departamento":"-","codigo_postal":"-","Validacion":true};
      }
        localStorage.removeItem(COMPRA_USER_DETALLE);
      if (compraUserDetalle) {
       
        if (pitem) {
          axiosPagos.get('stripe/payments?sessionId=' + sessionId)
            .then((res) => {
              console.log(res);
           
              const status = res.data.payment_status // approved
             
              if (status == 'paid') {
             
                axiosPagos.post('data/cud/create', compraUserDetalle)
                  .then((res2) => {
                    const comprasUserDetalleId = res2.data.result.id;

                    // data/compras/create
                    const item = {
                      "origin": STRIPE,
                      "compras_user_detalle_id": comprasUserDetalleId,
                      "payment_id": res.data.payment_intent,
                      "product_id": pitem.item.id,
                      "product_type": pitem.item.type,
                      "amount": res.data.amount_total,
                      "currency": res.data.currency.toUpperCase(),
                      "user_id": compraUserDetalle.user_id,
                      "response": JSON.stringify(res.data)
                    }
                    axiosPagos.post('data/compras/create', item)
                      .then(() => {
                        if (pitem.item.type == EVENTO_COMPRA) {
                          const evento = getStorageKey(EVENTO_COMPRA);
                           localStorage.removeItem(EVENTO_COMPRA);
                          if (evento) {
                          const path = `/evento-inscripcion-03/${evento.IdEvento}-${slugify(evento.Titulo)}?id=${res.data.payment_intent}`;
                            navigate(path);
                          }
                        }

                        if (pitem.item.type == LIBRO_COMPRA) {
                          const libro = getStorageKey(LIBRO_COMPRA);
                           localStorage.removeItem(LIBRO_COMPRA);
                          if (libro) {
                            const path = `/libro-compra-gracias/${libro.id}?id=${res.data.payment_intent}`;
                            navigate(path);
                          }
                        }
                      });
                  })
              }
            }).catch((err) => console.log(err));
        }
      }
    }
 
  }, [location]);

  return <></>

};

export default CompraCallbackStripe;
