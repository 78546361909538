import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { axiosPagos } from '@/axios';
import { getStorageKey, slugify } from '@/components/utils';
import {
  COMPRA_USER_DETALLE,
  EVENTO_COMPRA,
  LIBRO_COMPRA,
  MERCADO_PAGO,
  MERCADO_PAGO_PREFERENCE_ID,
  PRODUCT_ITEM,
} from '@/components/constants';
import { store } from '@/store';

const CompraCallback: React.FC = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const navigate = useNavigate();
  const queryParams: { [key: string]: string } = {};

  useEffect(() => {
    const LOCAL_MPID = localStorage.getItem(MERCADO_PAGO_PREFERENCE_ID);

    let isMyPreferenceId = false;
    for (const param of query.entries()) {
      queryParams[param[0]] = param[1];
      if (param[0] === 'preference_id') {
        if (LOCAL_MPID === param[1]) {
          isMyPreferenceId = true;
        }
      }
    }

    if (isMyPreferenceId) {
      localStorage.removeItem(MERCADO_PAGO_PREFERENCE_ID);
      let compraUserDetalle = getStorageKey(COMPRA_USER_DETALLE);
      const pitem = getStorageKey(PRODUCT_ITEM);
      localStorage.removeItem(PRODUCT_ITEM);

      if (pitem.item.type == EVENTO_COMPRA && getStorageKey(EVENTO_COMPRA).Modalidad == 'Online') {
        compraUserDetalle = {
          user_id: store?.getState().user?.userInfo?.id,
          nombre_y_apellido: '-',
          dni: '-',
          pais: '-',
          provincia: '-',
          localidad: '-',
          telefono: '-',
          email: store?.getState().user?.userInfo?.email,
          calle: '-',
          numero: '-',
          piso: '-',
          departamento: '-',
          codigo_postal: '-',
          Validacion: true,
        };
      }
      localStorage.removeItem(COMPRA_USER_DETALLE);
      if (compraUserDetalle) {
        if (pitem) {
          axiosPagos
            .get('mercadopago/payments/' + queryParams.payment_id)
            .then(res => {
              const status = res.data.response.body.status; // approved
              // console.log(res.data.response.body.status);
              if (status == 'approved') {
                // guardo los datos en la db y redirecciono
                // creo compra detail
                // data/cud/create
                axiosPagos.post('data/cud/create', compraUserDetalle).then(res2 => {
                  const comprasUserDetalleId = res2.data.result.id;

                  // data/compras/create
                  const item = {
                    origin: MERCADO_PAGO,
                    compras_user_detalle_id: comprasUserDetalleId,
                    payment_id: res.data.response.body.id,
                    product_id: pitem.item.id,
                    product_type: pitem.item.type,
                    amount: res.data.response.body.transaction_amount,
                    currency: res.data.response.body.currency_id,
                    user_id: compraUserDetalle.user_id,
                    response: JSON.stringify(res.data),
                  };
                  axiosPagos.post('data/compras/create', item).then(() => {
                    if (pitem.item.type == EVENTO_COMPRA) {
                      const evento = getStorageKey(EVENTO_COMPRA);
                      localStorage.removeItem(EVENTO_COMPRA);
                      if (evento) {
                        const path = `/evento-inscripcion-03/${evento.IdEvento}-${slugify(evento.Titulo)}?id=${
                          res.data.response.body.id
                        }`;
                        navigate(path);
                      }
                    }

                    if (pitem.item.type == LIBRO_COMPRA) {
                      const libro = getStorageKey(LIBRO_COMPRA);
                      localStorage.removeItem(LIBRO_COMPRA);
                      if (libro) {
                        const path = `/libro-compra-gracias/${libro.id}?id=${res.data.response.body.id}`;
                        navigate(path);
                      }
                    }
                  });
                });
              } else {
                const path = `/compra-error`;
                navigate(path);
              }
            })
            .catch(err => console.log(err));
        }
      }
    }
  }, [location]);

  return <></>;
};

export default CompraCallback;
