import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useNavigate } from 'react-router-dom';
import { getLibros, LibroData } from '@/store/libros/librosActions';
import { slugify } from '@/components/utils';
import   TagManager    from 'react-gtm-module';

const Libros: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const libros = useAppSelector((state) => state.libros?.itemsData?.data);

  useEffect(() => {
    dispatch(getLibros({}));
   
  }, [dispatch]);

  useEffect(() => {
    sendPageView();
  })
  
  const sendPageView = () => {
    // Obtener información de la página actual
    const pagePath = window.location.pathname;
    const pageTitle = document.title;
    console.log(pagePath + pageTitle)
    // Enviar datos de la página a GTM
    
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        pagePath: pagePath,
        pageTitle: pageTitle,
      },
    });
  }

  const viewLibro = (item: LibroData) => {
    const path = `/libro/${item.id}-${slugify(item.nombre)}`;
    window.scrollTo(0, 0);
    navigate(path);

  }

  return (
    <>
      {/* Header */}
      <header className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h1 className="display-4 mb-4">Libros de Pablo Almazán</h1>
            <p className="lead">
            Siento un profundo agradecimiento y reconocimiento hacia los lectores de mis libros. Son realmente obras para valientes; para personas que desean transformar profundamente su vida y que saben que, para eso, el compromiso es con uno mismo. Tenemos un esquema de supervivencia que es muy bueno pero que debe ser compensado por un permanente contacto con nuestra verdadera esencia. Hacia allí apunta todo lo que transmito. Feliz de que lo hagamos juntos.
            </p>
          </div>
          <div className="col-md-6 text-center d-none d-md-block">
            <img src="./img/libro_hero.jpg" className="w-50" alt="" />
          </div>
        </div>
      </header>
      {/* Libros */}
      <section className="container my-5">
        <div className="row">
          {/* Libro 01 */}
          {libros && libros.map(data => (
            <article key={data.id} className="col-md-6 col-lg-4 text-center book-border pb-5">
              <img
                src={data.imagen}
                alt={data.nombre}
                className="img-fluid"
              />
              <h2>{data.nombre}</h2>
              <h6 className="text-secondary">{data.autor}</h6>
              <button type="button"
                onClick={() => viewLibro(data)}
                className="btn btn-link icon-argentina btn-lg" />
              <button type="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  window.open(data.link_envio_al_exterior, '_blank');
                }}

                className="btn btn-link icon-world btn-lg" />
              <button type="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  window.open(data.link_version_ebook, '_blank');
                }}

                className="btn btn-link icon-tablet btn-lg" />
              <p className="px-3"  dangerouslySetInnerHTML={{ __html: data.descripcion.length > 120 ? data.descripcion.substring(0, 120) + '...' : data.descripcion }}>
         
              </p>
              <button type="button"
                onClick={() => viewLibro(data)}
                className="btn btn-outline-primary">
                + info
              </button>
            </article>
          ))}

        </div>
      </section>
    </>





  );
};

export default Libros;
