import React, {  useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useParams } from 'react-router-dom';
import {
  getConsultorById,
  ConsultorData  
} from '@/store/consultores/consultoresActions';
import FormaParteForm from '@/components/organisms/FormaParteForm';

const FormaParte: React.FC = () => {
  const { consultorId } = useParams();
  const dispatch = useAppDispatch();
  /*const dataConsultor = useAppSelector((state) => state.consultores?.consultorIdData);
 
    
  useEffect(() => {
    if (consultorId)
    {
     dispatch(getConsultorById({idConsultor: parseInt(consultorId.split("-")[0])}));
    }
  }, [dispatch]);*/
 

  return (
    <>
  {/* Breadcrumb */}
  <section className="container d-none d-lg-block mt-2">
  <div className="row">
    <div className="col-12">
      <div className="border-top py-2">
        <nav style={{}} aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Inicio</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/consultores">Consultores</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
            Deseo incorporarme al listado de Consultores
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</section>

  {/* Header */}
  <header className="container my-2 my-md-3"> 
  <div className="row">
  <div className="col-md-12 text-center">
    <h1 className="display-4 mb-4">Asumirse un Humano Puente</h1>
  </div>
</div>
</header>
 
<FormaParteForm></FormaParteForm>
 


</>


 
  );
};

export default FormaParte;
