import { createAsyncThunk } from '@reduxjs/toolkit';
import { ValidationErrors } from '@/interfaces/common';
import { axiosData } from '@/axios';

import type { AxiosError, AxiosResponse } from 'axios';


const modulePrefix = '/eventos';

export interface CategoriaData {
  id: number,
  codigo: string,
  nombre: string,
  activo: boolean,
  Subtitle: string,
}

export interface CategoriaResponse {
  data: CategoriaData[];
}

export interface CategoriaProps {
  order?: string;
  code?: string;
  IdCategoria2?: number;
  activo?: number;
  id?: number;
}

export const getCategorias = createAsyncThunk(
  `${modulePrefix}/getCategorias`,
  async ({  IdCategoria2 }: CategoriaProps, { rejectWithValue }) => {
    try {
      const data: AxiosResponse = await axiosData.get(`/eventos?includes=imagenes&IdEstado=3&order=categoria.Nombre|Fecha,DESC|Titulo&IdCategoria2=${IdCategoria2!.toString()}`, {

      });
      const result: CategoriaResponse = data.data;
      return result;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);


export const getCategoriaByCode = createAsyncThunk(
  `${modulePrefix}/getCategoriasByCode`,
  async ({ code }: CategoriaProps, { rejectWithValue }) => {
    try {
      const data: AxiosResponse = await axiosData.get(`categorias2/code/${code!.toString()}`, {
      });
      const result: CategoriaData = data.data.item;
      return result;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);


