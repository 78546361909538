import React, { useCallback, useState } from 'react';
import { code2User, codeUser } from '@/store/user/userActions';
import { LoginUserProps, RecoveryProps } from '@/interfaces/user';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import LoginForm from '@/components/organisms/LoginForm';
import MyNav2 from '@/components/templates/MyNav2';
import { useNavigate, useLocation } from 'react-router-dom';
import ForgottPassword from '@/components/organisms/ForgottPassword';
import ForgottPassword2 from '@/components/organisms/ForgottPassword2';
import { Alert } from 'react-bootstrap';

const Recovery2: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loading, userInfo } = useAppSelector(state => state.user);
  const [addError, setAddError] = useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  
  const handleLogin = useCallback((values: RecoveryProps) => {
    dispatch(code2User(values))
      .then((result) => {
        console.log("result," , result.payload)
         if (result.payload)
        {
          setAddSuccess(true);

        }
        else
        {
          setAddError(true);

        }
        
       
      })
      .catch((error) => {
        
      });
  }, [dispatch]);


  
  return (
  
 <>
  {/* Breadcrumb */}
  <section className="container d-none d-lg-block mt-2">
    <div className="row">
      <div className="col-12">
        <div className="border-top py-2">
          <nav style={{}} aria-label="breadcrumb" className="d-none d-md-block">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Inicio</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Recuperar Contraseña
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </section>
  {/* Header */}
  <header className="container my-2 my-md-5">
    <div className="row">
      <div className="col-md-6">
        <h1 className="display-3 mb-4">Accedé a las Actividades Online</h1>
      </div>
      <div className="col-md-6 text-start">
        {/* Login CARD */}
        <div className="col-12 d-grid align-items-stretch sticky-md-top mb-5">
          <div className="card">
            <div className="card-header d-flex align-items-center">
              <h3 className="icon-actividades text-secondary">
                Recuperar Acceso
              </h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                 <ForgottPassword2 onSubmit={handleLogin} />

                 {addSuccess && (
                  <Alert variant="success"  >
                    <Alert.Heading>¡Muchas Gracias!</Alert.Heading>
                    <p>Su contraseña se actualizo correctamente. <a href="/login">Iniciar Sesión</a></p>
                  </Alert>
                )}
                {addError && (
                  <Alert variant="danger" dismissible onClose={() => setAddError(false)}>
                    <Alert.Heading>¡Ups, algo salió mal!</Alert.Heading>
                    <p>Los datos ingresados no son correctos, intente nuevamente</p>
                  </Alert>
                )}
                     
                </div>

                 

             
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</>



 
  );
};

export default Recovery2;
