import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import env from '@beam-australia/react-env';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useNavigate } from 'react-router-dom';
import {
  EventoData,
  getEventoById
} from '@/store/eventos/eventosActions';

import CreatePurchaseEventoForm from '@/components/organisms/CreatePurchaseEventoForm';
import { getPriceDescription, obtenerParte, parseCopete, parseFecha, parseHora } from '@/components/utils';

const EventoInscripcion1: React.FC = () => {


  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { eventoId } = useParams();
  const dataEvento = useAppSelector((state) => state.eventos?.itemIdData);
  const { userInfo } = useAppSelector(state => state.user);

  useEffect(() => {
    if (eventoId) {
      dispatch(getEventoById({ id: parseInt(eventoId.split("-")[0]) }));
    }
  }, [dispatch]);


  const slugify = (text: string) => {
    let slug = text.replace(/[^\w\s]/g, "");
    slug = slug.replace(/\s+/g, "_");
    slug = slug.toLowerCase();
    return slug;
  }
  const viewEvento = (item: EventoData) => {
    let path = `/evento-inscripcion-02/${item.IdEvento}-${slugify(item.Titulo)}`;
    const isOnline = dataEvento?.Modalidad === 'Online'
    if (!userInfo && isOnline) {
      path = '/registro'
    }
    navigate(path);
  }


  return (

    <>
      {/* Breadcrumb */}
      <section className="container d-none d-lg-block mt-2">
        <div className="row">
          <div className="col-12">
            <div className="border-top py-2">
              <nav style={{}} aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Inicio</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/actividades">Actividades</a>
                  </li>
               
                  <li className="breadcrumb-item active" aria-current="page">
                    {(dataEvento) ? dataEvento.Titulo : ''}

                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* Header */}
      {dataEvento && (
        <>
          <header className="container my-2 my-md-3">
            <div className="row g-4">
              <div className="col-md-12">
                <h1 className="display-4 mb-4">
                  {dataEvento.Titulo}
                </h1>
                <a
                  className="btn btn-outline-secondary icon-calendar me-1 mb-2"
                  href="#"
                  role="button"
                >
                  {(dataEvento.Fecha) ? parseFecha(dataEvento.Fecha.toString()) : ''}
                </a>
                <a
                  className={
                    dataEvento.Modalidad === 'Online'
                      ? 'btn btn-outline-secondary   icon-video  me-1 mb-2'
                      : dataEvento.Modalidad === 'Presencial'
                        ? 'btn btn-outline-secondary   icon-presencial  me-1 mb-2'
                        : dataEvento.Modalidad === 'Video'
                          ? 'btn btn-outline-secondary   icon-video  me-1 mb-2'
                          : 'btn btn-outline-secondary   '
                  }
                  href="#"
                  role="button"
                >
                    {dataEvento.Modalidad==='Video'?'Zoom':dataEvento.Modalidad}
                </a>
                {dataEvento.Oradores && dataEvento.Oradores.split(';').map(dataOrador => (

                  <a key={"o_" + dataOrador}
                    className="btn btn-outline-secondary icon-speaker me-1 mb-2"
                    href="#"
                    role="button"
                  >
                    {dataOrador}
                  </a>
                ))}


                <p className="lead my-4" dangerouslySetInnerHTML={{ __html: parseCopete(dataEvento) }} />
                <hr />
              </div>
            </div>
          </header>
          <section className="container mb-5">
            <div className="row g-4">
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <h6 className="text-secondary">Actividad</h6>
                    <h2 className="card-title">
                      {dataEvento.Titulo}
                    </h2>
                    <hr />
                    <ul className="evento-info">
                    {(!dataEvento.evento_sociales  || (dataEvento.evento_sociales && dataEvento.evento_sociales.length==0))  && dataEvento.Modalidad !== 'Online'    && (
                      <li className="icon-schedule d-flex align-items-center my-1">
                        {/* 17-09-2022 de 10:00am a 5:00pm */}
                        {(dataEvento.Fecha) ?   parseFecha(dataEvento.Fecha.toString()) : ''}
                      </li>
                      )}

                      {dataEvento.evento_sociales && dataEvento.evento_sociales.length>0 && (
                       <>
                          <li className="icon-schedule d-flex align-items-center my-1">
                            {/* 17-09-2022 de 10:00am a 5:00pm */}
                            {(dataEvento.evento_sociales[0].Fecha) ?   <> Fecha y Hora de Inicio <br/>  {  parseFecha(dataEvento.evento_sociales[0].Fecha.toString())  +  " " +  parseHora(dataEvento.evento_sociales[0].Fecha.toString()) } </>  : ''}
                     
                          </li>
                          <li className="icon-schedule d-flex align-items-center my-1">
                          {/* 17-09-2022 de 10:00am a 5:00pm */}
                          {(dataEvento.evento_sociales[0].FechaFin) ?   <>  Fecha y Hora de Fin <br/>  {   parseFecha(dataEvento.evento_sociales[0].FechaFin.toString()) + " " + parseHora(dataEvento.evento_sociales[0].FechaFin.toString())} </>   : ''}
                        </li>
                         </>
                      )}


                      {dataEvento.Modalidad=='Presencial' && dataEvento.evento_sociales[0]  && dataEvento.evento_sociales[0].localidad && (
                      <li className="icon-location d-flex align-items-center my-1">{dataEvento.evento_sociales[0].provincia.Nombre + ", " +dataEvento.evento_sociales[0].localidad.Nombre}</li>
                      )}
                       {dataEvento.Modalidad!='Presencial' && (
                      <li className="icon-video d-flex align-items-center my-1">
                          {(dataEvento.Modalidad=='Video') ? 'En vivo por plataforma Zoom' : dataEvento.Modalidad }
                      </li>
                       )}
                      <li className="icon-currency d-flex align-items-center my-1">
                      {obtenerParte(getPriceDescription(dataEvento))}
                      </li>
                      {dataEvento.Oradores && dataEvento.Oradores.split(';').map(dataOrador => (
                        <li key={"or_"+dataOrador}  className="icon-speaker d-flex align-items-center my-1">
                          {dataOrador}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="card p-2">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-8">
                        <h6 className="text-primary">Inscripción a la actividad</h6>
                      </div>
                      <div className="col-4 text-end text-muted">
                        <h6>Paso 1 de 3</h6>
                      </div>
                    </div>
                  </div>

                  <CreatePurchaseEventoForm viewEvento={viewEvento} dataEvento={dataEvento} />

                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>

  );
};

export default EventoInscripcion1;
