
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getLibrosById, getLibros, LibroData } from '@/store/libros/librosActions';
import { getDatosEmpresasById } from '@/store/datosEmpresas/datosEmpresasActions';
import CreatePurchaseLibroForm from '@/components/organisms/CreatePurchaseLibroForm';
import { slugify } from '@/components/utils';


const LibroCompra: React.FC = () => {
  const dispatch = useAppDispatch();
  const { libroId } = useParams();
  const navigate = useNavigate();
  const dataLibro = useAppSelector((state) => state.libros?.itemIdData);
  const libros = useAppSelector((state) => state.libros?.itemsData?.data);

  useEffect(() => {
    if (libroId) {
      dispatch(getLibrosById({ id: parseInt(libroId.split("-")[0]) }));
    }
  }, [dispatch, libroId]);

  useEffect(() => {
    dispatch(getLibros({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDatosEmpresasById({ id: 1 }));
  }, [dispatch]);


  const viewLibro = (item: LibroData) => {
    const path = `/libro-compra-forma-de-pago/${item.id}-${slugify(item.nombre)}`;
    window.scrollTo(0, 0);
    navigate(path);

  }


  function obtenerCuatroLibrosAlAzar(librosArray: LibroData[] | undefined) {
    if (librosArray == undefined) return;
    const librosCopia = [...librosArray];
    const arrayAleatorizado = librosCopia.sort(() => Math.random() - 0.5);
    const cuatroAlAzar = arrayAleatorizado.slice(0, 4);

    return (
      <>
        {cuatroAlAzar.map((item) => (
          <article className="col-md-6 col-lg-3" key={`otros_lb-${item.id}`}>
            <a href="#" onClick={() => viewLibro(item)}>
              <img
                src={item.imagen}
                alt={item.nombre}
                className="img-fluid"
              />
              <h3>{item.nombre}</h3>
              <h6 className="text-secondary">{item.autor}</h6>
              <button
                type="button"
                className="btn btn-link solo-argentina btn-lg"
              />
              <button type="button" className="btn btn-link solo-world btn-lg" />
              <button type="button" className="btn btn-link solo-tablet btn-lg" />
            </a>
          </article>
        ))}
      </>
    );
  }


  return (
    <>
      {/* Breadcrumb */}
      <section className="container d-none d-lg-block mt-2">
        <div className="row">
          <div className="col-12">
            <div className="border-top py-2">
              <nav style={{}} aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Inicio</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/libros">Libros de Pablo Almazán</a>
                  </li>
                  <li className="breadcrumb-item"> {(dataLibro) ? dataLibro.nombre : ''}</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Envíos a Argentina
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* Libro */}
      {dataLibro && (
        <section className="container">
          <div className="row text-start">
            <div className="col-md-6">
              <img src={dataLibro.imagen} className="img-fluid" alt="" />
            </div>
            <div className="col-md-6 d-grid gap-2">
              <h1 className="display-3">{dataLibro.nombre}</h1>
              <h6 className="text-secondary">{dataLibro.autor}</h6>
              <hr />
              <div className="row">
                <div className="col-4">
                  <small className="text-muted my-0 text-uppercase">
                    <strong>Precio</strong>
                  </small>
                  <h1 className="text-primary price">${dataLibro.precio}</h1>
                </div>
                <div className="col-8">
                  <h3 className="icon-argentina text-primary" />
                  <p>Con envío incluido a toda la Argentina</p>
                  <hr />
                  <small>
                    Para más información a cerca de libros, enviar un mail a{" "}
                    <a href="mailto:hplibros@gmail.com">hplibros@gmail.com</a>.  
                  </small>
                </div>
              </div>
              <hr />
              <h6 className="mb-3 text-primary">Formulario de Compra</h6>
              <CreatePurchaseLibroForm event={viewLibro} dataLibro={dataLibro} />
            </div>
          </div>
        </section>
      )}
      {/* Otros Libros */}
      <section className="container my-5">
        <div className="col-12">
          <div className="border-top pt-2">
            <h5 className="text-primary">
              <strong>Otros libros de Pablo Almazán</strong>
            </h5>
          </div>
          <div className="row text-center">
            {obtenerCuatroLibrosAlAzar(libros)}
          </div>
        </div>
      </section>
    </>


  );
};

export default LibroCompra;
