import React, { useCallback, useEffect } from 'react';
import { loginUser } from '@/store/user/userActions';
import { LoginUserProps } from '@/interfaces/user';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { getBanners } from '@/store/banners/bannersActions';
import { getLibros, LibroData } from '@/store/libros/librosActions';
import { useNavigate } from 'react-router-dom';
import { EventoData, getEventos3 } from '@/store/eventos/eventosActions';
import { parseFecha, slugify } from '@/components/utils';

const Home: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const banners = useAppSelector((state) => state.banners?.itemsData);
  const libros = useAppSelector((state) => state.libros?.itemsData);
  const eventos = useAppSelector((state) => state.eventos?.items3Data);

  useEffect(() => {
    dispatch(getBanners({}));
  }, [dispatch]);


  useEffect(() => {
    dispatch(getLibros({}));
  }, [dispatch]);


  useEffect(() => {
    dispatch(getEventos3({}));
  }, [dispatch]);


  const viewEvento = (item: EventoData) => {
    const path = `/evento-detalle/${item.IdEvento}-${slugify(item.Titulo)}`;
    navigate(path);
  }


  const viewLibro = (item: LibroData) => {
    const path = `/libro/${item.id}-${slugify(item.nombre)}`;
    navigate(path);
  }

  function Carousel() {

    const librosArray = libros?.data;
    if (!Array.isArray(librosArray)) {
      // Si libros no es un array, mostrar un mensaje de error
      return <div></div>;
    }

    const groupsOfThree = librosArray.reduce<Array<Array<any>>>((acc, item, index) => {
      const groupIndex = Math.floor(index / 3);

      if (!acc[groupIndex]) {
        acc[groupIndex] = [];
      }

      acc[groupIndex].push(item);

      return acc;
    }, []);


    return (
      <div className="carousel-inner">
        {groupsOfThree.map((group: any, index: any) => (
          <div key={`libro-g-${index}`} className={`carousel-item ${index === 0 ? 'active' : ''}`} data-bs-interval={10000}>
            <div className="row mb-5">
              {group.map((item: any) => (
                <div key={`libro-${item.id}`} className="col-md-4 text-center column-border-end">
                  <article className="px-4">
                    <a href="#" onClick={() => viewLibro(item)}>
                      <img
                        src={item.imagen}
                        alt={item.nombre}
                        className="img-fluid"
                      />
                      <h2>{item.nombre}</h2>
                      <h6 className="text-secondary">{item.autor}</h6>
                      <button
                        type="button"
                        className="btn btn-link icon-argentina btn-lg"
                      />

                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          window.open(item.link_envio_al_exterior, '_blank');
                        }}
                        className="btn btn-link icon-world btn-lg"
                      />

                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          window.open(item.link_version_ebook, '_blank');
                        }}
                        className="btn btn-link icon-tablet btn-lg"
                      />
                      <p dangerouslySetInnerHTML={{ __html: item.descripcion.length > 120 ? item.descripcion.substring(0, 120) + '...' : item.descripcion }}>
                    
                      </p>
                    </a>
                  </article>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }


  return (
    <>
      {/* Carousel */}
      <div id="carouselHero" className="carousel slide" data-bs-ride="false">
        <div className="carousel-indicators d-none d-md-flex">
          {banners && banners.data.map((item, index) => (
            <button
              type="button"
              key={`banner-btn-${item.imagen}`}
              data-bs-target="#carouselHero"
              data-bs-slide-to={index}
              className={index === 0 ? "active" : ""}
              aria-current={index === 0 ? "true" : "false"}
              aria-label={`Slide ${item.imagen}`}
            />
          ))}

        </div>
        <div className="carousel-inner">
          {banners && banners.data.map((item, index) => (
            <div key={`banner-${item.imagen}`}  className={`carousel-item ${index === 0 ? "active" : ""}`}>
              <img src={item.imagen} className="d-block w-100" alt="..." />
              <div className="carousel-caption d-block text-md-start mb-md-2">
                <a href="/somos" className="link-light">
                  <h1 className="display-5 mb-md-5">
                  Somos humano puente
                  </h1>
                </a>
                <a
                  className="btn btn-outline-light d-none d-md-inline"
                  href="/somos"
                  role="button"
                >
                  + información
                </a>
              </div>
            </div>
          ))}
        </div>
        {banners && banners.data.length > 1 && (
          <>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselHero"
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselHero"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="visually-hidden">Next</span>
            </button>
          </>
        )}
      </div>
      {/* Articulos */}
      <section className="container">
        <div className="row my-4 my-md-5">
          <div className="col-12 col-lg-6">
            <article className="mb-4">
              <a href="/camino">
                <img
                  src="./img/placeholder_01.jpg"
                  className="img-fluid mb-3"
                  alt=""
                />
                <h1 className="display-5">¿Cómo hacer el camino Humano Puente?</h1>
                <p className="lead">
                  Todo lo que ha sucedido en tu historia y en la de tu clan te ha
                  traido hasta este momento y es, justamente desde este preciso
                  instante y desde cada instante presente donde, sólo tu decisión
                  personal hará que todo haya sucedido para algo maravilloso.
                </p>
              </a>
            </article>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <article className="mb-4">
              <a href="/consultores">
                <img
                  src="./img/placeholder_02.jpg"
                  className="img-fluid mb-3"
                  alt=""
                />
                <h6 className="text-primary">consultores</h6>
                <h2>¿Estás buscando un Consultor?</h2>
                <p>
                  Accedé al Listado de Consultores de todo el mundo, que han realizado
                  el Camino completo de Humano Puente.
                </p>
                <button type="button" className="btn btn-outline-primary">
                  + info
                </button>
              </a>
            </article>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <article className="mb-4">
              <div className="border-top border-dark pt-2">
                <h5 className="text-primary">
                  <strong>Próximas Actividades</strong>
                </h5>
              </div>
              {eventos && eventos.data.map((item) => (
                <div className="border-bottom py-3" key={`evento-map-${item.IdEvento}`}>
                  <h6>
                    {(item.Fecha) ? parseFecha(item.Fecha.toString().substring(0, 10)) : ''} <span className="text-primary"> {item.Modalidad=="Video"?"Zoom":item.Modalidad}</span>
                  </h6>
                  <h3>

                    <a href="" onClick={() => viewEvento(item)}  >
                      {item.Titulo}
                    </a>
                  </h3>

                  <h6 className="text-secondary">
                    {item.Oradores && item.Oradores.split(';').map((dataOrador: any, index: number) => (
                      <div key={`orador-${index}`}>
                        {dataOrador}
                        <br />
                      </div>
                    ))}

                  </h6>
                </div>
              ))}
            </article>
          </div>
        </div>
      </section>
      {/* Como hacer el camino */}
      <section className="container">
        <div className="row">
          <div className="col-12">
            <div className="border-top border-dark pt-2">
              <h5 className="text-primary">
                <strong>Cómo hacer el camino</strong>
              </h5>
              <div className="row text-white">
                <div className="col-md-4 my-2">
                  <a href='/caminohp'>
                    <div className="circle ratio ratio-1x1 paso-01">
                      <div className="d-flex flex-column align-items-center justify-content-center text-center">
                        <h5>01</h5>
                        <h2>Jornada Humano Puente</h2>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-4 my-2">
                  <a href='/eventos/apertura-decodificación-bioemocional'>
                    <div className="circle ratio ratio-1x1 paso-02">
                      <div className="d-flex flex-column align-items-center justify-content-center text-center">
                        <h5>02</h5>
                        <h2>Apertura en Decodificación Bioemocional</h2>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-4 my-2">
                  <a href='/eventos/apertura-bioexistencia-consciente'>
                    <div className="circle ratio ratio-1x1 paso-03">
                      <div className="d-flex flex-column align-items-center justify-content-center text-center">
                        <h5>03</h5>
                        <h2>Apertura en Bioexistencia Consciente</h2>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Libros */}
      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="border-top border-dark">
                <div className="row py-3">
                  <div className="col-6">
                    <h5 className="text-primary align-middle">
                      <strong>Libros de Pablo Almazán</strong>
                    </h5>
                  </div>
                  <div className="col-6 text-end">
                    <button
                      type="button"
                      className="btn btn-link btn-lg"
                      data-bs-target="#carouselExampleDark"
                      data-bs-slide="prev"
                    >
                      <i className="bi bi-chevron-left" />
                    </button>
                    <button
                      type="button"
                      className="btn btn-link btn-lg"
                      data-bs-target="#carouselExampleDark"
                      data-bs-slide="next"
                    >
                      <i className="bi bi-chevron-right" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                id="carouselExampleDark"
                className="carousel carousel-dark slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide-to={0}
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  />
                  <button
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide-to={1}
                    aria-label="Slide 2"
                  />
                  <button
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide-to={2}
                    aria-label="Slide 3"
                  />
                   <button
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide-to={3}
                    aria-label="Slide 4 "
                  />
                </div>
                {libros && Carousel()}

              </div>
            </div>
          </div>
        </div>
      </section>
    </>

  );
};

export default Home;
