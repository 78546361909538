 
interface StripeButtonProps {
  price: number;
  product_type: string;
  product_id: number;
  product_name: string;

}
import env from '@beam-australia/react-env';

const StripeButton: React.FC<StripeButtonProps> = ({ price, product_type,  product_id, product_name }) => {
  
  const BASE_API_URL = env('BASE_API_URL');

  return   <form action={`${BASE_API_URL}/v1/stripe/create-checkout-session`} method="POST">
           
              
           
  <input
    type="hidden"
    id="quantity-input"
    min="1"
    max="10"
    
    value="1"
    name="quantity"
    readOnly
  />

<input
    type="hidden"
    id="product_type"
  
    
    value={product_type}
    name="product_type"
    readOnly
  />

<input
    type="hidden"
    id="product_id"
  
    
    value={product_id}
    name="product_id"
    readOnly
  />

<input
    type="hidden"
    id="product_name"
  
    
    value={product_name}
    name="product_name"
    readOnly
  />


<input
    type="hidden"
    id="price"
  
    
    value={price}
    name="price"
    readOnly
  />

<input
    type="hidden"
    id="urlCurrent"
  
    
    value={window.location.href}
    name="urlCurrent"
    readOnly
  />
  
  <div className="col-12 mb-3 d-grid">
                              <button type="submit" className="btn btn-outline-primary" 
                                
                              >
                                Siguiente <i className="bi bi-chevron-right" />
                              </button>
                            </div>
 
</form>

};


export default StripeButton;
