import { configureStore, combineReducers, AnyAction } from '@reduxjs/toolkit';
import userReducer from '@/store/user/userSlice';
import consultorReducer from '@/store/consultores/consultoresSlice';
import locacionesReducer from '@/store/locaciones/locacionesSlice';
import bannersReducer from '@/store/banners/bannersSlice';
import librosReducer from '@/store/libros/librosSlice';
import certificadosReducer from '@/store/certificados/certificadosSlice';
import eventosReducer from '@/store/eventos/eventosSlice';
import categoriasReducer from '@/store/categorias/categoriasSlice';
import videosReducer from '@/store/videos/videosSlice';
import mercadoPagoPreferenceReducer from '@/store/purchase/purchaseSlice';
import datosEmpresasReducer from '@/store/datosEmpresas/datosEmpresasSlice';
import ipLocationReducer from '@/store/iplocation/ipLocationSlice';

const reducers = {
  user: userReducer,
  consultores: consultorReducer,
  locaciones: locacionesReducer,
  banners: bannersReducer,
  libros: librosReducer,
  certificados: certificadosReducer,
  eventos: eventosReducer,
  categorias: categoriasReducer,
  videos: videosReducer,
  mercadoPagoPreference: mercadoPagoPreferenceReducer,
  datosEmpresas: datosEmpresasReducer,
  iplocation: ipLocationReducer
};


const combinedReducers = combineReducers(reducers);

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  if (action.type === 'user/logout/rejected' || action.type === 'user/logout/fulfilled') {
    return combinedReducers(undefined, action);
  }
  return combinedReducers(state, action);
};

export const store = configureStore({
  middleware: getdefaultMiddleware =>
    getdefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: rootReducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof combinedReducers>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
