import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import env from '@beam-australia/react-env';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useNavigate } from 'react-router-dom';
import { getLibrosById, LibroData } from '@/store/libros/librosActions';

const CompraError: React.FC = () => {
  return (
    <>
      {/* Breadcrumb */}
      <section className="container d-none d-lg-block mt-2">
        <div className="row">
          <div className="col-12">
            <div className="border-top py-2">
              <nav style={{}} aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Inicio</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/Pagos">Pagos</a>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* Header */}

      <>
        <header className="container my-2 my-md-3">
          <div className="row g-4">
            <div className="col-md-12">
              <h1 className="display-4 mb-4">Detalle de la compra</h1>
              <hr />
            </div>
          </div>
        </header>
        <section className="container mb-5">
          <div className="row g-4">
            <div className="col-md-12">
              <div className="card p-2">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col-8">
                      <h6 className="text-primary">Compra </h6>
                    </div>
                    <div className="col-4 text-end text-muted">
                      <h6>Paso 3 de 3</h6>
                    </div>
                  </div>
                </div>

                <div className="card-body py-5">
                  <h1 className="mb-5 text-primary">Su compra no fue procesada correctamente.</h1>
                  <p>Su compra no fue confirmada.</p>
                  <p>Por cualquier consulta comuniquese a</p>
                  <p>
                    <a href="mailto:organizacionhp@gmail.com">organizacionhp@gmail.com</a>
                  </p>
                </div>

                <div className="row">
                  <div className="col-12 mb-3 d-grid">
                    <a href="/" className="btn btn-primary">
                      Finalizar
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
};

export default CompraError;
