import { createSlice } from '@reduxjs/toolkit';
import { VideoResponse, getVideos } from './videosActions';
 
interface InitialState {
  itemsSuccess: boolean;
  itemsData: undefined | VideoResponse;
  itemsLoading: boolean;
  itemsError: boolean;
 
}

const initialState: InitialState = {
  itemsSuccess: false,
  itemsData: undefined,
  itemsLoading: false,
  itemsError: false,
};

const VideosSlice = createSlice({
  name: 'videos',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getVideos.rejected, (state: InitialState) => {
      state.itemsError = true;
      state.itemsLoading = false;
      state.itemsSuccess = false;
      state.itemsData = undefined;
    });
    builder.addCase(getVideos.pending, (state: InitialState) => {
      state.itemsError = false;
      state.itemsLoading = true;
      state.itemsSuccess = false;
    });
    builder.addCase(getVideos.fulfilled, (state: InitialState, action) => {
      state.itemsData = action.payload;
      state.itemsError = false;
      state.itemsLoading = false;
      state.itemsSuccess = true;
    });
   
  },
});

export default VideosSlice.reducer;
