import { createAsyncThunk } from '@reduxjs/toolkit';
import { ValidationErrors } from '@/interfaces/common';
import { axiosData } from '@/axios';

import type { AxiosError, AxiosResponse } from 'axios';

const modulePrefix = '/ip-location';

export interface Group {
  group: number,
  countryCode: string,
  countryDesc: string,
  ip: string
}

export interface ipLocationData {
  group: Group,
}

export interface IpLocationResponse {
  data: ipLocationData;
}


export const GetCountryGroupByIP  = createAsyncThunk(
  `${modulePrefix}/getCountryGroupByIP`,
  async (a, { rejectWithValue }) => {
    try {
      const data: AxiosResponse = await axiosData.get(`ip-location/get-country-client`);
      const result: IpLocationResponse = data;
    
      //localStorage.setItem('country',result.data.group.countryCode);
      //localStorage.setItem('group',  result.data.group.group.toString());
      if (!localStorage.getItem('country'))
         { localStorage.setItem('country',(result.data.group.countryCode)?result.data.group.countryCode: "US");}

      if (!localStorage.getItem('group'))
      { localStorage.setItem('group',  result.data.group.group.toString());}

      if (!localStorage.getItem('countryDesc'))
      { localStorage.setItem('countryDesc',(result.data.group.countryDesc)?result.data.group.countryDesc: "United States");} 

      return result;
    } catch (err: unknown) {
      if (err instanceof Error) {
        const error: AxiosError<ValidationErrors> = err as AxiosError<ValidationErrors>; // Aquí estamos haciendo un "type assertion", es decir, le estamos diciendo a TypeScript que estamos seguros de que `err` es de tipo `AxiosError<ValidationErrors>`.
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data);
      } else {
        throw err;
      }
    }

  },
);

