import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Alert, Button, Container, Form, Modal } from 'react-bootstrap';
import { Navigate, useNavigate } from 'react-router-dom';
import {  newsletterUser } from '../../store/user/userActions';
 
import { reset, resetUserCreate } from '@/store/user/userSlice';
import * as Yup from 'yup';
import TabNavigation from '../molecules/TabNavigation';

export interface NewUserValues {
  email: string;
}

const NewsletterForm = () => {
  const initialValues: NewUserValues = { 
    email:'',
   };
  const dispatch = useAppDispatch();
  const { newNewsletterError, newNewsletterLoading, newNewsletterSuccess } = useAppSelector(state => state.user);
  const [addError, setAddError] = useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  const [modalChangeState, setModalChangeState] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email no valido')
      .email('Email no valido')
      .min(0, 'Email no valido')
      .matches(/^\s*\S[^]*$/, 'Email no valido'),
    
  });

  useEffect(() => {
    if (newNewsletterError) {
      setAddError(true);
    }
    if (newNewsletterSuccess) {
      formik.resetForm();
      formik.setValues(initialValues);
      setAddSuccess(true);
      setModalChangeState(true);
    
     }
  }, [newNewsletterError, newNewsletterSuccess]);

  const formik = useFormik({
    initialValues,
    onSubmit: async values => {
      setAddError(false);
      setAddSuccess(false);
      await dispatch(newsletterUser(values));
    },
    validationSchema: validationSchema,
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: false,
  });
 
  
  return (
    <>
      
      
           <Form.Group  className="d-flex"   >
         
           <Form.Control
                              onBlur={formik.handleBlur}
                              value={formik.values.email}
                              required
                              onChange={formik.handleChange}
                              placeholder="Correo Electrónico"
                              name="email"
                              isInvalid={formik.errors.email ? true : false}
                              bsPrefix="form-control-white me-2"
                            />
   
                            
                            <Button    variant="outline-light"  type="button"  className="btn btn-outline-light"   onClick={() => formik.handleSubmit()}>
                        Suscribirse
                        </Button>
                      
                        <Form.Control.Feedback className='text-white ' type="invalid">{formik.errors.email}</Form.Control.Feedback>    
                            
                 </Form.Group>
               
      
        {addSuccess && (
        <Modal show={modalChangeState} onHide={() =>{setAddSuccess(false); setModalChangeState(false)}}>
        <Modal.Header closeButton className="modal-header">
          <Modal.Title>Gracias por suscribirte</Modal.Title>
        </Modal.Header>
  
        <Modal.Body className="modal-body">
      
            <Form.Label>Pronto vas a recibir las últimas novedades de Humano Puente en tu casilla de e-mail.</Form.Label>
   
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <Button onClick={() => {setAddSuccess(false); setModalChangeState(false)}} variant="btn btn-primary">
            Cerrar
          </Button>
         
        </Modal.Footer>
      </Modal>
        )}
        {addError && (
          <Alert variant="danger" dismissible onClose={() => setAddError(false)}>
            <Alert.Heading>¡Ups, algo salió mal!</Alert.Heading>
            <p>Intenta nuevamente</p>
          </Alert>
        )}

   
      {newNewsletterLoading && (
        <div>Aguarde un instante...</div>
      )}
    </>
  );
};

export default NewsletterForm;
