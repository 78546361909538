import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Alert, Button, Container, Form } from 'react-bootstrap';
import { Navigate, useNavigate } from 'react-router-dom';
import { contactCertificado, contactUser } from '../../store/user/userActions';
import {
  getPaises,
  getProvincias,
  getlocalidades,
  LocacionesData
} from '@/store/locaciones/locacionesActions';
 
import {
  getCertificados,
  CertificadoData
} from '@/store/certificados/certificadosActions';
import { reset, resetUserCreate } from '@/store/user/userSlice';
import * as Yup from 'yup';
import TabNavigation from '../molecules/TabNavigation';

export interface NewUserValues {
  nombre: string;
  telefono: string;
  email: string;
  dni: string;
  pais: string;
  provincia: string;
  localidad: string;
  mensaje: string;
  acepto: boolean;
}

const CertificadoForm = () => {
  const initialValues: NewUserValues = { 
    nombre:'',
    telefono:'',
    email:'',
    dni:'',
    pais:'',
    provincia:'',
    localidad:'',
    mensaje:'',
    acepto:false,
   };
  const dispatch = useAppDispatch();
  const { newContactCertificadoError, newContactCertificadoLoading, newContactCertificadoSuccess } = useAppSelector(state => state.user);
  const [addError, setAddError] = useState(false);
  const [addSuccess, setAddSuccess] = useState(false);

  const [valorPais, setValorPais] = useState('');
  const [valorProvincia, setValorProvincia] = useState('');
  const [valorLocalidad, setValorLocalidad] = useState('');
  const [valorActividad, setValorActividad] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [modalChangeState, setModalChangeState] = useState(true);
  const informacionPaises = useAppSelector((state) => state.locaciones?.paisesData);
  const informacionProvincias = useAppSelector((state) => state.locaciones?.provinciasData);
  const informacionLocalidades = useAppSelector((state) => state.locaciones?.localidadesData);
  const [checked, setChecked] = useState(false);
  const dataCertificados = useAppSelector((state) => state.certificados?.itemsData);



  useEffect(() => {
    dispatch(getPaises({ only_with_consultor:false }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCertificados({}));
  }, [dispatch]);


  useEffect(() => {
    if (valorPais) {
      dispatch(getProvincias({ id: valorPais, only_with_consultor:false }));
      setValorProvincia('');
    }
  }, [valorPais, dispatch]);

  useEffect(() => {
    if (valorProvincia) {
      dispatch(getlocalidades({ id: valorProvincia, only_with_consultor:false  }));
      setValorLocalidad('');
    }
  }, [valorProvincia, dispatch]);

  const handleChangeA = (event: any) => {
    if (event.target.value == "0") {
      setValorPais('');
      setValorProvincia('');
      setValorLocalidad('');
    }
    else {
      setValorPais(event.target.value);
      setValorProvincia('');
      setValorLocalidad('');
      dispatch(getProvincias({ id: event.target.value, only_with_consultor:false  }));
    }
  };

  
  


  const handleChangeB = (event: any) => {
    if (event.target.value == "0") {
      setValorProvincia('');
      setValorLocalidad('');
    }
    else {
      setValorProvincia(event.target.value);
      setValorLocalidad('');
      dispatch(getlocalidades({ id: event.target.value, only_with_consultor:false  }));
    }
  };

  const handleChangeC = (event: any) => {
    setValorLocalidad(event.target.value);
  };

  const handleChangeActividad = (event: any) => {
    setValorActividad(event.target.value);
  };

  
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Este campo es obligatorio')
      .email('Este campo tiene que ser un email')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    nombre: Yup.string()
      .required('Este campo es obligatorio')
      .matches(/^([^0-9]*)$/, 'Ingresa un formato correcto')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    dni: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
     telefono: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
     pais: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
      provincia: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
      localidad: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    mensaje: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
      acepto: Yup.boolean().oneOf([true], 'Debes aceptar los términos y condiciones'),
  });

  useEffect(() => {
    if (newContactCertificadoError) {
      setAddError(true);
    }
    if (newContactCertificadoSuccess) {
      formik.resetForm();
      formik.setValues(initialValues);
      setAddSuccess(true);
    
     }
  }, [newContactCertificadoError, newContactCertificadoSuccess]);

  const formik = useFormik({
    initialValues,
    onSubmit: async values => {
      setAddError(false);
      setAddSuccess(false);
       
      const updatedValues = { ...values };

      const pais = informacionPaises?.data.find((option) => option.IdPais.toString() === values.pais)?.Nombre?? '';
      const localidad = informacionLocalidades?.data.find((option) => option.IdLocalidad.toString() === values.localidad)?.Nombre?? '';
      const provincia = informacionProvincias?.data.find((option) => option.IdProvincia.toString() === values.provincia)?.Nombre?? '';

      updatedValues.pais = pais;
      updatedValues.localidad = localidad;
      updatedValues.provincia = provincia;

    //     updatedValues.localidad-32;


      await dispatch(contactCertificado(updatedValues));
    },
    validationSchema: validationSchema,
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: false,
  });
 

  return (
    <>


    
      <Container>


       
      <section className="container mb-5">
  <div className="row justify-content-center">
    <div className="col-md-12">
      <div className="card p-2">
        <div className="card-body">
        {addSuccess && (

        <div className="card-body py-5">
                    <h1 className="mb-5 text-primary">Gracias por enviarnos su contacto.</h1>
                    <p>
                    Gracias
                    Me honra ya, el simple hecho de que Usted esté navegando esta página.
<strong> </strong> 
 
                      
                    </p>
                    <p>
                    En unas horas nos comunicaremos con usted. Por cualquier consulta comuniquese a  
                   
                    </p>
                    <p>
                    <a href="mailto:organizacionhp@gmail.com">
                        organizacionhp@gmail.com
                      </a>
                    </p>
                    <div className="col d-grid mb-5"><a   href='/consultores' className="btn btn-outline-primary" >Volver a Consultores</a></div>
                    </div>
                    
        )}

{!addSuccess && (  <form action="">
            <div className="row">
              <div className="col-md-6 mb-3">

                
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Nombre y Apellido
                </label>

                <Form.Group className="col-md-12">
                            <Form.Control
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.nombre ? true : false}
                              required
                              onChange={formik.handleChange}
                          
                              value={formik.values.nombre}
                              name="nombre"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.nombre}</Form.Control.Feedback>
                      </Form.Group>
 
              </div>
              <div className="col-md-6 mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Teléfono
                </label>
                <Form.Group className="col-md-12">
                            <Form.Control
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.telefono ? true : false}
                              required
                              onChange={formik.handleChange}
                             
                              value={formik.values.telefono}
                              name="telefono"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.telefono}</Form.Control.Feedback>
                      </Form.Group>
              </div>
              <div className="col-md-6 mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Mail
                </label>
                <Form.Group className="col-md-12">
                            <Form.Control
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.email ? true : false}
                              required
                              onChange={formik.handleChange}
                             
                              value={formik.values.email}
                              name="email"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
                      </Form.Group>
              </div>
              <div className="col-md-6 mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  DNI
                </label>
                <Form.Group className="col-md-12">
                            <Form.Control
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.dni ? true : false}
                              required
                              onChange={formik.handleChange}
                              value={formik.values.dni}
                              name="dni"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.dni}</Form.Control.Feedback>
                      </Form.Group>
              </div>
              <div className="col-md-6 mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  País
                </label>
                <Form.Group  >
                <Form.Select
                    aria-label="Default select example"
                    onChange={(event) => {
                      handleChangeA(event);formik.handleChange(event);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.pais}
                    name="pais"
                    isInvalid={formik.errors.pais ? true : false}
                    required
                  >
                    <option value="">Seleccione un país</option>
                    {informacionPaises && informacionPaises.data.map((opcion) => (
                  <option key={opcion.IdPais} value={opcion.IdPais}>
                    {opcion.Nombre}
                  </option>
                ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{formik.errors.pais}</Form.Control.Feedback>
                </Form.Group>

               
              </div>
              <div className="col-md-6 mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Provincia
                </label>
                <Form.Group  >
                <Form.Select
                    aria-label="Default select example"
                    onChange={(event) => {
                      handleChangeB(event);formik.handleChange(event);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.provincia}
                    name="provincia"
                    isInvalid={formik.errors.provincia ? true : false}
                    required
                  >
                    <option value="">Seleccione una provincia</option>
                    {informacionProvincias && informacionProvincias.data.map((opcion) => (

                      <option key={opcion.IdProvincia} value={opcion.IdProvincia}>
                        {opcion.Nombre}
                      </option>
                      ))}
                
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{formik.errors.provincia}</Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-6 mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Localidad
                </label>
                <Form.Group  >
                <Form.Select
                    aria-label="Default select example"
                    onChange={(event) => {
                      handleChangeC(event);formik.handleChange(event);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.localidad}
                    name="localidad"
                    isInvalid={formik.errors.localidad ? true : false}
                    required
                  >
                    <option value="">Seleccione una localidad</option>
                    {informacionLocalidades && informacionLocalidades.data.map((opcion) => (

                                  <option key={opcion.IdLocalidad} value={opcion.IdLocalidad}>
                                    {opcion.Nombre}
                                  </option>
                                  ))}
                
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{formik.errors.localidad}</Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-12 text-center">
                <hr />
              </div>
              
               


                      
                
                  {dataCertificados && dataCertificados.data.map((opcion) => (
                    <div key={"cer_" + opcion.IdCertificado} className="col-md-4">
                        
                      <Form.Check
                        type="checkbox"
                        id={`certificado-${opcion.IdCertificado}`}
                        label={opcion.Nombre}
                        value={opcion.Nombre}
                        //checked={formik.values.localidad === opcion.IdLocalidad}
                        onChange={(event) => {
                          formik.handleChange(event);
                        }}
                      />
                    
                    </div>
                  ))}
                
              

 
            
              <div className="col-12 text-center">
                <hr />
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Mensaje
                </label>
                <Form.Group className="col-md-12">
                            
                            <Form.Control
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.mensaje ? true : false}
                              required
                              as="textarea"
                              rows={6}
                              maxLength={2000}
                              value={formik.values.mensaje}
                          
                              onChange={formik.handleChange}
                              name="mensaje"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.mensaje}</Form.Control.Feedback>
                      </Form.Group>
              </div>
              <div className="col-12">
                <p>
                  Declaro que he realizado la actividad por la cual solicito el
                  certificado y asumo la total responsabilidad por toda relación
                  que tenga con los Consultantes o personas que atienda en
                  relación a este campo o aplicando sus conocimientos ya que
                  Humano Puente no puede corroborar el tipo de abordaje que
                  llevaré a cabo.
                  <br />
                  He sido desde un comienzo notificado/a acerca de que las
                  prácticas que comparte Humano Puente no pretenden ser bajo
                  ningún punto de vista una práctica médica.
                </p>
              </div>
              <div className="col-12 mb-3">
           
                  
 <Form.Group className="col-md-12" controlId="acepto"> 
                  <Form.Check
                        type="checkbox"
                        id={`localidad-aceptar`}
                        name="acepto"
                        label="Acepto los términos y condiciones"
                        isInvalid={formik.touched.acepto && !!formik.errors.acepto}
                        onChange={formik.handleChange}
          onBlur={formik.handleBlur}
                      />
                        <Form.Control.Feedback type="invalid">{formik.errors.acepto}</Form.Control.Feedback>
</Form.Group>
                
               
              </div>
              <div className="col-12 mb-3 d-grid">
                <button type="button" className="btn btn-outline-primary" onClick={() => formik.handleSubmit()}>
                  Enviar
                </button>
              </div>
            </div>
            
          </form>
          )}
        </div>

      </div>
    </div>
  </div>
</section>
     
           
        
        {addError && (
          <Alert variant="danger" dismissible onClose={() => setAddError(false)}>
            <Alert.Heading>¡Ups, algo salió mal!</Alert.Heading>
            <p>Intenta nuevamente</p>
          </Alert>
        )}

      </Container>
      {newContactCertificadoLoading && (
        <div>Aguarde un instante...</div>
      )}
    </>
  );
};

export default CertificadoForm;
