import React, {  useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useParams } from 'react-router-dom';
import {
  getConsultorById,
  ConsultorData  
} from '@/store/consultores/consultoresActions';
import CertificadoForm from '@/components/organisms/CertificadoForm';

const Certificado: React.FC = () => {
  const { consultorId } = useParams();
  const dispatch = useAppDispatch();
  /*const dataConsultor = useAppSelector((state) => state.consultores?.consultorIdData);
 
    
  useEffect(() => {
    if (consultorId)
    {
     dispatch(getConsultorById({idConsultor: parseInt(consultorId.split("-")[0])}));
    }
  }, [dispatch]);*/
 

  return (
    <>
  {/* Breadcrumb */}
  <section className="container d-none d-lg-block mt-2">
  <div className="row">
    <div className="col-12">
      <div className="border-top py-2">
        <nav style={{}} aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Inicio</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/consultores">Consultores</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Deseo obtener mi certificado
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</section>

  {/* Header */}
  <header className="container my-2 my-md-3">
  <div className="row">
    <div className="col-md-12 text-center">
      <h1 className="display-4 mb-4">Deseo obtener mi certificado</h1>
      <p className="lead">
        Los consultores en Decodificacion Bioemocional y REORIxINS
        (Bioexistencia Consciente) pueden completar el siguiente formulario para
        solicitar el Certificado de participacion en los eventos de Humano
        Puente.
        <br />
        Este Certificado se les enviara en formato PDF a la direccion de mail
        especificada.
      </p>
    </div>
  </div>
</header>
<CertificadoForm></CertificadoForm>
 

</>


 
  );
};

export default Certificado;
