import { createSlice } from '@reduxjs/toolkit';
import { GetCountryGroupByIP, IpLocationResponse } from './ipLocationActions';

interface InitialState {
  success: boolean;
  item: undefined | IpLocationResponse;
  loading: boolean;
  error: boolean;
}

const initialState: InitialState = {
  success: false,
  item: undefined,
  loading: false,
  error: false,
};

const ipLocationSlice = createSlice({
  name: 'iplocation',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(GetCountryGroupByIP.rejected, (state: InitialState) => {
      state.error = true;
      state.loading = false;
      state.success = false;
      state.item = undefined;
    });
    builder.addCase(GetCountryGroupByIP.pending, (state: InitialState) => {
      state.error = false;
      state.loading = true;
      state.success = false;
    });
    builder.addCase(GetCountryGroupByIP.fulfilled, (state: InitialState, action) => {
      state.item = action.payload;
      state.error = false;
      state.loading = false;
      state.success = true;
    });
  },
});

export default ipLocationSlice.reducer;
