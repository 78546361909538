import { createSlice } from '@reduxjs/toolkit';
import { MPPreferenceResponse, createMercadoPagoPreferenceId } from './purchaseActions';

interface InitialState {
  itemsSuccess: boolean;
  itemsData: undefined | MPPreferenceResponse;
  itemsLoading: boolean;
  itemsError: boolean;

}

const initialState: InitialState = {
  itemsSuccess: false,
  itemsData: undefined,
  itemsLoading: false,
  itemsError: false,
};

const mercadoPagoPreferenceSlice = createSlice({
  name: 'mercadoPagoPreference',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(createMercadoPagoPreferenceId.rejected, (state: InitialState) => {
      state.itemsError = true;
      state.itemsLoading = false;
      state.itemsSuccess = false;
      state.itemsData = undefined;
    });
    builder.addCase(createMercadoPagoPreferenceId.pending, (state: InitialState) => {
      state.itemsError = false;
      state.itemsLoading = true;
      state.itemsSuccess = false;
    });
    builder.addCase(createMercadoPagoPreferenceId.fulfilled, (state: InitialState, action) => {
      state.itemsData = action.payload;
      state.itemsError = false;
      state.itemsLoading = false;
      state.itemsSuccess = true;
    });

  },
});

export default mercadoPagoPreferenceSlice.reducer;
