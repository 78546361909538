import { createSlice } from '@reduxjs/toolkit';
import { EventoResponse, getEventoById, getEventos, EventoData, getEventosChild, EventoChildResponse, getEventos3, MisEventoResponse, getMisEventos, getEventoUnidadById, getEventoUsuarioById, EventoUsuarioData, EventoUnidadData, getEventoUnidadPreguntaById } from './eventosActions';
 
interface InitialState {
  itemsSuccess: boolean;
  itemsData: undefined | EventoResponse;
  itemsLoading: boolean;
  itemsError: boolean;


  itemsMisSuccess: boolean;
  itemsMisData: undefined | MisEventoResponse;
  itemsMisLoading: boolean;
  itemsMisError: boolean;


  items3Success: boolean;
  items3Data: undefined | EventoResponse;
  items3Loading: boolean;
  items3Error: boolean;

  itemsChildSuccess: boolean;
  itemsChildData: undefined | EventoChildResponse;
  itemsChildLoading: boolean;
  itemsChildError: boolean;

  itemIdSuccess: boolean;
  itemIdData: undefined | EventoData;
  itemIdLoading: boolean;
  itemIdError: boolean;

  itemEventoUsuarioIdSuccess: boolean;
  itemEventoUsuarioIdData: undefined | EventoUsuarioData;
  itemEventoUsuarioIdLoading: boolean;
  itemEventoUsuarioIdError: boolean;


  itemEventoUnidadIdSuccess: boolean;
  itemEventoUnidadIdData: undefined | EventoUnidadData;
  itemEventoUnidadIdLoading: boolean;
  itemEventoUnidadIdError: boolean;
 
}

const initialState: InitialState = {
  itemsSuccess: false,
  itemsData: undefined,
  itemsLoading: false,
  itemsError: false,


  items3Success: false,
  items3Data: undefined,
  items3Loading: false,
  items3Error: false,


  itemsMisSuccess: false,
  itemsMisData: undefined,
  itemsMisLoading: false,
  itemsMisError: false,


  itemsChildSuccess: false,
  itemsChildData: undefined,
  itemsChildLoading: false,
  itemsChildError: false,

  itemIdSuccess: false,
  itemIdData: undefined,
  itemIdLoading: false,
  itemIdError: false,

  itemEventoUsuarioIdSuccess: false,
  itemEventoUsuarioIdData: undefined,
  itemEventoUsuarioIdLoading: false,
  itemEventoUsuarioIdError: false,

  itemEventoUnidadIdSuccess: false,
  itemEventoUnidadIdData: undefined,
  itemEventoUnidadIdLoading: false,
  itemEventoUnidadIdError: false,
};

const LibrosSlice = createSlice({
  name: 'Eventos',
  initialState,
  reducers: {},
  extraReducers: builder => {


    
    builder.addCase(getEventoUnidadPreguntaById.rejected, (state: InitialState) => {
      state.itemEventoUnidadIdError = true;
      state.itemEventoUnidadIdLoading = false;
      state.itemEventoUnidadIdSuccess = false;
      state.itemEventoUnidadIdData = undefined;
    });
    builder.addCase(getEventoUnidadPreguntaById.pending, (state: InitialState) => {
      state.itemEventoUnidadIdError = false;
      state.itemIdSuccess = true;
      state.itemEventoUnidadIdLoading = false;
    });
    builder.addCase(getEventoUnidadPreguntaById.fulfilled, (state: InitialState, action) => {
      state.itemEventoUnidadIdData = action.payload;
      state.itemEventoUnidadIdError = false;
      state.itemEventoUnidadIdSuccess = true;
      state.itemEventoUnidadIdLoading = false;
    });

    builder.addCase(getEventoUnidadById.rejected, (state: InitialState) => {
      state.itemEventoUnidadIdError = true;
      state.itemIdLoading = false;
      state.itemIdSuccess = false;
      state.itemIdData = undefined;
    });
    builder.addCase(getEventoUnidadById.pending, (state: InitialState) => {
      state.itemIdError = false;
      state.itemIdSuccess = true;
      state.itemIdLoading = false;
    });
    builder.addCase(getEventoUnidadById.fulfilled, (state: InitialState, action) => {
      state.itemIdData = action.payload;
      state.itemIdError = false;
      state.itemIdSuccess = false;
      state.itemIdLoading = true;
    });


    builder.addCase(getEventoUsuarioById.rejected, (state: InitialState) => {
      state.itemEventoUsuarioIdError = true;
      state.itemEventoUsuarioIdLoading = false;
      state.itemEventoUsuarioIdSuccess = false;
      state.itemEventoUsuarioIdData = undefined;
    });
    builder.addCase(getEventoUsuarioById.pending, (state: InitialState) => {
      state.itemEventoUsuarioIdError = false;
      state.itemEventoUsuarioIdSuccess = true;
      state.itemEventoUsuarioIdLoading = false;
    });
    builder.addCase(getEventoUsuarioById.fulfilled, (state: InitialState, action) => {
      state.itemEventoUsuarioIdData = action.payload;
      state.itemEventoUsuarioIdError = false;
      state.itemEventoUsuarioIdSuccess = false;
      state.itemEventoUsuarioIdLoading = true;
    });



    builder.addCase(getEventos.rejected, (state: InitialState) => {
      state.itemsError = true;
      state.itemsLoading = false;
      state.itemsSuccess = false;
      state.itemsData = undefined;
    });
    builder.addCase(getEventos.pending, (state: InitialState) => {
      state.itemsError = false;
      state.itemsLoading = true;
      state.itemsSuccess = false;
    });
    builder.addCase(getEventos.fulfilled, (state: InitialState, action) => {
      state.itemsData = action.payload;
      state.itemsError = false;
      state.itemsLoading = false;
      state.itemsSuccess = true;
    });

    builder.addCase(getEventoById.rejected, (state: InitialState) => {
      state.itemIdError = true;
      state.itemIdLoading = false;
      state.itemIdSuccess = false;
      state.itemIdData = undefined;
    });
    builder.addCase(getEventoById.pending, (state: InitialState) => {
      state.itemIdError = false;
      state.itemIdSuccess = true;
      state.itemIdLoading = false;
    });
    builder.addCase(getEventoById.fulfilled, (state: InitialState, action) => {
      state.itemIdData = action.payload;
      state.itemIdError = false;
      state.itemIdSuccess = false;
      state.itemIdLoading = true;
    });


    builder.addCase(getEventosChild.rejected, (state: InitialState) => {
      state.itemsChildError = true;
      state.itemsChildLoading = false;
      state.itemsChildSuccess = false;
      state.itemsChildData = undefined;
    });
    builder.addCase(getEventosChild.pending, (state: InitialState) => {
      state.itemsChildError = false;
      state.itemsChildLoading = true;
      state.itemsChildSuccess = false;
    });
    builder.addCase(getEventosChild.fulfilled, (state: InitialState, action) => {
      state.itemsChildData = action.payload;
      state.itemsChildError = false;
      state.itemsChildLoading = false;
      state.itemsChildSuccess = true;
    });

    builder.addCase(getEventos3.rejected, (state: InitialState) => {
      state.items3Error = true;
      state.items3Loading = false;
      state.items3Success = false;
      state.items3Data = undefined;
    });
    builder.addCase(getEventos3.pending, (state: InitialState) => {
      state.items3Error = false;
      state.items3Loading = true;
      state.items3Success = false;
    });
    builder.addCase(getEventos3.fulfilled, (state: InitialState, action) => {
      state.items3Data = action.payload;
      state.items3Error = false;
      state.items3Loading = false;
      state.items3Success = true;
    });


    builder.addCase(getMisEventos.rejected, (state: InitialState) => {
      state.itemsMisError = true;
      state.itemsMisLoading = false;
      state.itemsMisSuccess = false;
      state.itemsMisData = undefined;
    });
    builder.addCase(getMisEventos.pending, (state: InitialState) => {
      state.itemsMisError = false;
      state.itemsMisLoading = true;
      state.itemsMisSuccess = false;
    });
    builder.addCase(getMisEventos.fulfilled, (state: InitialState, action) => {
      state.itemsMisData = action.payload;
      state.itemsMisError = false;
      state.itemsMisLoading = false;
      state.itemsMisSuccess = true;
    });
   
   
  },
});

export default LibrosSlice.reducer;
