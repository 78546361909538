import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useNavigate } from 'react-router-dom';
import { getLibrosById, LibroData } from '@/store/libros/librosActions';
import { Alert } from 'react-bootstrap';
import MercadoPagoButton from '@/components/pagos/MercadoPagoButton';
import PaypalCheckoutButton from '@/components/pagos/PaypalCheckoutButton';
import { createMercadoPagoPreferenceLibroId } from '@/store/purchase/purchaseActions';
import { getDatosEmpresasById } from '@/store/datosEmpresas/datosEmpresasActions';
import { ItemPreferenceProps, LibroPreferenceProps, MPItem, MPPayer } from '@/interfaces/compras';
import { getStorageKey, slugify } from '@/components/utils';
import {
  COMAFI,
  COMPRA_USER_DETALLE,
  FINANCE_BANK,
  FRANCES,
  GALICIA,
  LIBRO_COMPRA,
  MERCADO_PAGO,
  PAYPAL,
  PRODUCT_ITEM,
} from '@/components/constants';
import Loading from '@/components/loading/Loading';
import { axiosPagos } from '@/axios';

const LibroCompraFormaDePago: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { libroId } = useParams();
  const dataLibro = useAppSelector(state => state.libros?.itemIdData);
  const mercadoPagoPreference = useAppSelector(state => state.mercadoPagoPreference.itemsData);
  const isLoadingMercadoPagoPreference = useAppSelector(state => state.mercadoPagoPreference.itemsLoading);
  const datosEmpresas = useAppSelector(state => state.datosEmpresas?.itemIdData);

  const [radioValue, setRadioValue] = useState('');
  const [onSubmitted, setOnSubmitted] = useState(false);
  const [siguiente, setSiguiente] = useState(false);
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSiguiente(true);
    if (event.target.id === MERCADO_PAGO) {
      getMercadoPagoPreferenceId();
    } else {
      setStorageItems();
    }

    setRadioValue(event.target.id);
  };

  useEffect(() => {
    if (libroId) {
      dispatch(getLibrosById({ id: parseInt(libroId.split('-')[0]) }));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDatosEmpresasById({ id: 1 }));
  }, [dispatch]);

  const viewSiguiente = (item: LibroData) => {
    setOnSubmitted(true);
    if (radioValue === '') {
      return;
    }

    const compraUserDetalle = getStorageKey(COMPRA_USER_DETALLE);

    if (!compraUserDetalle) {
      const path = `/libro-compra/${item.id}-${slugify(item.nombre)}`;
      window.scrollTo(0, 0);
      navigate(path);
    } else {
      let formadepago = 1;
      if (radioValue === GALICIA) {
        formadepago = 4;
      }
      if (radioValue === FINANCE_BANK) {
        formadepago = 5;
      }
      if (radioValue === FRANCES) {
        formadepago = 6;
      }
      const libro = getStorageKey(LIBRO_COMPRA);

      axiosPagos.post('data/cud/create', compraUserDetalle).then(res2 => {
        const comprasUserDetalleId = res2.data.result.id;
        const pitem = getStorageKey(PRODUCT_ITEM);
        // data/compras/create
        const itemCompra = {
          origin: radioValue,
          compras_user_detalle_id: comprasUserDetalleId,
          payment_id: 'TRANSFERENCIA',
          product_id: pitem.item.id,
          product_type: pitem.item.type,
          amount: libro.precio,
          currency: 'ARS',
          user_id: null,
          response: JSON.stringify(''),
        };
        axiosPagos.post('data/compras/create', itemCompra).then(() => {
          if (libro) {
            try {
              localStorage.removeItem(COMPRA_USER_DETALLE);
              localStorage.removeItem(PRODUCT_ITEM);
              localStorage.removeItem(LIBRO_COMPRA);
            } catch (error) {
              console.error(error);
            }

            const path = `/libro-compra-gracias/${libro.id}`;
            navigate(path);
          }
        });
      });
    }
  };

  const viewAnterior = (item: LibroData) => {
    const path = `/libro-compra/${item.id}-${slugify(item.nombre)}`;
    window.scrollTo(0, 0);
    navigate(path);
  };

  const getMercadoPagoPreferenceId = async () => {
    const compraUserDetalle = getStorageKey(COMPRA_USER_DETALLE);
    if (compraUserDetalle) {
      const item: MPItem = {
        id: dataLibro?.id,
        type: LIBRO_COMPRA,
      };

      const payer: MPPayer = {
        name: compraUserDetalle.nombre_y_apellido,
        email: compraUserDetalle.email,
      };

      const dataItem: ItemPreferenceProps = {
        item: item,
        payer: payer,
      };

      const data: LibroPreferenceProps = {
        item: dataItem,
        compra_user_detalle: compraUserDetalle,
      };

      localStorage.setItem(PRODUCT_ITEM, JSON.stringify(dataItem));
      localStorage.setItem(LIBRO_COMPRA, JSON.stringify(dataLibro));
      await dispatch(createMercadoPagoPreferenceLibroId(data));
    }
  };

  const setStorageItems = () => {
    const compraUserDetalle = getStorageKey(COMPRA_USER_DETALLE);
    if (compraUserDetalle) {
      const item: MPItem = {
        id: dataLibro?.id,
        type: LIBRO_COMPRA,
      };

      const payer: MPPayer = {
        name: compraUserDetalle.nombre_y_apellido,
        email: compraUserDetalle.email,
      };

      const data: ItemPreferenceProps = {
        item: item,
        payer: payer,
      };

      localStorage.setItem(PRODUCT_ITEM, JSON.stringify(data));
      localStorage.setItem(LIBRO_COMPRA, JSON.stringify(dataLibro));
    }
  };

  return (
    <>
      {isLoadingMercadoPagoPreference && <Loading />}
      {/* Breadcrumb */}
      <section className="container d-none d-lg-block mt-2">
        <div className="row">
          <div className="col-12">
            <div className="border-top py-2">
              <nav style={{}} aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Inicio</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/libros">Libros de Pablo Almazán</a>
                  </li>
                  <li className="breadcrumb-item"> {dataLibro ? dataLibro.nombre : ''}</li>
                  <li className="breadcrumb-item" aria-current="page">
                    Envíos a Argentina
                  </li>

                  <li className="breadcrumb-item active" aria-current="page">
                    Formas de Pago
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* Header */}
      {dataLibro && (
        <>
          <header className="container my-2 my-md-3">
            <div className="row g-4">
              <div className="col-md-12">
                <h1 className="display-4 mb-4">{dataLibro.nombre}</h1>
                <h6 className="text-secondary">{dataLibro.autor}</h6>
                <hr />
              </div>
            </div>
          </header>
          <section className="container mb-5">
            <div className="row g-4">
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <div className="col-md-12">
                      <img src={dataLibro.imagen} className="img-fluid" alt="" />
                    </div>
                    <hr />
                    <ul className="evento-info">
                      <li className="icon-currency d-flex align-items-center my-1">
                        ${dataLibro.precio ? dataLibro.precio : ''}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="card p-2">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-8">
                        <h6 className="text-primary">Compra del libro</h6>
                      </div>
                      <div className="col-4 text-end text-muted">
                        <h6>Paso 2 de 3</h6>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <h6 className="text-primary">Medio de pago</h6>
                    <div className="row justify-content-end">
                      <div className="col-md-12 mb-3 border rounded p-3">
                        <div className="row">
                          <div className="col-6">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id={COMAFI}
                                onChange={handleRadioChange}
                              />
                              <label className="form-check-label text-primary icon-bank" htmlFor={COMAFI}>
                                Transferencia bancaria COMAFI
                              </label>
                            </div>
                          </div>
                          <div className="col-6">
                            Al escoger esta opción y presionar el botón <strong className="text-primary">Siguiente</strong>{' '}
                            recibirás un mail en la casilla que has brindado con todos los datos para hacer una{' '}
                            <strong className="text-primary">transferencia</strong> o{' '}
                            <strong className="text-primary">depósito bancario</strong>.
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3 border rounded p-3">
                        <div className="row">
                          <div className="col-6">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id={MERCADO_PAGO}
                                onChange={handleRadioChange}
                              />
                              <label className="form-check-label text-primary icon-wallet" htmlFor={MERCADO_PAGO}>
                                MercadoPago
                              </label>
                            </div>
                          </div>
                          <div className="col-6">
                            Por este medio de pago podrás abonar con Tarjeta de Crédito y Débito, Rapipago, Pago Fácil, etc.
                            <br />
                            Dependiendo de tu tarjeta de crédito podrás tener diferentes opciones de cuotas/mensualidades sin
                            interés.
                            <br />
                            Este sistema de cobro agrega automáticamente un 5% por el servicio.
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3 border rounded p-3">
                        <div className="row">
                          <div className="col-6">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id={PAYPAL}
                                onChange={handleRadioChange}
                              />
                              <label className="form-check-label text-primary icon-paypal" htmlFor={PAYPAL}>
                                PayPal
                              </label>
                            </div>
                          </div>
                          <div className="col-6">Realizar el pago mediante PayPal.</div>
                        </div>
                      </div>
                      {radioValue === '' && onSubmitted && (
                        <Alert variant="danger">
                          <p>Debe seleccionar un medio de pago para continuar</p>
                        </Alert>
                      )}

                      {radioValue === MERCADO_PAGO && mercadoPagoPreference ? (
                        <div className="col-12 mb-3 d-grid">
                          <MercadoPagoButton preferenceId={mercadoPagoPreference.id} />
                        </div>
                      ) : radioValue === PAYPAL ? (
                        <div className="col-12 d-grid paypalbutton">
                          <PaypalCheckoutButton
                            product={{
                              description: dataLibro.nombre,
                              price: datosEmpresas
                                ? Number(((dataLibro.precio * 1.05) / datosEmpresas.CotizacionDolar).toFixed(2))
                                : dataLibro.precio * 1.05,
                            }}
                          />
                        </div>
                      ) : (
                        <div className="col-6 mb-3 d-grid">
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            disabled={!siguiente}
                            onClick={() => viewSiguiente(dataLibro)}>
                            Siguiente <i className="bi bi-chevron-right" />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default LibroCompraFormaDePago;
