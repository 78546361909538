import { createSlice } from '@reduxjs/toolkit';
import { LocacionesResponse, getPaises, getlocalidades, getProvincias } from './locacionesActions';


interface InitialState {
  paisesSuccess: boolean;
  paisesData: undefined | LocacionesResponse;
  paisesLoading: boolean;
  paisesError: boolean;

  provinciasSuccess: boolean;
  provinciasData: undefined | LocacionesResponse;
  provinciasLoading: boolean;
  provinciasError: boolean;

  localidadesSuccess: boolean;
  localidadesData: undefined | LocacionesResponse;
  localidadesLoading: boolean;
  localidadesError: boolean;
}

const initialState: InitialState = {
  paisesSuccess: false,
  paisesData: undefined,
  paisesLoading: false,
  paisesError: false,

  provinciasSuccess: false,
  provinciasData: undefined,
  provinciasLoading: false,
  provinciasError: false,

  localidadesSuccess: false,
  localidadesData: undefined,
  localidadesLoading: false,
  localidadesError: false,
 
};

const locacionesSlice = createSlice({
  name: 'locaciones',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getPaises.rejected, (state: InitialState) => {
      state.paisesError = true;
      state.paisesLoading = false;
      state.paisesSuccess = false;
      state.paisesData = undefined;
    });
    builder.addCase(getPaises.pending, (state: InitialState) => {
      state.paisesError = false;
      state.paisesLoading = true;
      state.paisesSuccess = false;
    });
    builder.addCase(getPaises.fulfilled, (state: InitialState, action) => {
      state.paisesData = action.payload;
      state.paisesError = false;
      state.paisesLoading = false;
      state.paisesSuccess = true;
    });

    builder.addCase(getProvincias.rejected, (state: InitialState) => {
      state.provinciasError = true;
      state.provinciasLoading = false;
      state.provinciasSuccess = false;
      state.provinciasData = undefined;
    });
    builder.addCase(getProvincias.pending, (state: InitialState) => {
      state.provinciasError = false;
      state.provinciasLoading = true;
      state.provinciasSuccess = false;
    });
    builder.addCase(getProvincias.fulfilled, (state: InitialState, action) => {
      state.provinciasData = action.payload;
      state.provinciasError = false;
      state.provinciasLoading = false;
      state.provinciasSuccess = true;
    });


    builder.addCase(getlocalidades.rejected, (state: InitialState) => {
      state.localidadesError = true;
      state.localidadesLoading = false;
      state.localidadesSuccess = false;
      state.localidadesData = undefined;
    });
    builder.addCase(getlocalidades.pending, (state: InitialState) => {
      state.localidadesError = false;
      state.localidadesLoading = true;
      state.localidadesSuccess = false;
    });
    builder.addCase(getlocalidades.fulfilled, (state: InitialState, action) => {
      state.localidadesData = action.payload;
      state.localidadesError = false;
      state.localidadesLoading = false;
      state.localidadesSuccess = true;
    });
    
  },
});


export default locacionesSlice.reducer;
