import React, { useCallback } from 'react';
import { loginUser } from '@/store/user/userActions';
import { LoginUserProps } from '@/interfaces/user';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import LoginForm from '@/components/organisms/LoginForm';
import MyNav2 from '@/components/templates/MyNav2';
 

const RecuperarPassword: React.FC = () => {
  const dispatch = useAppDispatch();

  const { loading, userInfo } = useAppSelector(state => state.user);

  const handleLogin = useCallback((values: LoginUserProps) => dispatch(loginUser(values)), [dispatch]);

  return (
    <>
    {/* Breadcrumb */}
    <section className="container d-none d-lg-block mt-2">
      <div className="row">
        <div className="col-12">
          <div className="border-top py-2">
            <nav style={{}} aria-label="breadcrumb" className="d-none d-md-block">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Inicio</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/login">Iniciar Sesión</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Recuperar Contraseña
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>
    {/* Header */}
    <header className="container my-2 my-md-5 py-5">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <h1 className="mb-5 text-center">Recuperar Contraseña</h1>
        </div>
        <div className="col-md-6 text-center">
          <p className="lead">
            Por favor, para recuperar su usuario y/o contraseña, mandar un mail a{" "}
            <a href="mailto:organizacionhp@gmail.com">organizacionhp@gmail.com</a>{" "}
            aclarando el motivo del contacto nombre completo. ¡Gracias!
          </p>
        </div>
      </div>
    </header>
  </>
  

 
  );
};

export default RecuperarPassword;
