import React from 'react';
import NewsletterForm from './NewsletterForm';
const Footer: React.FC = () => (
  <>
  {/* Footer */}
  <footer className="text-white">
    <section className="footer-bg-01 py-4">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h2>Suscribite para recibir novedades</h2>
          </div>
          <div className="col-md-6">
            <NewsletterForm></NewsletterForm>
           
          </div>
        </div>
      </div>
    </section>
    <section className="footer-bg-02 py-4 py-md-5">
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-4">
            <img src="/img/hp_iso.svg" className="footer-logo" alt="" />
          </div>
          <div className="col-md-4 mb-3">
            <ul className="list-unstyled">
              <li className="mb-2">
                <a className="footer-link" href="/somos">
                  Somos Humano Puente
                </a>
              </li>
              <li className="mb-2">
                <a className="footer-link" href="/actividades">
                  Actividades
                </a>
              </li>
              <li className="mb-2">
                <a className="footer-link" href="/consultores">
                  Consultores
                </a>
              </li>
              <li className="mb-2">
                <a className="footer-link" href="/empresas">
                  Empresas y Economía
                </a>
              </li>
              <li className="mb-2">
                <a className="footer-link" href="/video">
                  Videos
                </a>
              </li>
              <li className="mb-2">
                <a className="footer-link" href="/contacto">
                  Contacto
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <p>Seguínos en las redes</p>
            <ul className="list-inline footer-social">
              <li className="list-inline-item me-2">
                <a href="https://www.facebook.com/apalmazan/" target="_blank">
                  <i className="bi bi-facebook" />
                </a>
              </li>
              <li className="list-inline-item me-2">
                <a
                  href="https://instagram.com/pabloalmazanhumanopuente"
                  target="_blank"
                >
                  <i className="bi bi-instagram" />
                </a>
              </li>
              <li className="list-inline-item me-2">
                <a href="https://www.tiktok.com/@humanopuente" target="_blank">
                  <i className="bi bi-tiktok" />
                </a>
              </li>
              <li className="list-inline-item me-2">
                <a
                  href="https://wa.me/+5491124980681"
                  target="_blank"
                >
                  <i className="bi bi-whatsapp" />
                </a>
              </li>
              <li className="list-inline-item me-2">
                <a href="https://www.youtube.com/@humanopuente" target="_blank">
                  <i className="bi bi-youtube" />
                </a>
              </li>
              <li className="list-inline-item me-2">
                <a
                  href="https://open.spotify.com/show/2RR5yIdRaz0NzBQe8kTFQ2?si=d56fa760f3654585"
                  target="_blank"
                >
                  <i className="bi bi-spotify" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </footer>
</>

);

export default Footer;
