import React, { IframeHTMLAttributes, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useNavigate } from 'react-router-dom';
 
import {
  getEventoUsuarioById, EventoData, getEventoUnidadById, setPaso
} from '@/store/eventos/eventosActions';
import { slugify } from '@/components/utils';

import { useParams } from 'react-router-dom';

interface CustomIframeProps extends IframeHTMLAttributes<HTMLIFrameElement> {
  mozallowfullscreen?: string;
  webkitallowfullscreen?: string;
}
const Unidades: React.FC = () => {
 

  const decodeEntities = (text: string) => {
    const element = document.createElement("div");
    element.innerHTML = (text.length > 120) ? text.substring(0, 200) + '...' : text;
    return element.innerText;
  }
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const pValue = urlParams.get('p');
  const [step, setStep] = useState<number | null>(null);
  const [stepUsuario, setStepUsuario] = useState<number | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { codigoEvento } = useParams();

 const dataEventoUsuario = useAppSelector((state) => state.eventos?.itemEventoUsuarioIdData);
 const dataEventoUnidad = useAppSelector((state) => state.eventos?.itemIdData);
 

 useEffect(() => {
  dispatch(getEventoUsuarioById({ id: parseInt(codigoEvento ?? '') }));
}, [dispatch]);

 useEffect(() => {
    dispatch(getEventoUnidadById({ id: parseInt(codigoEvento ?? '') }));
 }, [dataEventoUsuario]);


 const [isCollapsed, setCollapsed] = useState(true);

 const toggleCollapse = () => {
   setCollapsed(!isCollapsed);
 };
 
 useEffect(() => {

    if (pValue)
    {
      setStep(parseInt(pValue));
      if (dataEventoUsuario)
      {
        setStepUsuario(dataEventoUsuario.Paso+1);
      }
    }
    else
    {
      if (dataEventoUsuario)
      {
        setStep(dataEventoUsuario.Paso+1);
        setStepUsuario(dataEventoUsuario.Paso+1);
      }

      if (dataEventoUsuario && dataEventoUnidad && dataEventoUsuario.Paso  >= dataEventoUnidad.evento_unidades.length )
      {
       

        dispatch(setPaso({ id: dataEventoUsuario?.IdEvento, finalizado:1  }));
         
        navigate("/unidades-finalizada/" + dataEventoUsuario?.IdEvento);
  
      }
    }
 
  }
 , [dataEventoUnidad]);

  

  function extractSrcFromIframe(iframeString:string):string {

    const parsedInt = parseInt(iframeString);
    if (parsedInt)  
    {
      return "https://player.vimeo.com/video/" + iframeString;
    }

    const regex = /<iframe.*?src="(.*?)".*?>/g;
    const match = regex.exec(iframeString);
    const srcValue = match ? match[1] : "";

    

    return srcValue;
  }
 
 
  
  return (

    <>
    {/* Breadcrumb */}
    <section className="container d-none d-lg-block mt-2">
      <div className="row">
        <div className="col-12">
          <div className="border-top py-2">
            <nav style={{}} aria-label="breadcrumb" className="d-none d-md-block">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Inicio</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/misactividades">Mis Actividades</a>
                </li>
                <li className="breadcrumb-item">
                 {dataEventoUnidad  && (<>Paso {dataEventoUnidad.Titulo}</>)}
                 
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                {dataEventoUsuario && step && (<>Paso {step}</>)}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>
    {/* Content */}
    {dataEventoUsuario && step  && dataEventoUnidad && (
    <section className="container mt-2 mb-2  mt-md-2 mb-md-5">
     
      <div className="row align-items-center">
        <div className="col">
          <h1 className="display-5">{dataEventoUnidad.Titulo}</h1>
          <h2 className="text-secondary mb-4">Paso {step}</h2>
        </div>
         <div className="col-md-12">
          <div
            className="accordion accordion-flush border-bottom"
            id="accordionFlushExample"
          >
            <div className="accordion-item">
              <h1 className="accordion-header" id="flush-headingOne">
                <button
                 className={`accordion-button ${isCollapsed ? 'collapsed' : ''}`}
                 type="button"
                 onClick={toggleCollapse}
                 aria-expanded={!isCollapsed}
                 aria-controls="flush-collapseOne"
                >
                  Pasos de la Actividad
                </button>
              </h1>
              <div
                id="flush-collapseOne"
               
                aria-labelledby="flush-headingOne"
                className={`accordion-collapse collapse ${isCollapsed ? '' : 'show'}`}
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <table className="table table-hover">
                    <tbody>
                    {dataEventoUnidad && dataEventoUnidad.evento_unidades.map(data => {
                            if (data.Orden == step) {
                              return (
                                
                                <tr>
                                <td>{ data.Nombre }</td>
                                <td className="text-end">Unidad Actual</td>
                              </tr>
                                      
                              );
                            } else {
                              if (data.Orden > step)
                              {

                                if (stepUsuario && data.Orden >  stepUsuario)
                                {
                                      return (
                                      
                                        <tr>
                                        <td>{ data.Nombre }</td>
                                        <td className="text-end" />
                                      </tr>
                                              
                                      );
                                }
                                else
                                {
                                  return (
                                      
                                    <tr>
                                    <td>{ data.Nombre } </td>
                                    <td className="text-end"><a href={`/unidades/${data.IdEvento}/?p=${data.Orden}`}>Volver a ver el video</a></td>
                                  </tr>
                                          
                                  );

                                }
                              }
                              else
                              {
                                return (
                                
                                  <tr>
                                  <td>{ data.Nombre }</td>
                                  <td className="text-end"><a href={`/unidades/${data.IdEvento}/?p=${data.Orden}`}>Volver a ver el video</a></td>
                                </tr>
                                        
                                );
                              }
                              
                            }
                          })}
                       
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      
        <div className="col-12 mt-3 mb-3">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
          {dataEventoUnidad && dataEventoUnidad.evento_unidades.map(data => {
             if (data.Orden == step) {

             

              const iframeProps: CustomIframeProps = {
               
                src: extractSrcFromIframe( data.Video ),
                width: "600",
                height: "400",
                frameBorder: 0,
                allowFullScreen: true,
                mozallowfullscreen: "true",
                webkitallowfullscreen: "true",
              };
            

              return (
                
                <iframe key={data.IdEventoUnidad} 
                {...iframeProps}
                 
                />
               
              );
            } else {
              return null; 
            }
          })}
          </div>
        </div>
        <div className="col-12 text-end">
        {dataEventoUnidad && dataEventoUnidad.evento_unidades.map(data => {
             if (data.Orden == step) {
              if (pValue) 
              {

                        return (
                      
                          <a key={"a_"  + data.IdEventoUnidad}  href={"/misactividades"} className="btn btn-primary">
                            Volver a Mis Actividades<i className="bi bi-chevron-right" />
                          </a>
                      
              
                    );

              } 
              else {
              return (
              
                    <a key={"a_"  + data.IdEventoUnidad}  href={"/preguntas/" + data.IdEvento + "?p=" +data.IdEventoUnidad } className="btn btn-primary">
                      Continuar <i className="bi bi-chevron-right" />
                    </a>
                
         
              );
          } 
        }})}
        
        </div>
      </div>
    </section>)}
  </>
  


  );
};

export default Unidades;
