import React, { useCallback , useState} from 'react';
import { loginUser } from '@/store/user/userActions';
import { LoginUserProps } from '@/interfaces/user';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
 
 

const CaminoHP: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isOpen, setisOpen] = useState(false);
  const menuClass = `collapse col-12 my-3 ${isOpen ? " show" : ""}`;
  const toggleOpen = () => setisOpen( !isOpen);
 
 
 

  return (
 
  <>
  {/* Breadcrumb */}
  <section className="container d-none d-lg-block mt-2">
    <div className="row">
      <div className="col-12">
        <div className="border-top py-2">
          <nav style={{}} aria-label="breadcrumb" className="d-none d-md-block">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Inicio</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/actividades">Actividades</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Camino Humano Puente
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </section>
  {/* Header */}
  <header className="container my-2 my-md-5">
    <div className="row">
      <div className="col-md-6">
        <h1 className="display-4 mb-4">Camino Humano Puente</h1>
      </div>
      <div className="col-md-6 text-start">
        <p className="lead">
          Humano Puente continua creciendo dia a dia en todo el Mundo. Gracias!
          Siempre con el propósito de que este conocimiento pueda llegar a toda
          la Madre Tierra, hoy contamos con la posibilidad de hacer este camino
          COMPLETO desde toda la Madre Tierra para ser CONSULTOR/a EN
          BIOEXISTENCIA CONSCIENTE, el más profundo de los pasos y el tercero de
          este camino. Hoy es posible tambien hacer todo MODO ONLINE. Para
          informarse le sugerimos recorrer el contenido de esta solapa y la de
          Cómo hacer el camino de Humano Puente.
        </p>
        <a
 
          className="btn btn-outline-primary"
          data-bs-toggle="collapse"
          id="toggle"
          href="#hiddenContent"
          role="button"
          onClick={toggleOpen}
          aria-expanded="false"
          aria-controls="collapseExample"
        >
            {(isOpen)?'-':'+'} info
        </a>
      </div>
      <div      className={menuClass}  >
        <hr />
        <h6 className="text-secondary mb-4">Pasos para ser un Humano Puente</h6>
        <p>
          Amigos: Gracias por los mails, el amor y las solicitudes que nos
          llegan. Les comentamos aquí el modo de hacer el primer paso, la
          Jornada Online.
        </p>
        <h4 className="text-primary">
          ¿Qué son las jornadas Online de Humano Puente?
        </h4>
        <p>
          El resumen de esta vivencia se podría enmarcar como una gran consulta
          y una enorme toma de conciencia que provoca en muchos casos el
          replanteo de toda una manera de ver y vivir la vida, donde se comienza
          a ver la vida desde la mirada nueva que otorga este campo, a conocer
          sus herramientas básicas, a comprender{" "}
          <strong>para que cosa buena</strong> llega en determinado momento un
          hecho que es considerado traumático y que nosotros llamamos{" "}
          <strong>informedad</strong>, cual es su mensaje, qué debemos hacer
          para que ya no sea necesaria. Esto es muy importante para que nuestra
          parte consciente, lógica, comprenda y acepte los motivos por los que
          la Decodificación Bioemocional, primer paso de la{" "}
          <strong>Bioexistencia Consciente</strong> realmente funciona. Es aquí
          donde personas de, por ejemplo, un pensamiento concreto, científico,
          comprenden que son las fuerzas previas a cada cosa las que realmente
          determinan su existencia.{" "}
        </p>
        <p>
          Luego de esta actividad, que puede llevar desde días hasta semanas
          poder recorrer, todos comprenden por qué este camino decimos que no es
          un metodo de sanación de enfermedades, sino de reencuentro del Ser con
          su camino. Solo utilizamos el síntoma para ingresar al SER que
          verdaderamente eres, esa es la única e importantísima finalidad que
          para nosotros tiene cada síntoma. Este es el primer paso para quienes
          desean ser <strong>Consultores en Bioexistencia Consciente</strong>.
          Para seguir este camino maravilloso hay que hacer luego la Apertura en
          Decodificación Bioemocional y la Apertura en Bioexistencia Consciente.
          Hay un video específico sobre este tema en esta <strong>web</strong>.
        </p>
        <p>
          Otra manera <strong>maravillosa</strong> de comenzar este camino es
          gracias a que hoy existen los{" "}
          <strong>Comunicadores de Humano Puente</strong> que bridan las{" "}
          <strong>Charlas Introductorias de un día</strong>. Esta manera es
          óptima por dos motivos: 1- Al hacerla contarás con la parte presencial
          o en <strong>Vivo Online</strong> y 2- Por participar de la charla
          sólo abonarás el 50% del valor de la Jornada online y, de ese modo,
          tendrás las dos cosas a un precio menor que el que abonarías si sólo
          hicieras las Jornadas online.
        </p>
        <p>
          Para obtener información para ser Consultor en Bioexistencia
          Consciente, pedimos enviar un mail a organizacionhp@gmail.com o
          completar el fomulario de contacto (revisar casilla de Spam si no
          llega una respuesta en 72 hs). Para hacer las Aperturas es requisito
          haber realizado previamente la Jornada Online. ya sea que la hayas
          hecho luego de una charla introductoria o no.
        </p>
        <p>
          Sugerimos también dar este primer paso también antes de una consulta
          individual. Si desea ver el listado de Consultores en Bioexistencia
          Consciente podrá verlos en la solapa{" "}
          <strong>
            <a href="consultores.html">Consultores</a>
          </strong>
          .
        </p>
        <p>
          Gracias, en lo posible recomendamos ver todo el material de la sección{" "}
          <strong>
            <a href="\video">Video</a>
          </strong>
          . Un abrazo.
        </p>
      </div>
    </div>
  </header>
  <section className="container my-4 my-md-5">
    <div className="row g-4">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-md-6">
                <h6 className="text-secondary">Paso Previo Opcional</h6>
                <p>
                  con beneficio sobre el valor de la Jornada Online Humano
                  Puente
                </p>
                <h1>Charlas Introductorias</h1>
              </div>
              <div className="col-md-6 text-md-end mt-3 mt-md-0">
                <a href='/eventos/charlas-introductorias'>
                <button type="button" 
                className="btn btn-outline-primary">
                  + información
                </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4  d-flex align-items-stretch">
        <div className="card pb-5 card-bg-01">
          <div className="card-body">
            <h6 className="text-secondary">Primer Paso</h6>
            <h1>Jornadas Online Humano Puente</h1>
            <a href="/eventos/jornada-online" className="btn btn-outline-primary mt-3 mt-md-0">
              + información
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-4  d-flex align-items-stretch">
        <div className="card pb-5 card-bg-02">
          <div className="card-body">
            <h6 className="text-secondary">Segundo Paso</h6>
            <h1>Apertura en Decodificación Bioemocional</h1>
            <a href="/eventos/apertura-decodificación-bioemocional" className="btn btn-outline-primary mt-3 mt-md-0">
              + información
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-4  d-flex align-items-stretch">
        <div className="card pb-5 card-bg-03">
          <div className="card-body">
            <h6 className="text-secondary">Tercer Paso</h6>
            <h1>Apertura en Bioexistencia Consciente</h1>
            <a href="/eventos/apertura-bioexistencia-consciente" className="btn btn-outline-primary mt-3 mt-md-0">
              + información
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</>


 
  );
};

export default CaminoHP;
