import React, { useCallback, useState } from 'react';
import { loginUser } from '@/store/user/userActions';
import { LoginUserProps } from '@/interfaces/user';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import LoginForm from '@/components/organisms/LoginForm';
import MyNav2 from '@/components/templates/MyNav2';


const Campus: React.FC = () => {


  const [isOpen, setisOpen] = useState(false);
  const menuClass = `col-12 my-3 collapse ${isOpen ? " show" : ""}`;
  const toggleOpen = () => setisOpen( !isOpen);

  const dispatch = useAppDispatch();


  return (
    <>
      <header className="actividades">
      <MyNav2/>
    {/* Breadcrumb */}
    <section className="container d-none d-lg-block mt-2">
      <div className="row">
        <div className="col-12">
          <div className="border-top py-2">
            <nav style={{}} aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-light">
                <li className="breadcrumb-item">
                  <a href="/">Inicio</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Campus
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>
    {/* Titulo */}
    <section className="container text-white">
      <div className="row">
        <div className="col-md-9 col-lg-6 my-5">
          <h1 className="display-3 mt-5">Campus</h1>
          <p className="lead mb-5">
          Este espacio al que estás ingresando es exclusivo para Consultores Certificados de Humano Puente, quienes realizan su actividad de manera actualizada y con un grado de compromiso con ellos mismos que los ha llevado a dedicarse a la Bioexistencia Consciente de manera profesional. Si eres uno de ellos, una de ellas. Felicitaciones. Bienvenid@ al Campus. Aquí encontrarás sus dos entradas. Si aún no eres Consultor/a en Bioexistencia Consciente, te recomendamos visitar la sección ACTIVIDADES.
       
          </p>
        </div>
      </div>
    </section>
    </header>
    <section className="container py-5">
      <div className="row">
        <div className="col-lg-6">
          <h2 className="text-secondary">Bienvenid@</h2>
        </div>
        <div className="col-lg-6">
          <p className="mb-3">
          Hasta el momento, no he conocido una manera más eficaz y fructífera de compensar el permanente uso del hemisferio izquierdo, que la de tener una práctica permanente, lógica y creciente, desde el hemisferio derecho. Eso es lo que este espacio provee y por eso sus incomparables resultados en los Consultantes y en la vida de los Consultores. De aquí surgen, en mi opinión y por todo lo que he conocido, los mayores sanadores de vidas de la historia. Me honra acompañarlos  < br/>  < br/>Pablo Almazán.     
          </p>
          
        </div>
      
      </div>
    </section>
    <section className="container text-center mb-5">
      <div className="row">
        <div className="col-md-6 p-md-5 actividad-border">
          <img src="/img/seminarios.svg" alt="" className="big-icon mt-5 mb-3" />
          <h1 className="mb-3">
            Empresas y
            <br />
            Economía
          </h1>
          <a
            className="btn btn-outline-primary mb-5"
            href="https://foro.humanopuente.com.ar/"
            target='_blank'
            role="button"
          >
            Ingresar 
          </a>
        </div>
        <div className="col-md-6 p-md-5 actividad-border">
          <img src="./img/seminarios.svg" alt="" className="big-icon mt-5 mb-3" />
          <h1 className="mb-3">
           Campus<br /> Humano Puente
            
          </h1>
          <a
            className="btn btn-outline-primary mb-5"
            href="https://campus.humanopuente.com.ar/"
            target='_blank'
            role="button"
          >
             Ingresar 
          </a>
        </div>
      </div>
    </section>

  </>

  );
};

export default Campus;
