import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import env from '@beam-australia/react-env';
import { axiosPagos } from '@/axios';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useNavigate } from 'react-router-dom';
import {
  EventoData,
  getEventoById,
  purchasePorTransferencia
} from '@/store/eventos/eventosActions';
import { Alert } from 'react-bootstrap';
import MercadoPagoButton from '@/components/pagos/MercadoPagoButton';
import PaypalCheckoutButton from '@/components/pagos/PaypalCheckoutButton';
import { createMercadoPagoPreferenceId } from '@/store/purchase/purchaseActions';
import { ItemPreferenceProps, MPItem, MPPayer, MPPhone, MPIdentification, MPAddress } from '@/interfaces/compras';
import { getStorageKey, getMedioDePagoValue, getPriceByGroup, parseCopete, getPriceDescription, getMoneda, getPriceByGroupPaypal, parseFecha,parseHora, obtenerParte } from '@/components/utils';
import { obtenerMetodosDePago, slugify } from '@/components/utils';
import { GetCountryGroupByIP } from "@/store/iplocation/ipLocationActions";
import { COMAFI, COMPRA_USER_DETALLE, EVENTO_COMPRA, MERCADO_PAGO, PAYPAL, PRODUCT_ITEM , FINANCE_BANK, GALICIA, FRANCES, STRIPE } from '@/components/constants';
import Loading from '@/components/loading/Loading';
import { store } from '@/store';
import { getDatosEmpresasById } from '@/store/datosEmpresas/datosEmpresasActions';
import StripeButton from '@/components/pagos/StripeButton';

const EventoInscripcion2: React.FC = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { eventoId } = useParams();
  const datosEmpresas = useAppSelector((state) => state.datosEmpresas?.itemIdData);
  const dataEvento = useAppSelector((state) => state.eventos?.itemIdData);
  const mercadoPagoPreference = useAppSelector((state) => state.mercadoPagoPreference.itemsData);
  const isLoadingMercadoPagoPreference = useAppSelector((state) => state.mercadoPagoPreference.itemsLoading);
  const ipLocation = useAppSelector((state) => state.iplocation.item);
  const { userInfo } = useAppSelector(state => state.user);
 
  const [radioValue, setRadioValue] = useState('');
  const [onSubmitted, setOnSubmitted] = useState(false);
  const [siguiente, setSiguiente] = useState(false);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  
    setSiguiente(true);
    if (event.target.id === MERCADO_PAGO) {
     
      getMercadoPagoPreferenceId();
       
    } else {
      setStorageItems();
    }

    setRadioValue(event.target.id);
  };

  useEffect(() => {
    if (eventoId) {
      dispatch(getEventoById({ id: parseInt(eventoId.split("-")[0]) }));
    }
  }, [dispatch]);

  /*useEffect(() => {
    dispatch(GetCountryGroupByIP());
  }, [dispatch]);
*/

  useEffect(() => {
    dispatch(getDatosEmpresasById({ id: 1 }));
  }, [dispatch]);


  const viewEvento = (item: EventoData) => {

    setOnSubmitted(true);
    if (radioValue === '') {
      return;
    }

    const isOnline = dataEvento?.Modalidad === 'Online'
    if (!userInfo && isOnline) {
      const path = '/registro'
      navigate(path);
    }

    if (isOnline && userInfo)
    {
      let formadepago = 1;
      if (radioValue === GALICIA) { formadepago = 4; } 
      if (radioValue === FINANCE_BANK) { formadepago = 5; } 
      if (radioValue === FRANCES) { formadepago = 6; } 

        dispatch(purchasePorTransferencia({id:item.IdEvento, precio:(getMoneda()=="ARS")?item.Precio:0, idformapago: formadepago, PrecioUsd: (getMoneda()=="USD")?item.PrecioUsd:0   }));
        
         localStorage.removeItem(COMPRA_USER_DETALLE);
         localStorage.removeItem(PRODUCT_ITEM);
         localStorage.removeItem(EVENTO_COMPRA);
        
        const path = `/evento-inscripcion-03/${item.IdEvento}-${slugify(item.Titulo)}`;
        navigate(path); 
        return;
    }

    
      const compraUserDetalle = getStorageKey(COMPRA_USER_DETALLE);
      if (!compraUserDetalle) {
        const path = `/evento-inscripcion-01/${item.IdEvento}-${slugify(item.Titulo)}`;
        navigate(path);
      }

        axiosPagos.post('data/cud/create', compraUserDetalle)
                  .then((res2) => {
                    const comprasUserDetalleId = res2.data.result.id;
                    const pitem = getStorageKey(PRODUCT_ITEM);
                    // data/compras/create
                    const itemCompra = {
                      "origin": radioValue,
                      "compras_user_detalle_id": comprasUserDetalleId,
                      "payment_id": "TRANSFERENCIA",
                      "product_id": pitem.item.id,
                      "product_type": pitem.item.type,
                      "amount": getPriceByGroup(dataEvento),
                      "currency": getMoneda(),
                      "user_id": 0,
                      "response": JSON.stringify("")
                    }
                    axiosPagos.post('data/compras/create', itemCompra)
                      .then(() => {
                        if (pitem.item.type == EVENTO_COMPRA) {
                          const evento = getStorageKey(EVENTO_COMPRA);
                          if (evento) {
                            const path = `/evento-inscripcion-03/${evento.IdEvento}-${slugify(evento.Titulo)}`;
                            navigate(path);
                          }
                        }
 
                      });
                  })

  }

  const viewEventoAnterior = (item: EventoData) => {
    const path = `/evento-inscripcion-01/${item.IdEvento}-${slugify(item.Titulo)}`;
    navigate(path);
  }

  const getMercadoPagoPreferenceId = async () => {

    //Agregado para los que son del tipo online
    if (dataEvento?.Modalidad=="Online")
    {
        
          const email = store?.getState().user?.userInfo?.email || '';
          const name = store?.getState().user?.userInfo?.nombre || '';
          const apellido = store?.getState().user?.userInfo?.apellido || '';
          const fullName = name + apellido;

          const item: MPItem = {
            id: dataEvento?.IdEvento,
            type: EVENTO_COMPRA
          }
          const payer: MPPayer = {
            name: fullName,
            email:email,
          }

          const data: ItemPreferenceProps = {
            item: item,
            payer: payer
          }

          localStorage.setItem(PRODUCT_ITEM, JSON.stringify(data));
          localStorage.setItem(EVENTO_COMPRA, JSON.stringify(dataEvento));
          await dispatch(createMercadoPagoPreferenceId(data)); 
    }
    else
    {
        const compraUserDetalle = getStorageKey(COMPRA_USER_DETALLE);
        if (compraUserDetalle) {
          const item: MPItem = {
            id: dataEvento?.IdEvento,
            type: EVENTO_COMPRA
          }
          const payer: MPPayer = {
            name: compraUserDetalle.nombre_y_apellido,
            email: compraUserDetalle.email,
          }

          const data: ItemPreferenceProps = {
            item: item,
            payer: payer
          }

          localStorage.setItem(PRODUCT_ITEM, JSON.stringify(data));
          localStorage.setItem(EVENTO_COMPRA, JSON.stringify(dataEvento));
          await dispatch(createMercadoPagoPreferenceId(data));
        }
      }
  }

  const setStorageItems = () => {
    
    if (dataEvento?.Modalidad=="Online")
    {
      const item: MPItem = {
        id: dataEvento?.IdEvento,
        type: EVENTO_COMPRA
      }
      const email = store?.getState().user?.userInfo?.email || '';
      const name = store?.getState().user?.userInfo?.nombre || '';
      const apellido = store?.getState().user?.userInfo?.apellido || '';
      const fullName = name + apellido;

      const payer: MPPayer = {
        name: fullName,
        email: email,
      }

      const data: ItemPreferenceProps = {
        item: item,
        payer: payer
      }

      localStorage.setItem(PRODUCT_ITEM, JSON.stringify(data));
      localStorage.setItem(EVENTO_COMPRA, JSON.stringify(dataEvento));
    }
    else
    {
      const compraUserDetalle = getStorageKey(COMPRA_USER_DETALLE);
      if (compraUserDetalle) {
        const item: MPItem = {
          id: dataEvento?.IdEvento,
          type: EVENTO_COMPRA
        }

        const payer: MPPayer = {
          name: compraUserDetalle.nombre_y_apellido,
          email: compraUserDetalle.email,
        }

        const data: ItemPreferenceProps = {
          item: item,
          payer: payer
        }

        localStorage.setItem(PRODUCT_ITEM, JSON.stringify(data));
        localStorage.setItem(EVENTO_COMPRA, JSON.stringify(dataEvento));
       }
    }
  }

   


  return (
    <>
    { isLoadingMercadoPagoPreference && <Loading/>}
      {/* Breadcrumb */}
      <section className="container d-none d-lg-block mt-2">
        <div className="row">
          <div className="col-12">
            <div className="border-top py-2">
              <nav style={{}} aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Inicio</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/actividades">Actividades</a>
                  </li>
               
                  <li className="breadcrumb-item active" aria-current="page">
                    {(dataEvento) ? dataEvento.Titulo : ''}

                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* Header */}
      {dataEvento && (
        <>
          <header className="container my-2 my-md-3">
            <div className="row g-4">
              <div className="col-md-12">
                <h1 className="display-4 mb-4">
                  {dataEvento.Titulo}
                </h1>
                {dataEvento.Modalidad !== 'Online' && (
                <a
                  className="btn btn-outline-secondary icon-calendar me-1 mb-2"
                  href="#"
                  role="button"
                >
                  {(dataEvento.Fecha) ? parseFecha(dataEvento.Fecha.toString()) : ''}
                </a>)}
                <a
                  className={
                    dataEvento.Modalidad === 'Online'
                      ? 'btn btn-outline-secondary   icon-video  me-1 mb-2'
                      : dataEvento.Modalidad === 'Presencial'
                        ? 'btn btn-outline-secondary   icon-presencial  me-1 mb-2'
                        : dataEvento.Modalidad === 'Video'
                          ? 'btn btn-outline-secondary   icon-video  me-1 mb-2'
                          : 'btn btn-outline-secondary   '
                  }
                  href="#"
                  role="button"
                >
                   {dataEvento.Modalidad==='Video'?'Zoom':dataEvento.Modalidad}
                </a>
                {dataEvento.Oradores && dataEvento.Oradores.split(';').map(dataOrador => (
                  <a
                    className="btn btn-outline-secondary icon-speaker me-1 mb-2"
                    href="#"
                    role="button"
                  >
                    {dataOrador}
                  </a>
                ))}
                <p className="lead my-4" dangerouslySetInnerHTML={{ __html: parseCopete(dataEvento) }} />
                <hr />
              </div>
            </div>
          </header>
          <section className="container mb-5">
            <div className="row g-4">
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <h6 className="text-secondary">Actividad</h6>
                    <h2 className="card-title">
                      {dataEvento.Titulo}
                    </h2>
                    <hr />
                    <ul className="evento-info">
                    {(!dataEvento.evento_sociales  || (dataEvento.evento_sociales && dataEvento.evento_sociales.length==0))  && dataEvento.Modalidad !== 'Online'   && (
                      <li className="icon-schedule d-flex align-items-center my-1">
                        {/* 17-09-2022 de 10:00am a 5:00pm */}
                        {(dataEvento.Fecha) ?   parseFecha(dataEvento.Fecha.toString()) : ''}
                      </li>
                      )}

                      {dataEvento.evento_sociales && dataEvento.evento_sociales.length>0 && (
                       <>
                          <li className="icon-schedule d-flex align-items-center my-1">
                            {/* 17-09-2022 de 10:00am a 5:00pm */}
                            {(dataEvento.evento_sociales[0].Fecha) ?   <> Fecha y Hora de Inicio <br/>  {  parseFecha(dataEvento.evento_sociales[0].Fecha.toString())  +  " " +  parseHora(dataEvento.evento_sociales[0].Fecha.toString()) } </>  : ''}
                     
                          </li>
                          <li className="icon-schedule d-flex align-items-center my-1">
                          {/* 17-09-2022 de 10:00am a 5:00pm */}
                          {(dataEvento.evento_sociales[0].FechaFin) ?   <>  Fecha y Hora de Fin <br/>  {   parseFecha(dataEvento.evento_sociales[0].FechaFin.toString()) + " " + parseHora(dataEvento.evento_sociales[0].FechaFin.toString())} </>   : ''}
                        </li>
                         </>
                      )}

                      
                      {dataEvento.Modalidad=='Presencial' && dataEvento.evento_sociales[0]  && dataEvento.evento_sociales[0].localidad && (
                      <li className="icon-location d-flex align-items-center my-1">{dataEvento.evento_sociales[0].provincia.Nombre + ", " +dataEvento.evento_sociales[0].localidad.Nombre}</li>
                      )}
                       {dataEvento.Modalidad!='Presencial' && (
                      <li className="icon-video d-flex align-items-center my-1">
                          {(dataEvento.Modalidad=='Video') ? 'En vivo por plataforma Zoom' : dataEvento.Modalidad }
                      </li>
                       )}
                      <li className="icon-currency d-flex align-items-center my-1">
                        {/* $1000.00— */}
                         {obtenerParte(getPriceDescription(dataEvento))}
                      </li>
                      {dataEvento.Oradores && dataEvento.Oradores.split(';').map(dataOrador => (
                        <li className="icon-speaker d-flex align-items-center my-1">
                          {dataOrador}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="card p-2">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-8">
                        <h6 className="text-primary">Inscripción a la actividad</h6>
                      </div>
                      <div className="col-4 text-end text-muted">
                        <h6>Paso 2 de 3</h6>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <h6 className="text-primary">Medio de pago</h6>
                    <div className="row justify-content-end">
                      {obtenerMetodosDePago(getMedioDePagoValue(dataEvento )).map((medio,index) => (
                        <div className="col-md-12 mb-3 border rounded p-3"
                          key={medio.metodo}>
                          <div className="row">
                            <div className="col-6">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id={medio.metodo}

                                  onChange={handleRadioChange}
                                />
                                <label
                                  className="form-check-label text-primary icon-bank"
                                  htmlFor={medio.metodo}
                                >
                                  {medio.text}
                                </label>
                              </div>
                            </div>
                            <div className="col-6" dangerouslySetInnerHTML={{ __html: medio.leyenda }}></div>
                          </div>
                        </div>
                      ))}
                      {(radioValue === '' && onSubmitted) &&
                        (<Alert variant="danger">
                          <p>Debe seleccionar un medio de pago para continuar</p>
                        </Alert>)}
                    
                      {(radioValue === MERCADO_PAGO && mercadoPagoPreference) ?
                        <div className="col-12 mb-3 d-grid">
                          <MercadoPagoButton preferenceId={mercadoPagoPreference.id} />
                        </div>
                        :

                        
                        (radioValue === PAYPAL && datosEmpresas) ?
                          <div className="col-12 mb-3 d-grid paypalbutton">
                            <PaypalCheckoutButton product={{ description: dataEvento.Titulo, price: (getPriceByGroupPaypal(dataEvento, datosEmpresas.CotizacionDolar)*1.05).toFixed(2) }} />
                          </div> :

                            (radioValue === STRIPE) ?
                            <div  >
                              <StripeButton price={Math.trunc((getPriceByGroupPaypal(dataEvento, datosEmpresas?.CotizacionDolar)*1.05)*100)}  product_name={dataEvento.Titulo}  product_id={dataEvento.IdEvento}  product_type='evento'  />
                            </div> :

                          (
                            <div className="col-6 mb-3 d-grid">
                              <button type="button" className="btn btn-outline-primary" disabled={!siguiente}
                                onClick={() => viewEvento(dataEvento)}
                              >
                                Siguiente <i className="bi bi-chevron-right" />
                              </button>
                            </div>
                          )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default EventoInscripcion2;
