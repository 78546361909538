import { createAsyncThunk } from '@reduxjs/toolkit';
import { ValidationErrors } from '@/interfaces/common';
import { axiosData, axiosPrivate } from '@/axios';

import type { AxiosError, AxiosResponse } from 'axios';
 

const modulePrefix = '/eventos';
 
export interface EventoData {
  FechaFin: any;
  IdEvento: number,
  IdCategoria: number,
  Fecha: Date,
  Titulo: string,
  Copete: string,
  Cuerpo: string,
  IdEstado:  number,
  Precio:  number,
  PrecioUsd:  number,
  PrecioGrupo1:  number,
  PrecioGrupo2:  number,
  MedioPagoArg: number,
  MedioPagoExt:  number,
  IdCategoria2:  number,
  Modalidad: string,
  Destacada: boolean,
  Oradores: string,
  imagenes:  EventoImagen[],
  evento_sociales:  EventoSocial[],
  evento_unidades:  EventoUnidadData[]
 
}

export interface Localidad {
  Nombre: string,
}
export interface Provincia {
  Nombre: string,
}

export interface EventoSocial {
  Legales: string,
  Fecha: Date,
  FechaFin: Date,
  Cupo: number,
   localidad:  Localidad,
  provincia:  Provincia,
}


export interface OpcionesPreguntaData {
  IdOpcion: number,
  IdPreguntaUnidad: number,
  Nombre: string,
  Correcto: number,
  seleccionado: boolean ,
}

export interface PreguntaData {
  IdPreguntaUnidad: number,
  IdEventoUnidad: number,
  Pregunta: string,
  Orden:  number,
  opciones: OpcionesPreguntaData[],
  
}

export interface EventoUnidadData {
  IdEventoUnidad: number,
  IdEvento: number,
  Nombre: string,
  Fecha:  string,
  FechaFin: string,
  Precio: number,
  Orden: number,
  Video:  string, 
  preguntas:  PreguntaData[], 
}

export interface EventoUsuarioData {
  IdEventoUsuario: number,
  IdEvento: number,
  IdUsuario: number,
  Precio:  number,
  Fecha: string,
  Pago: number,
  IdFormaPago: number,
  Paso:  number, 
  evento:  EventoData , 
}


export interface MisEventosData {
  evento_usuarios: EventoData[],
}


export interface CategoriaData {
  id: number,
  codigo: string,
  nombre: string,
  activo: boolean,
  Subtitle: string,
  childs: CategoriaData[],
  eventos: EventoData[],
}


export interface EventoImagen {
  Imagen: string,
}

export interface EventoResponse {
  data: EventoData[];
}

export interface EventoCompraResponse {
  IdEventoUsuario: number,
  IdEvento: number,
  IdUsuario: number,
  Pago: number,
  IdFormaPago: number,
  Paso: number,
  Finalizado: number,
  evento: EventoData,
  evento_usuarios: EventoUsuarioData[],
}

export interface MisEventoResponse {
  evento_usuarios: EventoCompraResponse[],
}

export interface EventoChildResponse {
  data: CategoriaData[];
}

export interface EventoProps {
  order?: string;
  IdCategoria2?: number;
  codigo?: string;
  activo?: number;
  id?: number;
  paso?: number;
  precio?: number;
  idformapago?: number;
  finalizado?: number;
  PrecioUsd?: number;
}

export const getEventos = createAsyncThunk(
  `${modulePrefix}/getEventos`,
  async ({  IdCategoria2 }: EventoProps, { rejectWithValue }) => {
    try { 
      const data: AxiosResponse = await axiosData.get(`/eventos?includes=imagenes&IdEstado=1&order=categoria.Nombre|Fecha,DESC|Titulo&IdCategoria2=${IdCategoria2!.toString()}`, {
        
      });
      const result: EventoResponse = data.data;
      return result;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);


export const getEventos3 = createAsyncThunk(
  `${modulePrefix}/getEventos3`,
  async ({  id }: EventoProps, { rejectWithValue }) => {
    try { 
      const data: AxiosResponse = await axiosData.get(`/eventos?limit=3&Destacada=1&order_rand`, {
        
      });
      const result: EventoResponse = data.data;
      return result;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

export const getEventosChild = createAsyncThunk(
  `${modulePrefix}/getEventosChild`,
  async ({  codigo }: EventoProps, { rejectWithValue }) => {
    try { 
      const data: AxiosResponse = await axiosData.get(`/categorias2/code/${codigo!.toString()}?includes=eventos,childs.eventos,childs.childs.eventos,childs.childs.childs.eventos`, {
        
      });
      const result: EventoChildResponse = data.data.item;
      return result;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);


export const getEventoById = createAsyncThunk(
  `${modulePrefix}/getEventosById`,
  async ({ id }: EventoProps, { rejectWithValue }) => {
    try {
      const data: AxiosResponse = await axiosData.get(`eventos/${id!.toString()}?includes=imagenes,evento_sociales`, {
      });
      const result: EventoData = data.data.item;
      return result;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);


export const getEventoUsuarioById = createAsyncThunk(
  `${modulePrefix}/eventosusuarios/idevento`,
  async ({ id }: EventoProps, { rejectWithValue }) => {
    try { 
      const data: AxiosResponse = await axiosPrivate.get(`eventosusuarios/idevento/${id!.toString()}?includes=evento`, {
      });
      const result: EventoUsuarioData = data.data.item;
      return result;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);


export const getEventoUnidadById = createAsyncThunk(
  `${modulePrefix}/eventosunidades/idevento`,
  async ({ id }: EventoProps, { rejectWithValue }) => {
    try { 
      const data: AxiosResponse = await axiosPrivate.get(`eventosunidades/${id!.toString()}`, {
      });
      const result: EventoData = data.data.item;
      return result;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);


export const getEventoUnidadPreguntaById = createAsyncThunk(
  `${modulePrefix}/eventosunidadespregunta/idevento`,
  async ({ id }: EventoProps, { rejectWithValue }) => {
    try { 
      const data: AxiosResponse = await axiosPrivate.get(`/unidades/${id!.toString()}?includes=preguntas.opciones`, {
      });
      const result: EventoUnidadData = data.data.item;
      return result;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);


export const getMisEventos = createAsyncThunk(
  `${modulePrefix}/misactividades`,
  async ({ id }: EventoProps, { rejectWithValue }) => {
    try {
      const data: AxiosResponse = await axiosPrivate.get(`misactividades`, {
      });

      const result: MisEventoResponse = data.data;
      return result;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);


 
export const setPaso = createAsyncThunk(
  `setpase/create`,
  async ({ id, paso, finalizado}: EventoProps, { rejectWithValue }) => {
    try {
     const data =   {
      paso,
      Finalizado: finalizado
    };

      await axiosPrivate.put(`/eventosusuarios/idevento/${id!.toString()}`, data);
      return null;

   } catch (err: unknown) {
      if (err instanceof Error) {
        const error: AxiosError<ValidationErrors> = err as AxiosError<ValidationErrors>;
        if (!error.response) {
            throw err;
        }
        return rejectWithValue(error.response.data);
      }
      throw err;
    }
  },
);


 
export const purchasePorTransferencia = createAsyncThunk(
  `purchasePorTransferencia`,
  async ({ id, precio, idformapago, PrecioUsd}: EventoProps, { rejectWithValue }) => {
    try {
     const data =   {
      IdEvento: id,
      Precio : precio,
      Pago: 0,
      IdFormaPago: idformapago,
      Paso: 0,
      Finalizado: 0,
      PrecioUsd: PrecioUsd
    };

      await axiosPrivate.post(`/eventosusuarios`, data);
      return null;

   } catch (err: unknown) {
      if (err instanceof Error) {
        const error: AxiosError<ValidationErrors> = err as AxiosError<ValidationErrors>;
        if (!error.response) {
            throw err;
        }
        return rejectWithValue(error.response.data);
      }
      throw err;
    }
  },
);


 
 