import React from 'react';

const Loading: React.FC = () => {
  const style: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 99999,
    pointerEvents: 'none',
  };

  return (
    <div style={style}>
    </div>
  );
};

export default Loading;
