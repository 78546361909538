import React, { useCallback } from 'react';
import { loginUser } from '@/store/user/userActions';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import MyNav2 from '@/components/templates/MyNav2';
import ContactoForm from '@/components/organisms/ContactoForm';
import EncuestaForm from '@/components/organisms/EncuestaForm';
 
const EmpresaEncuesta: React.FC = () => {
  const dispatch = useAppDispatch();
 
  return (
 <> 
 {/* Breadcrumb */}
 <section className="container d-none d-lg-block mt-2">
   <div className="row">
     <div className="col-12">
       <div className="border-top py-2">
         <nav style={{}} aria-label="breadcrumb">
           <ol className="breadcrumb">
             <li className="breadcrumb-item">
               <a href="/">Inicio</a>
             </li>
             <li className="breadcrumb-item active" aria-current="page">
             Encuesta
             </li>
           </ol>
         </nav>
       </div>
     </div>
   </div>
 </section>
 {/* Header */}
 <header className="container my-2 my-md-5">
   <div className="row g-4">
     <div className="col-md-6">
       <h1 className="display-6">ENCUESTA SOBRE CONSULTAS CON CONSULTORES CERTIFICADOS</h1>
     </div>
     <div className="col-md-6 text-start">
       <p className="lead">
       Gracias por completar esta encuesta. La misma es un canal para que las personas que han sido atendidas en consulta, hagan llegar su opinión y comentarios en general sobre la atención recibida para continuar en el permanente proceso de mejora continua de los Consultores Certificados por Humano Puente.

         <br />
         
       </p>
      
     </div>
   </div>
 </header>
 <section className="container my-2 my-md-5">
   <div className="row g-4">
   
     <div className="col-md-12">
       <div className="row">
         <div className="col-12">
           <div className="border-top pt-2 border-dark mb-4">
             <h5 className="text-primary">
               
             </h5>
           </div>
         </div>
       </div>
       
       <EncuestaForm />


     </div>
   </div>
 </section>
</>
 
  );
};

export default EmpresaEncuesta;
