import React from 'react';
import Form from 'react-bootstrap/Form';

interface FormInputProps {
  label?: string;
  inputType: string;
  type: string;
  placeholder?: string;
  value: string | string[] | boolean | number | undefined;
  isValid?: boolean | undefined;
  isInvalid?: boolean | undefined;
  readOnly?: boolean | undefined;
  onChange?: (e?: React.ChangeEvent<HTMLInputElement>) => void;
}

interface OptionalsProps {
  label?: string;
  isValid?: boolean;
  isInvalid?: boolean;
  readOnly?: boolean;
}

const FormInput: React.FC<FormInputProps> = ({
  label = '',
  inputType = 'Control',
  type = 'text',
  value = '',
  placeholder = '',
  onChange = () => {},
  isValid = false,
  isInvalid = false,
  readOnly = false,
}) => {
  const props: OptionalsProps = {};
  if (label) {
    props.label = label;
  }
  if (isValid) {
    props.isValid = isValid;
  }
  if (isInvalid) {
    props.isInvalid = isInvalid;
  }
  if (readOnly) {
    props.readOnly = readOnly;
  }

  return (
    <>
      {inputType === 'Check' ? (
        <Form.Check {...props} type="checkbox" checked={!!value} onChange={onChange} />
      ) : (
        <Form.Control
          {...props}
          type={type}
          placeholder={placeholder}
          value={typeof value == 'boolean' ? String(value) : value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
          className="form-control"
        />
      )}
    </>
  );
};

export default FormInput;
