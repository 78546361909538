import React, { useCallback } from 'react';
import { loginUser } from '@/store/user/userActions';
import { LoginUserProps } from '@/interfaces/user';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useNavigate, useLocation } from 'react-router-dom';
import CreateUser from '@/components/organisms/CreateUser';

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loading, userInfo } = useAppSelector(state => state.user);
  const params = new URLSearchParams(window.location.search);
  const back = params.get('back');
  let path = "/login";
  if (back)
  {
    path = "/login?back="+ back;

  }

 return (
  
    <>
    {/* Breadcrumb */}
    <section className="container d-none d-lg-block mt-2">
      <div className="row">
        <div className="col-12">
          <div className="border-top py-2">
            <nav style={{}} aria-label="breadcrumb" className="d-none d-md-block">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Inicio</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Registro
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>
    {/* Header */}
    <header className="container my-2 my-md-5 text-center">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <h1 className="display-3 mb-2">Crear Usuario Nuevo</h1>
          <p className="lead mb-4">
            ¿Ya se encuentra registrado? <a href={`${path}`}>Iniciar sesión</a>.
          </p>
        </div>
        <div className="col-md-8 text-start">
          {/* Login CARD */}
          <div className="card p-2 p-md-3 mb-5">
            <div className="card-body">

              <CreateUser></CreateUser>
              
            </div>
          </div>
        </div>
      </div>
    </header>
  </>
  


 
  );
};

export default Login;
