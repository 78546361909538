import { useState } from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { axiosPagos } from '@/axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { getStorageKey, slugify } from '@/components/utils';
import { COMPRA_USER_DETALLE, EVENTO_COMPRA, LIBRO_COMPRA, PAYPAL, PAYPAL_ORDER, PAYPAL_ORDER_ID, PRODUCT_ITEM } from "../constants";
import { store } from '@/store';
const PaypalCheckoutButton = (props) => {
  const { product } = props;
  const navigate = useNavigate();
  const [paidFor, setPaidFor] = useState(false);
  const [error, setError] = useState(null);

  const handleApprove = (orderId) => {
    // Call backend function to fulfill order
    localStorage.setItem(PAYPAL_ORDER_ID, JSON.stringify(orderId));

    // if response is success
    setPaidFor(true);
    // Refresh user's account or subscription status

    // if response is error
    // setError("Your payment was processed successfully. However, we are unable to fulfill your purchase. Please contact us at support@designcode.io for assistance.");
  };

  if (paidFor) {
    // Display success message, modal or redirect user to success page
    const pitem = getStorageKey(PRODUCT_ITEM);
    localStorage.removeItem(PRODUCT_ITEM);
    let compraUserDetalle = getStorageKey(COMPRA_USER_DETALLE);
    const pporder = getStorageKey(PAYPAL_ORDER);
    localStorage.removeItem(PAYPAL_ORDER);
    const pporderId = getStorageKey(PAYPAL_ORDER_ID);
    localStorage.removeItem(PAYPAL_ORDER_ID);

    if (pitem.item.type == EVENTO_COMPRA  && getStorageKey(EVENTO_COMPRA).Modalidad=="Online" )
    {
      compraUserDetalle =  {"user_id":  store?.getState().user?.userInfo?.id,"nombre_y_apellido":"-","dni":"-","pais":"-","provincia":"-","localidad":"-","telefono":"-","email":store?.getState().user?.userInfo?.email,"calle":"-","numero":"-","piso":"-","departamento":"-","codigo_postal":"-","Validacion":true};
    }
   
 
    axiosPagos.post('data/cud/create', compraUserDetalle)
      .then((res) => {
        const comprasUserDetalleId = res.data.result.id;
        localStorage.removeItem(COMPRA_USER_DETALLE);
        // data/compras/create
        const item = {
          "origin": PAYPAL,
          "compras_user_detalle_id": comprasUserDetalleId,
          "payment_id": pporder.purchase_units[0].payments.captures[0].id,
          "order_id": pporderId,
          "product_id": pitem.item.id,
          "product_type": pitem.item.type,
          "amount": pporder.purchase_units[0].amount.value,
          "currency": pporder.purchase_units[0].amount.currency_code,
          "user_id": compraUserDetalle.user_id,
          "response": JSON.stringify(pporder)
        }
        axiosPagos.post('data/compras/create', item)
          .then(() => {
            if (pitem.item.type == EVENTO_COMPRA) {
              const evento = getStorageKey(EVENTO_COMPRA);
              localStorage.removeItem(EVENTO_COMPRA);
              if (evento) {
                const path = `/evento-inscripcion-03/${evento.IdEvento}-${slugify(evento.Titulo)}?id=${pporderId}`;
                navigate(path);
              }
            }

            if (pitem.item.type == LIBRO_COMPRA) {
              const libro = getStorageKey(LIBRO_COMPRA);
              localStorage.removeItem(LIBRO_COMPRA);
              if (libro) {
                const path = `/libro-compra-gracias/${libro.id}?id=${pporderId}`;
                navigate(path);
              }
            }
          });
      })
  }

  if (error) {
    // Display error message, modal or redirect user to error page
    alert(error);
  }

  return (
    <PayPalButtons
      style={{
        color: "gold",
        layout: "horizontal",
        height: 48,
        tagline: false,
        shape: "rect"
      }}
      onClick={(data, actions) => {
        return actions.resolve();
      }}
      createOrder={(data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              description: product.description,
              amount: {
                value: product.price.toString(),
              }
            }
          ]
        });
      }}
      onApprove={async (data, actions) => {
        const order = await actions.order.capture();
        localStorage.setItem(PAYPAL_ORDER, JSON.stringify(order));

        handleApprove(data.orderID);
      }}
      onCancel={() => {
        // Display cancel message, modal or redirect user to cancel page or back to cart
      }}
      onError={(err) => {
        setError(err);
        console.error("PayPal Checkout onError", err);
      }}
    />
  );
};

export default PaypalCheckoutButton;
