import { useAppSelector } from '@/hooks/redux';
import { useFormik } from 'formik';
import { Container, Form } from 'react-bootstrap';
import { ComprasUserDetalleProps } from '@/interfaces/compras';
import * as Yup from 'yup';
import { LibroData } from '@/store/libros/librosActions';
import { COMPRA_USER_DETALLE } from '../constants';

interface CreatePurchaseLibroFormProps {
  event: (item: LibroData) => void;
  dataLibro: LibroData;
  validacion?: boolean;
}

const CreatePurchaseLibroForm: React.FC<CreatePurchaseLibroFormProps> = ({ event, dataLibro, validacion = true }) => {
  const { userInfo } = useAppSelector(state => state.user);
  const initialValues: ComprasUserDetalleProps = {
    user_id: userInfo ? userInfo.id : null,
    nombre_y_apellido: '',
    dni: '',
    pais: '',
    provincia: '',
    localidad: '',
    telefono: '',
    email: '',
    calle: '',
    numero: '',
    piso: '',
    departamento: '',
    codigo_postal: '',
    Validacion: validacion
  };

  const validationSchema = Yup.object().shape({
    nombre_y_apellido: Yup.string()
      .required('Este campo es obligatorio')
      .matches(/^([^0-9]*)$/, 'Ingresa un formato correcto')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    dni: Yup.string()
      .required('Este campo es obligatorio')
      .max(25, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    telefono: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    email: Yup.string()
      .required('Este campo es obligatorio')
      .email('Este campo tiene que ser un email')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    pais: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    localidad: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    provincia: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    codigo_postal: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    calle: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    numero: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    Validacion: Yup.boolean()
      .oneOf([true], "Debe aceptar los término y condiciones")
  });

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      localStorage.setItem(COMPRA_USER_DETALLE, JSON.stringify(values));
      event(dataLibro)
    },
    validationSchema: validationSchema,
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: false,
  });


  return (
    <>
      <Container>
        <div>
          <div className="card-body">
            <div className="row justify-content-end">
              <div className="col-md-6 mb-3">
                <Form.Group className="w-3">
                  <Form.Label>Nombre y Apellido *</Form.Label>
                  <Form.Control
                    onBlur={formik.handleBlur}
                    isInvalid={formik.errors.nombre_y_apellido ? true : false}
                    required
                    autoComplete='new-password' 
                    onChange={formik.handleChange}
                    name="nombre_y_apellido"
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.nombre_y_apellido}</Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Group className="w-3">
                  <Form.Label> Número de Documento{" "}
                    <small className="text-muted">DNI/CI/ID</small> *
                  </Form.Label>

                  <Form.Control
                    onBlur={formik.handleBlur}
                    autoComplete='new-password' 
                    isInvalid={formik.errors.dni ? true : false}
                    required
                    onChange={formik.handleChange}
                    name="dni"
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.dni}</Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Group className="w-3">
                  <Form.Label>Teléfono *</Form.Label>
                  <Form.Control
                    onBlur={formik.handleBlur}
                    isInvalid={formik.errors.telefono ? true : false}
                    required
                    autoComplete='new-password' 
                    onChange={formik.handleChange}
                    name="telefono"
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.telefono}</Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Group className="w-3">
                  <Form.Label>Mail *</Form.Label>
                  <Form.Control
                    onBlur={formik.handleBlur}
                    isInvalid={formik.errors.email ? true : false}
                    required
                    autoComplete='new-password' 
                    onChange={formik.handleChange}
                    name="email"
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Group className="w-3">
                  <Form.Label>País *</Form.Label>
                  <Form.Control
                    onBlur={formik.handleBlur}
                    isInvalid={formik.errors.pais ? true : false}
                    required
                    autoComplete='new-password' 
                    onChange={formik.handleChange}
                    name="pais"
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.pais}</Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Group className="w-3">
                  <Form.Label>Provincia *</Form.Label>
                  <Form.Control
                    onBlur={formik.handleBlur}
                    isInvalid={formik.errors.provincia ? true : false}
                    required
                    autoComplete='new-password' 
                    onChange={formik.handleChange}
                    name="provincia"
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.provincia}</Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Group className="w-3">
                  <Form.Label>Localidad *</Form.Label>
                  <Form.Control
                    onBlur={formik.handleBlur}
                    isInvalid={formik.errors.localidad ? true : false}
                    required
                    autoComplete='new-password' 
                    onChange={formik.handleChange}
                    name="localidad"
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.localidad}</Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Group className="w-3">
                  <Form.Label>Calle *</Form.Label>
                  <Form.Control
                    onBlur={formik.handleBlur}
                    isInvalid={formik.errors.calle ? true : false}
                    required
                    autoComplete='new-password' 
                    onChange={formik.handleChange}
                    name="calle"
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.calle}</Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Group className="w-3">
                  <Form.Label>Número *</Form.Label>
                  <Form.Control
                    onBlur={formik.handleBlur}
                    isInvalid={formik.errors.numero ? true : false}
                    required
                    autoComplete='new-password' 
                    onChange={formik.handleChange}
                    name="numero"
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.numero}</Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Group className="w-3">
                  <Form.Label>Piso</Form.Label>
                  <Form.Control
                   autoComplete='new-password' 
                    onBlur={formik.handleBlur}
                    isInvalid={formik.errors.piso ? true : false}
                    onChange={formik.handleChange}
                    name="piso"
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.piso}</Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Group className="w-3">
                  <Form.Label>Dpto</Form.Label>
                  <Form.Control
                   autoComplete='new-password' 
                    onBlur={formik.handleBlur}
                    isInvalid={formik.errors.departamento ? true : false}
                    onChange={formik.handleChange}
                    name="departamento"
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.departamento}</Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Group className="w-3">
                  <Form.Label>Código Postal *</Form.Label>
                  <Form.Control
                   autoComplete='new-password' 
                    onBlur={formik.handleBlur}
                    isInvalid={formik.errors.codigo_postal ? true : false}
                    required
                    onChange={formik.handleChange}
                    name="codigo_postal"
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.codigo_postal}</Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col mb-3 d-grid">
                <button type="button" className="btn btn-outline-primary"
                  onClick={() => formik.handleSubmit()}
                >
                  Siguiente <i className="bi bi-chevron-right" />
                </button>
              </div>

            </div>
          </div>

        </div>
      </Container>
    </>
  );
};

export default CreatePurchaseLibroForm;
