import React, { useCallback } from 'react';
import { loginUser } from '@/store/user/userActions';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import MyNav2 from '@/components/templates/MyNav2';
import ContactoForm from '@/components/organisms/ContactoForm';
 
const Contacto: React.FC = () => {
  const dispatch = useAppDispatch();
 
  return (
 <> 
 {/* Breadcrumb */}
 <section className="container d-none d-lg-block mt-2">
   <div className="row">
     <div className="col-12">
       <div className="border-top py-2">
         <nav style={{}} aria-label="breadcrumb">
           <ol className="breadcrumb">
             <li className="breadcrumb-item">
               <a href="/">Inicio</a>
             </li>
             <li className="breadcrumb-item active" aria-current="page">
               Contacto
             </li>
           </ol>
         </nav>
       </div>
     </div>
   </div>
 </section>
 {/* Header */}
 <header className="container my-2 my-md-5">
   <div className="row g-4">
     <div className="col-md-6">
       <h1 className="display-4">Contacto</h1>
     </div>
     <div className="col-md-6 text-start">
       <p className="lead">
         Me honra ya, el simple hecho de que Usted esté navegando esta página.
         <br />
         Gracias por llegar desde su resonancia.
       </p>
       <p className="lead">
         Un abrazo grande,
         <br />
         Pablo Almazán
       </p>
     </div>
   </div>
 </header>
 <section className="container my-2 my-md-5">
   <div className="row g-4">
     <div className="col-md-6">
       <div className="border-top pt-2 border-dark mb-4">
         <h5 className="text-primary">
           <strong>Nuestra Info</strong>
         </h5>
         <p>
           <strong>¿Tiene alguna consulta?</strong>
           <br />
           Póngase en contacto con nosotros.
           <br />
           <a href="mailto:organizacionhp@gmail.com">
             organizacionhp@gmail.com
           </a>
         </p>
         <p>¡Muchas gracias!</p>
       </div>
     </div>
     <div className="col-md-6">
       <div className="row">
         <div className="col-12">
           <div className="border-top pt-2 border-dark mb-4">
             <h5 className="text-primary">
               <strong>Envíenos un correo</strong>
             </h5>
           </div>
         </div>
       </div>
       
       <ContactoForm />


     </div>
   </div>
 </section>
</>
 
  );
};

export default Contacto;
