import { createAsyncThunk } from '@reduxjs/toolkit';
import { ValidationErrors } from '@/interfaces/common';
import { axiosData } from '@/axios';

import type { AxiosError, AxiosResponse } from 'axios';
 

const modulePrefix = '/locaciones';
 
export interface LocacionesData {
  IdPais: number,
  IdProvincia: number,
  IdLocalidad: number,
  Nombre:  string;

}
 
export interface LocacionesResponse {
  data: LocacionesData[];
}

export interface LocacionProps {
  id?: string;
  only_with_consultor:boolean
}

export const getPaises  = createAsyncThunk(
  `${modulePrefix}/getPaises`,
  async ({ id, only_with_consultor=true }: LocacionProps, { rejectWithValue }) => {
    try {
      const url =   only_with_consultor?"paises?only_with_consultor":"paises";
      const data: AxiosResponse = await axiosData.get(url, {
        params: { limit: 1000, order:"Nombre" },
      });
      const result: LocacionesResponse = data.data;
      return result;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);


export const getProvincias  = createAsyncThunk(
  `${modulePrefix}/getProvincias`,
  async ({ id, only_with_consultor=true }: LocacionProps, { rejectWithValue }) => {
    try {
      const url =   only_with_consultor?"provincias?only_with_consultor":"provincias";

      const data: AxiosResponse = await axiosData.get(url, {
        params: { IdPais: id, limit: 2000, order:"Nombre"  },
      });
      
      const result: LocacionesResponse = data.data;
      return result;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);


export const getlocalidades  = createAsyncThunk(
  `${modulePrefix}/getLocalidades`,
  async ({ id, only_with_consultor=true }: LocacionProps, { rejectWithValue }) => {
    try {
      const url =  only_with_consultor?"localidades?only_with_consultor":"localidades";
      const data: AxiosResponse = await axiosData.get(url, {
        params: { IdProvincia: id, limit: 3000 , order:"Nombre" },
      });
      
      const result: LocacionesResponse = data.data;
      return result;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);


 


 