import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Alert, Button, Container, Form } from 'react-bootstrap';
import { Navigate, useNavigate } from 'react-router-dom';
import { contactUser, encuestaEmpresa } from '../../store/user/userActions';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import { reset, resetUserCreate } from '@/store/user/userSlice';
import * as Yup from 'yup';
import TabNavigation from '../molecules/TabNavigation';

export interface NewUserValues {
  nombre: string;
 
 
  radioOption1: string;
  radioOption2: string;
  radioOption3: string;
  radioOption4: string;

  p1: string;
  p2: string;
  p3: string;
  p4: string;
  p5: string;
  p6: string;
  p7: string;

  radioOption5: string;
}

const EncuestaForm = () => {
  const initialValues: NewUserValues = { 
    nombre:'',
    radioOption1:'Si',
    radioOption2:'Si',
    radioOption3:'Si',
    radioOption4:'Si',
    p1:'',
    p2:'',
    p3:'',
    p4:'',
    radioOption5:'Si',
    p5:'',
    p6:'',
    p7:'',
        
    
   
     
   };
  const dispatch = useAppDispatch();
  const { newContactError, newContactLoading, newContactSuccess } = useAppSelector(state => state.user);
  const [addError, setAddError] = useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const validationSchema = Yup.object().shape({
   
    nombre: Yup.string()
      .required('Este campo es obligatorio')
      
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
      p1: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
      p2: Yup.string()
      .required('Este campo es obligatorio')
    
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
      p3: Yup.string()
      .required('Este campo es obligatorio')
     
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
      p4: Yup.string()
      .required('Este campo es obligatorio')
   
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
      p5: Yup.string()
      .required('Este campo es obligatorio')
     
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
      p6: Yup.string()
      .required('Este campo es obligatorio')
     
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
      p7: Yup.string()
      .required('Este campo es obligatorio')
     
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    
  });

  const setTokenFunc = (getToken:any) => {
   // setToken(getToken);
  };

  const handleVerify = (token: string) => {
    //setRecaptchaToken(token);
  };

  useEffect(() => {
    if (newContactError) {
      setAddError(true);
    }
    if (newContactSuccess) {
      //formik.resetForm();
     // formik.setValues(initialValues);
      setAddSuccess(true);

    
     }
  }, [newContactError, newContactSuccess]);

  const formik = useFormik({
    initialValues,
    onSubmit: async values => {
      setAddError(false);
      setAddSuccess(false);
      await dispatch(encuestaEmpresa(values));
    },
    validationSchema: validationSchema,
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: false,
  });
 

  return (
    <>
      <Container>
        
     
          <div className="row g-4">
            
          

                   <Form.Group className="col-md-12">
                   <p>
              Nombre y apellido de su Consultor/a Certificado/a:
            </p>
                            <Form.Control
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.nombre ? true : false}
                              required
                              onChange={formik.handleChange}
                              placeholder=""
                              maxLength={80}
                              value={formik.values.nombre}
                              name="nombre"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.nombre}</Form.Control.Feedback>
                            <br/>
                      </Form.Group>
                      <br/><br/>
                      <Form.Group className="col-md-12">
                        <p>
                        Al comenzar la consulta, ha completado un formulario de inicio junto a su Consultor/a? 

                        </p>
                      <Form.Check
                          type="radio"
                          id="radioOption1_1"
                          label="Si"
                          name="radioOption1"
                          value="Si"
                          checked={formik.values.radioOption1 == "Si"}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          isInvalid={formik.errors.radioOption1 ? true : false}
                          feedback={formik.errors.radioOption1}
                          required
                      />
                      <Form.Check
                          type="radio"
                          id="radioOption1_2"
                          label="No"
                          name="radioOption1"
                          value="No"
                          checked={formik.values.radioOption1 == "No"}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          isInvalid={formik.errors.radioOption1 ? true : false}
                          feedback={formik.errors.radioOption1}
                          required
                      />
                      <br/>
                      </Form.Group>
                      <br/><br/>
                      <Form.Group className="col-md-12">
                        <p>
                        Ha sido informado que las personas con antecedentes o que estén cursando depresión, trastorno generalizado de ansiedad, bipolaridad, psicosis, esquizofrenia, tratamientos con antidepresivos, intentos de suicidio, ACV, Infarto de miocardio, anginas de pecho, colocación de Stents o Bypass coronario no pueden atenderse de manera directa en este abordaje? 

                        </p>
                      <Form.Check
                          type="radio"
                          id="radioOption2_1"
                          label="Si"
                          name="radioOption2"
                          value="Si"
                          checked={formik.values.radioOption2 == "Si"}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          isInvalid={formik.errors.radioOption2 ? true : false}
                          feedback={formik.errors.radioOption2}
                          required
                      />
                      <Form.Check
                          type="radio"
                          id="radioOption2_2"
                          label="No"
                          name="radioOption2"
                          value="No"
                          checked={formik.values.radioOption2 == "No"}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          isInvalid={formik.errors.radioOption2 ? true : false}
                          feedback={formik.errors.radioOption2}
                          required
                      />
                      <br/>
                      </Form.Group>
                      <br/><br/>
                      <Form.Group className="col-md-12">
                        <p>
                        ¿Su consultor/a le ha mostrado un esquema que representa las etapas de la consulta y le ha comentado sobre las generalidades de cada una y le ha manifestado que un ciclo de consultas se considera completo solamente si se han realizado todas las etapas de ese ciclo?
                        </p>
                      <Form.Check
                          type="radio"
                          id="radioOption3_1"
                          label="Si"
                          name="radioOption3"
                          value="Si"
                          checked={formik.values.radioOption3 == "Si"}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          isInvalid={formik.errors.radioOption3 ? true : false}
                          feedback={formik.errors.radioOption3}
                          required
                      />
                      <Form.Check
                          type="radio"
                          id="radioOption3_2"
                          label="No"
                          name="radioOption3"
                          value="No"
                          checked={formik.values.radioOption3 == "No"}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          isInvalid={formik.errors.radioOption3 ? true : false}
                          feedback={formik.errors.radioOption3}
                          required
                      />
                      <br/>
                      </Form.Group>
                      <br/> 
                      <Form.Group className="col-md-12">
                        <p>
                        ¿Se le ha informado y ha estado de acuerdo en que un ciclo de consultas requiere de al menos 20/25 encuentros de periodicidad mensual? (uno por mes durante, al menos, 20/25 meses).

                        </p>
                      <Form.Check
                          type="radio"
                          id="radioOption4_1"
                          label="Si"
                          name="radioOption4"
                          value="Si"
                          checked={formik.values.radioOption4 == "Si"}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          isInvalid={formik.errors.radioOption4 ? true : false}
                          feedback={formik.errors.radioOption4}
                          required
                      />
                      <Form.Check
                          type="radio"
                          id="radioOption4_2"
                          label="No"
                          name="radioOption4"
                          value="No"
                          checked={formik.values.radioOption4 == "No"}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          isInvalid={formik.errors.radioOption4 ? true : false}
                          feedback={formik.errors.radioOption4}
                          required
                      />
                       <br/> 
                      </Form.Group>
                      

                     <Form.Group className="col-md-12">
                     <p> ¿Cuál ha sido el valor abonado en cada consulta en pesos o en dólares? </p>
                            <Form.Control
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.p1 ? true : false}
                              required
                              placeholder=""
                              maxLength={10}
                              value={formik.values.p1}
                              onChange={formik.handleChange}
                              name="p1"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.p1}</Form.Control.Feedback>
                            <br/> 
                      </Form.Group>

                      <Form.Group className="col-md-12">
                     <p> ¿Cuántas consultas ha realizado con este/a Consultor/a? </p>
                            <Form.Control
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.p2 ? true : false}
                              required
                              placeholder=""
                              maxLength={10}
                              value={formik.values.p2}
                              onChange={formik.handleChange}
                              name="p2"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.p2}</Form.Control.Feedback>
                            <br/> 
                      </Form.Group>

                      <Form.Group className="col-md-12">
                      <p> ¿Recuerda qué etapas han atravesado? Líneas de tiempo de su propia vida, 4 primeros años desde su nacimiento, Reprogramación de útero, análisis de proyecto y sentido, reprogramación de embarazo de su madre en su abuela materna, reprogramación de otros embarazos que ha definido el Consultor/a, análisis transgeneracional, proceso inmersivo en el inconsciente familiar a través de REORIxINS. </p>
                            <Form.Control
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.p3 ? true : false}
                              required
                              as="textarea"
                              rows={6}
                              maxLength={500}
                              value={formik.values.p3}
                              placeholder=""
                              onChange={formik.handleChange}
                              name="p3"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.p3}</Form.Control.Feedback>
                            <br/> 
                      </Form.Group>

                      <Form.Group className="col-md-12">
                      <p> ¿Durante el proceso de consultoría, ha cambiado el nombre de su síntoma o han cambiado el rumbo de lo que se iba abordando antes de finalizar un ciclo completo?  </p>
                            <Form.Control
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.p4 ? true : false}
                              required
                              as="textarea"
                              rows={6}
                              maxLength={200}
                              value={formik.values.p4}
                              placeholder=""
                              onChange={formik.handleChange}
                              name="p4"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.p4}</Form.Control.Feedback>
                            <br/> 
                      </Form.Group>


                      <Form.Group className="col-md-12">
                        <p>
                        ¿Ha sido invitado a ver el video de Youtube de Pablo Almazán llamado “El proceso de consulta con un Consultor Certificado” donde explica cómo es dicho abordaje?

                        </p>
                      <Form.Check
                          type="radio"
                          id="radioOption5_1"
                          label="Si"
                          name="radioOption5"
                          value="Si"
                          checked={formik.values.radioOption5 == "Si"}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          isInvalid={formik.errors.radioOption5 ? true : false}
                          feedback={formik.errors.radioOption5}
                          required
                      />
                      <Form.Check
                          type="radio"
                          id="radioOption5_2"
                          label="No"
                          name="radioOption5"
                          value="No"
                          checked={formik.values.radioOption5 == "No"}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          isInvalid={formik.errors.radioOption5 ? true : false}
                          feedback={formik.errors.radioOption5}
                          required
                      />
                      <br/> 
                      </Form.Group>

                      <Form.Group className="col-md-12">
                      <p> ¿Ha notado diferencias entre lo que se sugiere en ese video y lo que se ha aplicado en usted? ¿Podría mencionarlas si las hubo? Si no lo ha visto, puede verlo en Youtube y completar luego esta encuesta  </p>
                            <Form.Control
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.p5 ? true : false}
                              required
                              as="textarea"
                              rows={6}
                              maxLength={2005000}
                              value={formik.values.p5}
                              placeholder=""
                              onChange={formik.handleChange}
                              name="p5"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.p5}</Form.Control.Feedback>
                            <br/> 
                      </Form.Group>

                      <Form.Group className="col-md-12">
                      <p> ¿Desea dejar su nombre y dirección de mail? Puede hacerlo aquí. </p>
                            <Form.Control
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.p6 ? true : false}
                              required
                              as="textarea"
                              rows={6}
                              maxLength={200}
                              value={formik.values.p6}
                              placeholder=""
                              onChange={formik.handleChange}
                              name="p6"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.p6}</Form.Control.Feedback>
                            <br/> 
                      </Form.Group>

                      <Form.Group className="col-md-12">
                      <p> Por favor, coméntenos aquí lo que desee.  </p>
                            <Form.Control
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.p7 ? true : false}
                              required
                              as="textarea"
                              rows={6}
                              maxLength={600}
                              value={formik.values.p7}
                              placeholder=""
                              onChange={formik.handleChange}
                              name="p7"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.p7}</Form.Control.Feedback>
                            <br/> 

                          
                     
                    
                      </Form.Group>

                      
                       
                 
               
                  <div className="col d-grid mb-5">
                        <Button className="btn btn-primary"  disabled={addSuccess}  onClick={() => formik.handleSubmit()}>
                        Enviar
                        </Button>

                       
                  </div>
        
        </div>
        {addSuccess && (
          <Alert variant="success"  >
            <Alert.Heading>¡Muchas Gracias!</Alert.Heading>
            <p>Se envio su consulta correctamente</p>
          </Alert>
        )}
        {addError && (
          <Alert variant="danger" dismissible onClose={() => setAddError(false)}>
            <Alert.Heading>¡Ups, algo salió mal!</Alert.Heading>
            <p>Intenta nuevamente</p>
          </Alert>
        )}

      </Container>
      {newContactLoading && (
        <div>Aguarde un instante...</div>
      )}
    </>
  );
};

export default EncuestaForm;
