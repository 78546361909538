import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Container, Form, Modal } from 'react-bootstrap';
import {
  getEventoUsuarioById, EventoData, getEventoUnidadById, getEventoUnidadPreguntaById, setPaso
} from '@/store/eventos/eventosActions';
import { slugify } from '@/components/utils';

import { useParams } from 'react-router-dom';

const Unidades: React.FC = () => {

  const decodeEntities = (text: string) => {
    const element = document.createElement("div");
    element.innerHTML = (text.length > 120) ? text.substring(0, 200) + '...' : text;
    return element.innerText;
  }
  const [modalChangeState, setModalChangeState] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { codigoEvento } = useParams();
  const params = new URLSearchParams(window.location.search);
  const idEventoUsuario = params.get('p');

  const dataEventoUsuario = useAppSelector((state) => state.eventos?.itemEventoUsuarioIdData);
  const dataEventoUnidad = useAppSelector((state) => state.eventos?.itemIdData);
  const dataEventoUnidadPreguntas = useAppSelector((state) => state.eventos?.itemEventoUnidadIdData);
 
  

  const   handleAvanzar = async ()   => {
    // Validar si las respuestas coinciden con las opciones correctas
    if (dataEventoUnidadPreguntas && dataEventoUsuario && dataEventoUnidad)
      {
    const preguntasValidas = dataEventoUnidadPreguntas?.preguntas.every(dataPregunta => {
      const respuestaSeleccionada = document.querySelector(`input[name=flexRadioDefault${dataPregunta.IdPreguntaUnidad}]:checked`) as HTMLInputElement;
      return respuestaSeleccionada && respuestaSeleccionada.value === 'correcta';
    });

    if (preguntasValidas) {
      // Lógica para avanzar al siguiente video
 
      await dispatch(setPaso({ id: dataEventoUsuario?.IdEvento, paso: dataEventoUsuario.Paso+1, finalizado:0 }));
      navigate("/unidades/" + dataEventoUnidad.IdEvento);

    } else {
      setModalChangeState(true);
    }
  } 
  };

   
   
   useEffect(() => {
     if (codigoEvento)
       {dispatch(getEventoUsuarioById({ id: parseInt(codigoEvento) }));}
     
   }, [dispatch]);
 
 
   useEffect(() => {
     if (codigoEvento)
     {dispatch(getEventoUnidadById({ id: parseInt(codigoEvento)  }));}
   }, [dispatch]);

   useEffect(() => {
    if (idEventoUsuario)
    {dispatch(getEventoUnidadPreguntaById({ id: parseInt(idEventoUsuario)  }));}
  }, [dispatch]);

  return (
<>
  {/* Breadcrumb */}
  <section className="container d-none d-lg-block mt-2">
    <div className="row">
      <div className="col-12">
        <div className="border-top py-2">
          <nav style={{}} aria-label="breadcrumb" className="d-none d-md-block">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Inicio</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/misactividades">Mis Actividades</a>
              </li>
              <li className="breadcrumb-item">
              {dataEventoUnidad  && (<>Paso {dataEventoUnidad.Titulo}</>)}
              </li>
              <li className="breadcrumb-item active" aria-current="page">
              {dataEventoUsuario  && (<>Paso {dataEventoUsuario.Paso+1}</>)}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </section>
  {/* Content */}
  {dataEventoUsuario  && dataEventoUnidad && dataEventoUnidadPreguntas && (
    
  <section className="container mt-2 mb-2  mt-md-2 mb-md-5">
     
    <div className="row">
      <div className="col-12">
        <h1 className="display-5">{dataEventoUnidad.Titulo}</h1>
        <h2 className="text-secondary mb-4">Paso {dataEventoUsuario.Paso+1}</h2>
      </div>
    </div>
    <div className="row d-flex align-items-stretch">
      <div className="col-12">
        <div className="card mb-5">
          <div className="card-body py-4">
            <div className="row justify-content-center mt-5">
            {dataEventoUnidadPreguntas.preguntas.map(dataP => (
               <React.Fragment key={dataP.IdPreguntaUnidad}>
              <div   className="col-md-8">
                <h3 className="text-center">
                 {dataP.Pregunta}
                </h3>
              </div>
              <div   className="col-md-8 my-5">
              {dataP.opciones.map(dataO => (

                  <div key={dataO.IdOpcion}  className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                     
                      name={`flexRadioDefault${dataO.IdPreguntaUnidad}`}
                      id={`flexRadioDefault${dataO.IdPreguntaUnidad}${dataO.IdOpcion}`}
                      checked={dataO.seleccionado}
                      value={dataO.Correcto?"correcta":""}
            
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                     {dataO.Nombre} 
                    </label>
                  </div>
                
                   ))}
              </div>   
              </React.Fragment>
                 ))}
              <div className="col-md-8 my5">
                <div className="row mb-5">
                  <div className="col-6 d-grid">
                    <a href={"/unidades/" + dataEventoUnidad.IdEvento}  className="btn btn-outline-primary icon-video">
                      Volver a ver el video
                    </a>
                  </div>
                  <div className="col-6 d-grid">
                    <a href="#" className="btn btn-primary icon-video"  onClick={handleAvanzar}>
                      avanzar al siguiente video
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal show={modalChangeState} onHide={() => setModalChangeState(false)}>
        <Modal.Header closeButton className="modal-header" >
          <Modal.Title> </Modal.Title>
        </Modal.Header>
  
        <Modal.Body className="modal-body">
      
            <Form.Label>Las respuestas ingresadas son incorrectas</Form.Label>
   
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <a href={"/unidades/" + dataEventoUnidad.IdEvento}   > 
          <Button   variant="btn btn-outline-primary icon-video">
          Te recomendamos volver a ver el video
          </Button>
          </a>
        </Modal.Footer>
      </Modal>
  </section>)}
</>
 
   

  );
};

export default Unidades;
