import React, { useCallback, useEffect } from 'react';
import { loginUser } from '@/store/user/userActions';
import { LoginUserProps } from '@/interfaces/user';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { getBanners } from '@/store/banners/bannersActions';
import { getLibros, LibroData } from '@/store/libros/librosActions';
import { useNavigate } from 'react-router-dom';
import { EventoData, getEventos3 } from '@/store/eventos/eventosActions';
import { slugify } from '@/components/utils';

const Redirect: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  

  useEffect(() => {
    const path = `/`;
    navigate(path);
  }, []);

  return (
    <></>
    );

}
export default Redirect;
