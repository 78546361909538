import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation, RouteProps } from 'react-router-dom';
import { useAppSelector } from '@/hooks/redux';
import Home from '@/pages/Home';
import Somos from '@/pages/Somos';
import Layout from '@/components/templates/Layout';
import LayoutWithOutNav from './components/templates/LayoutWithOutNav';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'react-datetime/css/react-datetime.css';
import { ThemeProvider } from 'react-bootstrap';
import CreateUser from './components/organisms/CreateUser';
import Contacto from '@/pages/Contacto';
import LayoutLight from './components/templates/LayoutLight';
import Video from '@/pages/Video';
import Actividades from '@/pages/Actividades';
import Consultores from '@/pages/Consultores';
import Consultor from './pages/Consultor';
import Empresas from '@/pages/Empresas';
import Login from '@/pages/Login';
import RecuperarPassword from '@/pages/RecuperarPassword';
import Registro from '@/pages/Registro';
import Camino from '@/pages/Camino';
import MisActividades from '@/pages/MisActividades';
import LibroCompra from '@/pages/LibroCompra';
import Libro from '@/pages/Libro';
import Libros from '@/pages/Libros';
import Seminarios from '@/pages/Seminarios';
import CaminoHP from '@/pages/CaminoHP';
import Eventos from '@/pages/Eventos';
import Redirect from '@/pages/Redirect';
import EventoDetalle from '@/pages/EventoDetalle';
import EventoInscripcion1 from '@/pages/EventoInscripcion1';
import EventoInscripcion3 from '@/pages/EventoInscripcion3';
import EventoInscripcion2 from '@/pages/EventoInscripcion2';
import UnidadesFinalizada from '@/pages/UnidadesFinalizada';

import Unidades from '@/pages/Unidades';
import Preguntas from '@/pages/Preguntas';
import LibroCompraFormaDePago from '@/pages/LibroCompraFormaDePago';
import CompraCallback from './pages/CompraCallback';
import LibroCompraGracias from './pages/LibroCompraGracias';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import env from '@beam-australia/react-env';
import Certificado from './pages/Certificado';
import FormaParte from './pages/formaparte';
import TagManager from 'react-gtm-module';
import CompraCallbackStripe from './pages/CompraCallbackStripe';
import EmpresaEncuesta from './pages/EmpresaEncuesta';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Recovery from './pages/Recovery';
import Recovery2 from './pages/Recovery2';
import Campus from './pages/Campus';
import ConsultoresMenu from './pages/ConsultoresMenu';
import ConsultoresCampus from './pages/ConsultoresCampus';
import CompraError from './pages/CompraError';

interface PrivateRouteProps extends RouteProps {
  children: JSX.Element;
  type: string;
  allowIsAuthenticated: boolean;
  requiredIsAuthenticated: boolean;
}

const App: React.FC = () => {
  useEffect(() => {
    // Configura GTM con tu ID de contenedor
    TagManager.initialize({ gtmId: 'GTM-MKN2HNZS' });
  }, [location.pathname]);

  return (
    <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']} minBreakpoint="xxs">
      <BrowserRouter>
        <PayPalScriptProvider
          options={{
            'client-id': `${String(env('PP_CLIENT_ID'))}`,
          }}>
          <Routes>
            <Route
              path="/"
              element={
                <MyRoute type="Layout" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <Home />
                </MyRoute>
              }
            />

            <Route
              path="/espanol"
              element={
                <MyRoute type="Layout" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <Redirect />
                </MyRoute>
              }
            />

            <Route
              path="actividades"
              element={
                <MyRoute type="LayoutWithOutNav" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <Actividades />
                </MyRoute>
              }
            />

            <Route
              path="campus"
              element={
                <MyRoute type="LayoutWithOutNav" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <Campus />
                </MyRoute>
              }
            />

            <Route
              path="login"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={false} requiredIsAuthenticated={false}>
                  <Login />
                </MyRoute>
              }
            />

            <Route
              path="recuperar-acceso"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={false} requiredIsAuthenticated={false}>
                  <Recovery />
                </MyRoute>
              }
            />

            <Route
              path="recuperar-acceso-codigo"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={false} requiredIsAuthenticated={false}>
                  <Recovery2 />
                </MyRoute>
              }
            />

            <Route
              path="recuperar-acceso1"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <RecuperarPassword />
                </MyRoute>
              }
            />

            <Route
              path="registro"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={false} requiredIsAuthenticated={false}>
                  <Registro />
                </MyRoute>
              }
            />

            <Route
              path="empresas"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <Empresas />
                </MyRoute>
              }
            />

            <Route
              path="consultores-campus"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <ConsultoresCampus />
                </MyRoute>
              }
            />

            <Route
              path="caminohp"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <CaminoHP />
                </MyRoute>
              }
            />

            <Route
              path="consultores"
              element={
                <MyRoute type="LayoutWithOutNav" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <ConsultoresMenu />
                </MyRoute>
              }
            />

            <Route
              path="consultores-general"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <Consultores />
                </MyRoute>
              }
            />

            <Route
              path="consultor/:consultorId"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <Consultor />
                </MyRoute>
              }
            />

            <Route
              path="eventos/:codigoEvento"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <Eventos />
                </MyRoute>
              }
            />

            <Route
              path="unidades/:codigoEvento"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <Unidades />
                </MyRoute>
              }
            />

            <Route
              path="preguntas/:codigoEvento"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <Preguntas />
                </MyRoute>
              }
            />

            <Route
              path="evento-detalle/:eventoId"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <EventoDetalle />
                </MyRoute>
              }
            />

            <Route
              path="evento-inscripcion-01/:eventoId"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <EventoInscripcion1 />
                </MyRoute>
              }
            />

            <Route
              path="evento-inscripcion-02/:eventoId"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <EventoInscripcion2 />
                </MyRoute>
              }
            />

            <Route
              path="evento-inscripcion-03/:eventoId"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <EventoInscripcion3 />
                </MyRoute>
              }
            />

            <Route
              path="compra/feedback"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <CompraCallback />
                </MyRoute>
              }
            />

            <Route
              path="stripe/success"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <CompraCallbackStripe />
                </MyRoute>
              }
            />

            <Route
              path="somos"
              element={
                <MyRoute type="LayoutWithOutNav" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <Somos />
                </MyRoute>
              }></Route>

            <Route
              path="camino"
              element={
                <MyRoute type="LayoutWithOutNav" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <Camino />
                </MyRoute>
              }></Route>

            <Route
              path="contacto"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <Contacto />
                </MyRoute>
              }></Route>

            <Route
              path="empresa-encuesta"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <GoogleReCaptchaProvider reCaptchaKey={'6Lc2mYEpAAAAAHrrG5BF26ntSZzHhM0bSFe6o5_i'}>
                    <EmpresaEncuesta />
                  </GoogleReCaptchaProvider>
                </MyRoute>
              }></Route>

            <Route
              path="video"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <Video />
                </MyRoute>
              }></Route>

            <Route
              path="libro/:libroId"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <Libro />
                </MyRoute>
              }></Route>

            <Route
              path="libros"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <Libros />
                </MyRoute>
              }></Route>

            <Route
              path="seminarios"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <Seminarios />
                </MyRoute>
              }></Route>

            <Route
              path="libro-compra/:libroId"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <LibroCompra />
                </MyRoute>
              }></Route>

            <Route
              path="libro-compra-forma-de-pago/:libroId"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <LibroCompraFormaDePago />
                </MyRoute>
              }
            />

            <Route
              path="libro-compra-gracias/:libroId"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <LibroCompraGracias />
                </MyRoute>
              }
            />

            <Route
              path="compra-error"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <CompraError />
                </MyRoute>
              }
            />

            <Route
              path="misactividades"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={true}>
                  <MisActividades />
                </MyRoute>
              }>
              <Route path="crear" element={<CreateUser />} />
            </Route>

            <Route
              path="formaparte"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <FormaParte />
                </MyRoute>
              }></Route>

            <Route
              path="certificado"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={false}>
                  <Certificado />
                </MyRoute>
              }></Route>

            <Route
              path="unidades-finalizada/:id"
              element={
                <MyRoute type="LayoutLight" allowIsAuthenticated={true} requiredIsAuthenticated={true}>
                  <UnidadesFinalizada />
                </MyRoute>
              }></Route>
          </Routes>
        </PayPalScriptProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

const MyRoute: React.FC<PrivateRouteProps> = ({ children, type, allowIsAuthenticated, requiredIsAuthenticated }) => {
  const { userInfo } = useAppSelector(state => state.user);
  const location = useLocation();

  if (userInfo && !allowIsAuthenticated) {
    const params = new URLSearchParams(window.location.search);
    const back = params.get('back');

    if (back) {
      return <Navigate to={back} state={{ from: location }} replace />;
    }
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (!userInfo && requiredIsAuthenticated) {
    return <Navigate to="/registro" state={{ from: location }} replace />;
  }

  switch (type) {
    case 'LayoutLight':
      return <LayoutLight>{children}</LayoutLight>;
    case 'LayoutWithOutNav':
      return <LayoutWithOutNav>{children}</LayoutWithOutNav>;
    default:
      return <Layout>{children}</Layout>;
  }
};

export default App;
