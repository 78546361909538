import { createAsyncThunk } from '@reduxjs/toolkit';
import { ValidationErrors } from '@/interfaces/common';
import { axiosData } from '@/axios';

import type { AxiosError, AxiosResponse } from 'axios';


const modulePrefix = '/datosempresas';

export interface EmpresaData {
  IdDatoEmpresa: number,
  RazonSocial: string | undefined,
  CodigoAreaTelefono: string | undefined,
  Telefono: string | undefined,
  CodigoAreaTelefono2: string | undefined,
  Telefono2: string | undefined,
  CodigoAreaFax: string | undefined,
  Fax: string | undefined,
  Email: string | undefined,
  EmailContacto: string | undefined,
  EmailRegistro: string | undefined,
  EmailCheckout: string | undefined,
  DireccionCalle: string | undefined,
  DireccionNumero: string | undefined,
  DireccionPiso: string | undefined,
  DireccionDepartamento: string | undefined,
  IdPais: number | undefined,
  IdProvincia: number | undefined,
  Localidad: string | undefined,
  CodigoPostal: string | undefined,
  PaginaWeb: string | undefined,
  Logo: string | undefined,
  SeoRubroEmpresa: string | undefined,
  SeoNombreEmpresa: string | undefined,
  CotizacionDolar: number,
}

export interface EmpresaResponse {
  data: EmpresaData[];
}

export interface EmpresaProps {
  id: number;
}



export const getDatosEmpresasById = createAsyncThunk(
  `${modulePrefix}/getDatosEmpresasById`,
  async ({ id }: EmpresaProps, { rejectWithValue }) => {
    try {
      const data: AxiosResponse = await axiosData.get(`datosempresas/${id.toString()}`, {
      });
      const result: EmpresaData = data.data.item;
      return result;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);


