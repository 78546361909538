import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getLibrosById, getLibros, LibroData } from '@/store/libros/librosActions';
import { slugify } from '@/components/utils';

const Libro: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { libroId } = useParams();

  const dataLibro = useAppSelector((state) => state.libros?.itemIdData);
  const libros = useAppSelector((state) => state.libros?.itemsData?.data);

  useEffect(() => {
    if (libroId) {
      dispatch(getLibrosById({ id: parseInt(libroId.split("-")[0]) }));
    }
  }, [dispatch, libroId]);

  useEffect(() => {
    dispatch(getLibros({}));
  }, [dispatch]);


  const viewLibro = (item: LibroData) => {
    const path = `/libro/${item.id}-${slugify(item.nombre)}`;
    window.scrollTo(0, 0);
    navigate(path);

  }


  function obtenerCuatroLibrosAlAzar(librosArray: LibroData[] | undefined) {
    if (librosArray == undefined) return;
    const librosCopia = [...librosArray];
    const arrayAleatorizado = librosCopia.sort(() => Math.random() - 0.5);
    const cuatroAlAzar = arrayAleatorizado.slice(0, 4);

    return (
      <>
        {cuatroAlAzar.map((item) => (
          <article className="col-md-6 col-lg-3" key={`otros_lb-${item.id}`}>
            <a href="#" onClick={() => viewLibro(item)}>
              <img
                src={item.imagen}
                alt={item.nombre}
                className="img-fluid"
              />
              <h3>{item.nombre}</h3>
              <h6 className="text-secondary">{item.autor}</h6>
              <button
                type="button"
                className="btn btn-link solo-argentina btn-lg"
              />
              <button type="button" className="btn btn-link solo-world btn-lg" />
              <button type="button" className="btn btn-link solo-tablet btn-lg" />
            </a>
          </article>
        ))}
      </>
    );
  }


  return (
    <>
      {/* Breadcrumb */}
      <section className="container d-none d-lg-block mt-2">
        <div className="row">
          <div className="col-12">
            <div className="border-top py-2">
              <nav style={{}} aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Inicio</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/libros">Libros de Pablo Almazán</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {(dataLibro) ? dataLibro.nombre : ''}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* Libro */}
      <section className="container">
        {dataLibro && (
          <div className="row text-start">
            <div className="col-md-6">
              <img src={dataLibro.imagen} className="img-fluid" alt="" />
            </div>
            <div className="col-md-6 d-grid gap-2">
              <h1 className="display-3">{dataLibro.nombre}</h1>
              <h6 className="text-secondary">{dataLibro.autor}</h6>
              <a
                className="btn btn-outline-primary icon-argentina"
                href={`/libro-compra/${dataLibro.id}-${slugify(dataLibro.nombre)}`}
                role="button"
              >
                Envíos a Argentina
              </a>
              <a
                className="btn btn-outline-primary icon-world"
                href={dataLibro.link_envio_al_exterior}
                role="button"
                target="_blank"
              >
                Envíos al Exterior
              </a>
              <a
                className="btn btn-outline-primary icon-tablet"
                href={dataLibro.link_version_ebook}
                role="button"
                target="_blank"
              >
                Versión e-Book
              </a>
              <p className="border-top my-4 py-3"  dangerouslySetInnerHTML={{ __html: dataLibro.descripcion }}>
         
              </p>
              {dataLibro.video && (
                 <div>
                      <style
                        dangerouslySetInnerHTML={{
                          __html:
                            ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
                        }}
                      />
                      <div className="embed-container">
                        <iframe
                          src={dataLibro.video}
                          frameBorder={0}
                          
                        />
                      </div>
                    </div>
                    )}
              </div>
       
          </div>
        )}
      </section>
      {/* Otros Libros */}
      <section className="container my-5">
        <div className="col-12">
          <div className="border-top pt-2">
            <h5 className="text-primary">
              <strong>Otros libros de Pablo Almazán</strong>
            </h5>
          </div>
          <div className="row text-center">
            {obtenerCuatroLibrosAlAzar(libros)}

          </div>
        </div>
      </section>
    </>




  );
};

export default Libro;
