import React, { useCallback } from 'react';
import { loginUser } from '@/store/user/userActions';
import { LoginUserProps } from '@/interfaces/user';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import LoginForm from '@/components/organisms/LoginForm';
import MyNav2 from '@/components/templates/MyNav2';


const Camino: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loading, userInfo } = useAppSelector(state => state.user);

  const handleLogin = useCallback((values: LoginUserProps) => dispatch(loginUser(values)), [dispatch]);

  const navigateUrl = (path:any) => {
      window.scrollTo(0, 0);
      navigate(path);
  }

  return (
    <>
      <header className="camino">

        <MyNav2 />

        {/* Breadcrumb */}
        <section className="container d-none d-lg-block mt-2">
          <div className="row">
            <div className="col-12">
              <div className="border-top py-2">
                <nav style={{}} aria-label="breadcrumb">
                  <ol className="breadcrumb breadcrumb-light">
                    <li className="breadcrumb-item">
                      <a href="/">Inicio</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      ¿Cómo hacer el camino de Humano Puente?
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        {/* Titulo */}
        <section className="container text-white">
          <div className="row mt-3 mt-md-5 justify-content-center">
            <div className="col-lg-6 my-5 text-center">
              <h6>El camino</h6>
              <h1 className="display-2 my-5">
                ¿Cómo hacer el camino de Humano Puente?
              </h1>
            </div>
          </div>
        </section>
      </header>

      <>
        <section className="container py-3 py-md-5">
          <div className="row justify-content-center">
            <div className="col-lg-6 text-center text-primary my-3 my-md-5">
              <p className="lead">
                El Camino de Humano Puente es un camino de crecimiento personal. Es
                una manera de vivir la vida. No es un lugar donde intentaremos darTe
                forma, sino que te acompañaremos en el profundo y maravilloso proceso
                de encontrarTe con el milagro de que Tu forma, siempre fue perfecta.
                Tus síntomas y dolores vienen a sanarte a ti y a medida que hagas este
                camino tu Universo irá sanando. CADA CONSULTANTE QUE ATIENDES, TU
                SANAS Y DESPIERTAS. Si tu sanas, tu Universo sana. El camino de Humano
                Puente es un CAMINO PARA SANAR Y SER SANADOR.
              </p>
            </div>
            <div className="col-lg-8 mb-3 mb-md-5">
              <p>
                El Camino de Humano Puente es un camino de Crecimiento personal. Es
                una manera de vivir la vida. No es un lugar de formación ya que no nos
                resuena ese término. Es un lugar de Apertura. Amamos tu forma especial
                y única, tal cual es, y queremos que no la pierdas, no nos parece
                bueno darle "forma" a alguien. Tu ya la tienes y es, precisamente, tu
                potencial. Simplemente trataremos de guiarte para que amplíes tu
                visión en este camino Humano. La BIOEXISTENCIA CONSCIENTE (que es la
                suma de la Decodificación Bioemocional y su profundización
                transgeneracional llamada REORIxINS) la corriente de Pablo Almazán y
                Lucrecia Bianchi, tiene una personalidad especial, ya que es la única
                que comprende los siete planos, desde el Origen hasta la materia y la
                realidad toda. Una concepción competa, desde el Espíritu hasta la
                materia, pasando por cada plano desde una mirada integradora, sin un
                objetivo trazado, más que el disfrutar el camino hacia el despertar de
                la conciencia de la mano del conocimiento biológico, evolutivo y por
                sobre todo, originario; donde el saber de los antiguos pueblos de los
                Andes y el Pacífico nos guía como una estrella. Te recomendamos ver en
                esta web la serie de videos "SER UN HUMANO PUENTE".
              </p>
            </div>
            <div className="col-lg-10">
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
                }}
              />
              <div className="embed-container">
                <iframe
                  src="https://www.youtube.com/embed/6jA5REJp6Ug"
                  frameBorder={0}

                />
              </div>
            </div>
            <div className="col-lg-8 my-3 my-md-5">
              <p>
                Por otra parte{" "}
                <strong>
                  <em>
                    Pablo, a través de Humano Puente, tiene un propósito maravilloso
                    que ya se está cumpliendo contando ya con más de dos mil
                    Consultores en diferentes países. Ese maravilloso propósito es el
                    de no ser sólo un lugar de paso sino el de ser la primera red
                    global de Consultores en Bioexistencia Consciente o Humanos
                    Puente, una casa conectada, no sólo a traves del sentir sino en un
                    grupo de interconsulta a través de Internet que opera las 24 horas
                    los 365 días del año y desde TODO EL MUNDO! donde todos quienes
                    han dado sus pasos aquí pueden interactuar entre Consultores y
                    cuentan con todo nuestro acompañamiento.
                  </em>
                </strong>
              </p>
              <p>
                Cuando alguien hace un cambio profundo en su vida es igual que un
                trozo de roca que es sacado de la roca madre para ser tallado por sí
                mismo y convertirse en una obra de arte. Si esa nueva obra, pulida,
                brillante, perfecta, en camino de despertar, quiere regresar a la roca
                madre, muchas veces siente que ya no encaja allí como antes y en
                muchos casos eso lleva a un estado de sentirse "separado". Eso, sumado
                a la necesidad de consultar a sus colegas sobre los primeros pasos,
                dudas, experiencias de casos, síntomas y demás, ha provocado que desde
                el principio hayamos sentido y creado ese espacio donde toda la
                familia de Humano Puente se reune via internet. Es una inmensa y
                maravillosa familia global que crece día a día en número, en despertar
                y en sabiduría. Allí todos los días verás lo que llamamos "Milagros
                Lógicos" y eso acompaña tu certeza, ver que todos los dias tantas
                personas sanan y despiertan era un regalo inimaginable hace un tiempo.
              </p>
              <p>
                <strong>
                  <em>
                    En la solapa llamada Somos Humano Puente tienes amplia información
                    sobre este maravilloso y creciente grupo. La gran mayoría de los
                    Consultores que han dado y dan sus pasos aquí, continuan
                    conectados en este grupo de interconsulta permanente, acompañados
                    desde Humano Puente y generando la Enciclopedia de Consultas sobre
                    este abordaje más grande que se tenga conocimiento.
                  </em>
                </strong>
              </p>
            </div>
          </div>
        </section>
        <section className="bg-light"   style={{ maxWidth: 'calc(100% - 12px)'}}>
          <div className="row g-0 align-items-center">
            <div className="col-lg-6">
              <img src="./img/camino_consultor.jpg" className="img-fluid" alt="" />
            </div>
            <div className="col-lg-6">
              <div className="row justify-content-center">
                <div className="col-11 col-md-9 col-lg-7 py-3 py-md-0">
                  <h2 className="display-6 text-primary mb-3">
                    Ser Consultor en Bioexistencia Consciente
                  </h2>
                  <p>
                    <strong>
                      Ser Consultor en Bioexistencia Consciente es un enorme placer y
                      es muy simple.
                    </strong>{" "}
                    Se requieren solo tres pasos que son, en realidad, Aperturas a una
                    nueva visión. Esto es una forma de pensar, de ver, de sentir. Es
                    un camino muy simple, biológico, evolutivo, espiritual y
                    chamánico. Un camino de consciencia diferente donde tu único
                    maestro serás tú.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container">
          <div className="row justify-content-center my-5">
            <div className="col-lg-7 text-center text-primary">
              <h6>Los pasos</h6>
              <h2 className="display-5">
                Aquí podrás ver todos los pasos y al pie de cada página, encontrarás
                un botón que te llevará a la sección Actividades para poder
                inscribirte
              </h2>
            </div>
            <div className="col-lg-8 mt-5 border-bottom">
              <nav>
                <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                  <button
                    className="nav-link active"
                    id="nav-paso01-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-paso01"
                    type="button"
                    role="tab"
                    aria-controls="nav-paso01"
                    aria-selected="true"
                  >
                    Paso 1
                  </button>
                  <button
                    className="nav-link"
                    id="nav-paso02-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-paso02"
                    type="button"
                    role="tab"
                    aria-controls="nav-paso02"
                    aria-selected="false"
                  >
                    Paso 2
                  </button>
                  <button
                    className="nav-link"
                    id="nav-paso03-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-paso03"
                    type="button"
                    role="tab"
                    aria-controls="nav-paso03"
                    aria-selected="false"
                  >
                    Paso 3
                  </button>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade show active p-3"
                  id="nav-paso01"
                  role="tabpanel"
                  aria-labelledby="nav-paso01-tab"
                  tabIndex={0}
                >
                  <p>Hay 2 maneras de realizar este Primer Paso:</p>
                  <h3 className="text-primary">Realizar la Jornada Online</h3>
                  <p>
                    La puedes hacer en tu casa y con los tiempos que dispongas,
                    contiene mucha información que luego siempre tendrás a mano. Al
                    igual que el modo presencial es muy movilizadora. Te recomendamos
                    hacerla de a poco, es posible que experimentes cosas fuertes y
                    debas para, luego dejar pasar unos días, luego continuar, etc.
                    Cada uno tiene sus tiempos, algunas personas la han hecho en 15
                    días, otras en un mes, dos meses y otras en algo más de una
                    semana. Lo importante es que escuches a tu cuerpo y sepas esperar
                    si es necesario. Las Jornadas, tanto presencial como online son
                    todo un vórtice de cambio de vida. Por lo general, quienes las
                    realizan vuelven a verlas una y otra vez y siempre descubren, a
                    medida que cambian su Universo y Consciencia, nueva información
                    que antes parecía no estar. Muy fuertes y maravillosas. Si no
                    resides en Argentina esta es la manera que recomendamos para
                    iniciar el camino. Muchas personas del exterior que hoy son
                    Consultores comenzaron de este modo.
                  </p>
                  <div className="d-grid">
                     <button className="btn btn-primary icon-subscribe" type="button"  onClick={() => navigateUrl('/eventos/jornada-online')}>
                   Inscribirme  
                    </button>
                  
                  </div>
                  <h3 className="text-primary mt-4">
                    Realizar una Charla Introductoria
                  </h3>
                  <p className="text-primary">
                    La Charla Introductoria es un PASO PREVIO OPCIONAL con BENEFICIO
                    sobre el valor de la Jornada Online
                  </p>
                  <p>
                    Este modo es maravilloso porque cuenta con las dos cosas
                    (presencial y online) y un costo más conveniente aún. Lo
                    maravilloso de la Jornada presencial de tres días es el
                    intercambio grupal y el comprobar lo relacionados que estamos con
                    los demás (porque a esta altura aún se cree que el otro existe de
                    un determinado modo, recien se comienza el camino). La Charla
                    presencial de un día aporta esa parte que es muy nutritiva.
                    Quienes participan de la Charla de un día, reciben un descuento
                    del 50% en las Jornadas Online. De este modo, obtienen tanto la
                    parte presencial como el enorme y permanente caudal de información
                    de la parte online y a un precio menor que si solamente hicieran
                    la Jornada Online.
                  </p>
                  <p>
                    Las Charlas Introductorias al Camino de Humano Puente son
                    solamente brindadas por los COMUNICADORES DE HUMANO PUENTE que son
                    Consultores de amplia experiencia en Consulta, personas que han
                    cambiado su realidad sustancialmente, que aman este camino, aman
                    REORIxINS y la Bioexistencia Consciente, la cuidan, la valoran,
                    seres que comparten de modo permanente sus casos en el Grupo de
                    Consultores, que participan de los Congresos de Humano Puente,
                    cuentan con los Seminarios al día y, sobre todo, están en
                    permanente conexión y actualización con Pablo y Lucrecia. Los
                    podrás encontrar en el listado de Consultores como COMUNICADOR DE
                    HUMANO PUENTE. También encontrarás la Charla Introductoria más
                    cercana a tu domicilio (ya los hay en tres países, Argentina,
                    Chile y Ecuador) en esta misma página web, en la parte de
                    ACTIVIDADES.
                  </p>
                  <div className="d-grid">
                    <button className="btn btn-primary icon-subscribe" type="button" onClick={() => navigateUrl('/eventos/charlas-introductorias')}>
                      Inscribirme
                    </button>
                  </div>
                  <p className="mt-4 text-center">
                    Los sigulentes pasos para ser Consultor, una vez realizada la
                    Jorada Online son:
                  </p>
                  <h5 className="text-center text-primary">
                    Apertura en Decodificación Bioemocional (PASO 2)
                  </h5>
                  <h5 className="text-center text-primary">
                    Apertura en Bioexistencia Conciente (PASO 3)
                  </h5>
                </div>
                <div
                  className="tab-pane fade p-3"
                  id="nav-paso02"
                  role="tabpanel"
                  aria-labelledby="nav-paso02-tab"
                  tabIndex={0}
                >
                  <h3 className="text-primary">
                    Consultor/a en Decodificación Bioemocional
                  </h3>
                  <p>
                    Una vez que hayas hecho el <strong>paso uno</strong> en cualquiera
                    de las opciones lo que sigue es la{" "}
                    <strong>APERTURA EN DECODIFICACIÓN BIOEMOCIONAL</strong>, El Paso
                    2. Este paso se hace EN MODO ONLINE EN VIVO A TRAVES DE ZOOM Y
                    BRINDADO POR PABLO ALMAZÁN Y LUCRECIA BIANCHI para todo el Mundo.
                  </p>
                  <p>
                    La Apertura en Decodificación Bioemocional dura 5 días + 1 día
                    completo de dudas y consultas y una vez finalizada serás{" "}
                    <strong>Consultor/a en Decodificación Bioemocional</strong>,
                    pudiendo comenzar a atender las primeras partes de la Consultoría
                    y a formar parte ya del grupo de Interconsulta, apoyo y ayuda a
                    través de Internet. Tendrás miles de colegas consultores
                    acompañándote entre los que hay médicos, psicólogos, psiquiatras,
                    ingenieros, analistas de sistemas, amas de casa, enfermeros,
                    veterinarios, maestras, profesores, osteópatas y de todas las
                    profesiones que aún ejercen o que han dejado de ejercer para
                    dedicarse integrando todo a este maravilloso camino.
                  </p>
                  <p>
                    El proceso de la Apertura continúa siendo un camino de
                    incorporación de una nueva lógica que parte de la biología siempre
                    y que llega hasta el espíritu en todas sus manifestaciones. Aquí
                    aprendes a ser Consultor/a en la medida en que te vas sanando a ti
                    mismo/a ya que es imposible que eso no suceda de ese modo. Sucede
                    a la vez y modifica toda tu realidad, no sólo tu cuerpo. Por lo
                    tanto este paso es más fuerte que la Jornada. Allí comprenderás
                    que cada Consultante que llega a tu Consulta, eres tu mismo.
                  </p>
                </div>
                <div
                  className="tab-pane fade p-3"
                  id="nav-paso03"
                  role="tabpanel"
                  aria-labelledby="nav-paso03-tab"
                  tabIndex={0}
                >
                  <h3 className="text-primary">
                    Apertura en Bioexistencia Consciente
                  </h3>
                  <p>
                    El camino recién está completo cuando realizas el Paso 3,{" "}
                    <strong>SER CONSULTOR/A EN BIOEXISTENCIA CONSCIENTE</strong>.
                  </p>
                  <p>
                    Este paso es el más profundo de esta parte del camino, la{" "}
                    <strong>Apertura REORIxINS</strong> (Retorno al Origen por
                    Interpretación de Síntoma) o{" "}
                    <strong>Apertura en Bioexistencia Consciente</strong> (BEC) Para
                    llegar a este paso se debió ir haciendo todo un proceso de
                    transformación interior porque quien realiza la Apertura BEC y
                    pone en práctica lo incorporado de modo coherente, nunca más
                    vuelve a concebir al síntoma ni a la vida toda del mismo modo. Un
                    Consultor en Bioexistencia Consciente ha comprobado en persona y
                    comenzará a comprobar día a día que LA REALIDAD SE CREA EN EL
                    PASADO, SE CREÓ EN EL PASADO Y ALLÍ SE PUEDE VIAJAR, NO IMPORTA EL
                    TIEMPO O LAS GENERACIONES QUE HAYAN SUCEDIDO, de modo vivencial,
                    como en una película, guiado de la mano del mensaje certero del
                    síntoma para llegar al nudo, disolverlo, rescatar a los ancestros
                    involucrados, permitir que expresen, que cuenten todo lo sucedido,
                    ayudarlos, brindarles alivio, comprensión y volver al presente con
                    aquel hecho sanado, con los ancestros liberados y como
                    consecuencia, con otra realidad. Si el pasado cambia el presente
                    no puede ser el mismo.
                  </p>
                  <p>
                    Esta apertura también dura 5 días + 1 día completo de dudas y
                    consultas y ES BRINDADA EN MODO ONLINE EN VIVO A TRAVES DE ZOOM
                    POR PABLO ALMAZÁN Y LUCRECIA BIANCHI A TODO EL MUNDO.{" "}
                    <strong>
                      Cuando finalizas la Apertura BEC eres CONSULTOR/A EN
                      BIOEXISTENCIA CONSCIENTE. Eres un HUMANO PUENTE
                    </strong>
                    . Hay un video llamado "Infertilidad - Presentación de REORIxINS"
                    en esta misma web que puedes ver.
                  </p>
                  <p>Entonces, en total, analicemos los números en días:</p>
                  <ul>
                    <li>Si realizas la Charla Introductoria: 1 día.</li>
                    <li>
                      Jornada Online, en el tiempo que tu sientas dentro de los 3
                      meses de contratada.
                    </li>
                    <li>Apertura DBE = 5 días + 1 (Total 7 hasta aquí)</li>
                    <li>Apertura BEC = 5 Días +1 (Total 13 días)</li>
                  </ul>
                  <p>¿Sólo 13 días en total?</p>
                  <p>
                    <strong>
                      TODOS LOS CONSULTORES QUE ESTÁN HACIENDO LOS MILAGROS LÓGICOS
                      QUE SON CADA VEZ MÁS CONOCIDOS EN EL MUNDO HAN HECHO ESTE CAMINO
                      Y DE ESTE MODO.
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-8 mt-5">
              <h4 className="text-primary">Y luego? Hay más?</h4>
              <p>
                Tambien, una vez finalizada la Apertura en Bioexistencia Consciente
                (el tercer paso) podrás solicitar ser incluido/a en el listado de
                Consultores de la página humanopuente.com para que las personas de
                todo el mundo puedan encontrarte y atenderse contigo via presencial u
                online, como tu elijas. En ese listado podrás ver a los consultores
                por país, estado, localidad, idioma, los seminarios que ha realizado
                para profundizar y especializarse y mucho más.
              </p>
              <p>
                Lo que sigue y lo que va sucediendo a medida que haces tu camino es
                todo un milagro lógico y maravilloso. Con cada persona que atiendas,
                tu sanarás también, despertarás cada vez más y más, tu consciencia
                sobre la vida se irá transformando notablemente porque aquí tienes la
                certeza de estar haciendo lo que el Origen desea manifestar porque, de
                otro modo, nadie sanaría y aquí los sanados son tantos y los milagros
                permanentes son tan enormes que tu vida ya no será la misma, pero
                nadie puede hacerlo por tí. Es un camino personal y vivencial. El
                despertar de la consciencia no puede ser teórico. No se aprende a
                nadar leyendo en un libro, sino tirándose al agua.
              </p>
              <p>
                Además, para quienes desean seguir ampliando, hay seminarios que son
                EXCLUSIVOS PARA CONSULTORES EN BIOEXISTENCIA CONSCIENTE y que se
                brindan siempre en modo online o vivo online a traves de Zoom.
                Seminarios de temas especiales como Obesidad, Bulimia, Anorexia
                (brindado por Pablo Almazan y Lucrecia Bianchi), Histaminosis, SIBO,
                Bases Biológicas desde la lógica de Humano Puente, Iniciación al
                Chamanismo Bioexistente (Brindado por Pablo Almazán y Lucrecia
                Bianchi), Alergias, Fobias y Ataques de Pánico (Brindado por Pablo
                Almazán y Lucrecia Bianchi), Autismo, Asperger y otros TGD (Brindado
                por Pablo Almazán y Lucrecia Bianchi), La Consulta como encuentro
                humano, Numerología aplicada a la Bioexistencia Consciente,
                histaminosis y dolores de cabeza (brindado por Pablo Almazan y
                Lucrecia Bianchi) Economía y Abundancia en coherencia (brindado por
                Pablo Almazan y Lucrecia Bianchi). También hay Congresos anuales para
                Consultores que son MUY IMPORTANTES. La expansión de este conocimiento
                es lo que siente Pablo como misión. La Tierra vibra nuevamente en la
                frecuencia que estamos creando e indica que es hora de que los los
                caminos del retorno se expandan. Cada llamita que se enciende
                iluminamos más. Somos, al fin y al cabo, todos una sola luz.
              </p>
              <p>
                <strong>Un gran abrazo y bienvenido/a ti mismo/a</strong>
              </p>
            </div>
          </div>
        </section>
      </>

    </>


  );
};


export default Camino;
