import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useNavigate } from 'react-router-dom';
import {
  getCategoriaByCode,
} from '@/store/categorias/categoriasActions';
import {
  getEventos, EventoData, getEventosChild
} from '@/store/eventos/eventosActions';
import { parseCopete, parseFecha, slugify } from '@/components/utils';

import { useParams } from 'react-router-dom';


const Eventos: React.FC = () => {

  const decodeEntities = (text: string) => {
    const element = document.createElement("div");
    element.innerHTML = (text.length > 120) ? text.substring(0, 200) + '...' : text;
    return element.innerText;
  }


  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { codigoEvento } = useParams();

  const dataCategoria = useAppSelector((state) => state.categorias?.itemIdData);
  const dataEventos = useAppSelector((state) => state.eventos?.itemsChildData);

  const viewEvento = (item: EventoData) => {
    const path = `/evento-detalle/${item.IdEvento}-${slugify(item.Titulo)}`;
    navigate(path);
  }


  useEffect(() => {
    dispatch(getCategoriaByCode({ code: codigoEvento }));
  }, [dispatch]);


  useEffect(() => {
    dispatch(getEventosChild({ codigo: codigoEvento }));
  }, [dispatch]);




  const renderEventos = (item: any) => {

    const eventosCopy = [...item.eventos];

    const sortedEventos = eventosCopy.sort((a: any, b: any) => {
        const dateA = new Date(a.Fecha);
        const dateB = new Date(b.Fecha);
        return dateB.getTime() - dateA.getTime();
    });

    return sortedEventos.map((evento: any) => (
      <div key={evento.IdEvento} className="col-md-6 col-lg-4 d-flex align-items-stretch">
        <div className="card" style={{ width: '100%' }}>
          <div className="card-header py-3">
            <div className="row align-items-center">
              <div className="col-6">
                {evento.Modalidad!=='Online' && (
                <span className="fecha solo-calendar">{(evento.Fecha) ? parseFecha(evento.Fecha) : ''}</span>
                )}
              </div>
              <div className="col-6 text-end">

                <a href="#"
                  className={
                    evento.Modalidad === 'Online'
                      ? 'btn btn-secondary btn-sm icon-video Online'
                      : evento.Modalidad === 'Presencial'
                        ? 'btn btn-secondary btn-sm icon-presencial Presencial'
                        : evento.Modalidad === 'Video'
                          ? 'btn btn-secondary btn-sm icon-video Video'
                          : 'btn btn-secondary btn-sm  '
                  }
                >
                   {evento.Modalidad==='Video'?'Zoom':evento.Modalidad}
                </a>
              </div>
            </div>
          </div>
          <div className="card-body py-4">
            <h3 className="card-title">
              {evento.Titulo}
            </h3>

            <h6 className="text-secondary">
              {evento.Oradores && evento.Oradores.split(';').map((dataOrador: any, index: number) => (
                <div key={`orador-${index}`}>
                  {dataOrador}
                  <br />
                </div>
              ))}

            </h6>
            <p className="card-text" >
              {decodeEntities(parseCopete(evento))}
            </p>
            <button type="button"
              onClick={() => viewEvento(evento)}
              className="btn btn-outline-primary">
              + info
            </button>
          </div>
        </div>
      </div>

    )).concat(
      item.childs?.map((child: any, index: number) => renderEventos(child))
    );
  }


  const description = " El Camino de Humano Puente es un camino de crecimiento personal. Es una reconcepción de la vida completa, un proceso único de descubrimiento, gracias al síntoma, del verdadero objetivo de vida que se había olvidado. El síntoma viene siempre a decir la verdad.";


  return (

    <>


      <section className="container d-none d-lg-block mt-2">
        <div className="row">
          <div className="col-12">
            <div className="border-top py-2">
              <nav style={{}} aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Inicio</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/actividades">Actividades</a>
                  </li>
                  
                  <li className="breadcrumb-item active" aria-current="page">

                    {(dataCategoria) ? dataCategoria.nombre : ''}

                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <header className="container my-2 my-md-5">
        <div className="row g-4">
          <div className="col-md-6">
            <h6 className="text-secondary">   {(dataCategoria) ? dataCategoria.Subtitle : ''}</h6>
            <h1 className="display-5 mb-4">   {(dataCategoria) ? dataCategoria.nombre : ''}</h1>
          </div>
          <div className="col-md-6 text-start">
            <p className="lead">
              {description}
            </p>
          </div>
        </div>
      </header>

      <section className="container my-4 my-md-5">
        <div className="row g-4"  >

          {dataEventos && renderEventos(dataEventos)}

        </div>
      </section>
    </>



  );
};

export default Eventos;
