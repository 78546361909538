import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import env from '@beam-australia/react-env';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useNavigate } from 'react-router-dom';
import { getLibrosById, LibroData } from '@/store/libros/librosActions';
import { getEventoById } from '@/store/eventos/eventosActions';


const UnidadesFinalizada: React.FC = () => {


  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const dataEvento = useAppSelector((state) => state.eventos?.itemIdData);
  const { userInfo } = useAppSelector(state => state.user);
  const params = new URLSearchParams(window.location.search);
  const idtransaccion = params.get('id');
   

  useEffect(() => {
    if (id) {
      dispatch(getEventoById({ id: parseInt(id) }));
    }

   

  }, [dispatch, id]);


  return (
    <>
      {/* Breadcrumb */}
      <section className="container d-none d-lg-block mt-2">
        <div className="row">
          <div className="col-12">
            <div className="border-top py-2">
              <nav style={{}} aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Inicio</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/misactividades">Mis Actividades</a>
                  </li>
                  <li className="breadcrumb-item active"> {(dataEvento) ? dataEvento.Titulo : ''}</li>

                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* Header */}
      {dataEvento && (
        <>
          <header className="container my-2 my-md-3">
            <div className="row g-4">
              <div className="col-md-12">
                <h1 className="display-4 mb-4">
                  {dataEvento.Titulo}
                </h1>
              
              </div>
            </div>
          </header>
          <section className="container mb-5">
            <div className="row g-4">
             
              <div className="col-md-12">
                <div className="card p-2">
                
                  
                  <div className="card-body py-5">
                    <h1 className="mb-5 text-primary">Felicitaciones</h1>
                    <p>
                        Gracias por completar el evento online {dataEvento.Titulo}
                    </p>
                    <p>
         
						Puede responder una encuesta anónima ingresando en el siguiente <a href="http://bit.ly/2CPSRtz" target='_blank'><strong>link </strong></a>   
                                 para comentarnos lo que piensa acerca de éste.
                    </p>
                    <p>
                    Un gran abrazo
                    </p> 
                  </div>
              
                  <div className="row">
          
                    <div className="col-12 mb-3 d-grid">
                      <a href="/misactividades" className="btn btn-primary">
                        Volver a Mis Actividades
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </>
      )}
    </>

  );
};

export default UnidadesFinalizada;
