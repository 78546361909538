import { createAsyncThunk } from '@reduxjs/toolkit';
import { ValidationErrors } from '@/interfaces/common';
import { axiosData } from '@/axios';

import type { AxiosError, AxiosResponse } from 'axios';
 

const modulePrefix = '/banners';
 
export interface BannerData {
  imagen:  string;
  id:  number;
}

export interface BannerResponse {
  data: BannerData[];
}

export interface BannerProps {
  order?: string;
  activo?: number;
}

export const getBanners  = createAsyncThunk(
  `${modulePrefix}/getBanners`,
  async ({ order, activo }: BannerProps, { rejectWithValue }) => {
    try {
      const data: AxiosResponse = await axiosData.get(`/homebanners`, {
           params: { order, activo },
      });
      const result: BannerResponse = data.data;
      return result;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

 