import { COMAFI, FINANCE_BANK, FRANCES, GALICIA, MERCADO_PAGO, PAYPAL, STRIPE } from "./constants";

export const getStorageKey = (key: string) => {
  const keyStorage = localStorage.getItem(key)
  return keyStorage ? JSON.parse(keyStorage) : null;
} 

export const getStorageKeyString = (key: string): string => {
  const keyStorage = localStorage.getItem(key)
  return keyStorage?keyStorage:"";
} 
 
    export const obtenerMetodosDePago = (valor: number):  Array<{ metodo: string; text: string; leyenda: string }>  => {
        const expectValues = [2, 4, 8, 16, 32, 64,128];
        const formasDePago = [COMAFI, MERCADO_PAGO, PAYPAL,  GALICIA, FINANCE_BANK, FRANCES, STRIPE];
        const texts = ['Transferencia bancaria Comafi', 'MercadoPago', 'Paypal', 'Transferencia bancaria Galicia', 'Transferencia bancaria Finance Bank', 'Transferencia bancaria Frances', 'Stripe'];
        const leyendas = [
          'Al escoger esta opción y presionar el botón <strong class="text-primary">Siguiente</strong> recibirás un mail en la casilla que has brindado con todos los datos para hacer una <strong class="text-primary">transferencia</strong> o <strong class="text-primary">depósito bancario</strong>.',
          'Por este medio de pago podrás abonar con Tarjeta de Crédito y Débito, Rapipago, Pago Fácil, etc.<br>Dependiendo de tu tarjeta de crédito podrás tener diferentes opciones de cuotas/mensualidades sin interés.<br>Este sistema de cobro agrega automáticamente un 5% por el servicio.',
          'Realizar el pago mediante PayPal.<br>Este sistema de cobro agrega automáticamente un 5% por el servicio.',
          'Al escoger esta opción y presionar el botón <strong class="text-primary">Siguiente</strong> recibirás un mail en la casilla que has brindado con todos los datos para hacer una <strong class="text-primary">transferencia</strong> o <strong class="text-primary">depósito bancario</strong>.',
          'Al escoger esta opción y presionar el botón <strong class="text-primary">Siguiente</strong> recibirás un mail en la casilla que has brindado con todos los datos para hacer una <strong class="text-primary">transferencia</strong> o <strong class="text-primary">depósito bancario</strong>.',
          'Al escoger esta opción y presionar el botón <strong class="text-primary">Siguiente</strong> recibirás un mail en la casilla que has brindado con todos los datos para hacer una <strong class="text-primary">transferencia</strong> o <strong class="text-primary">depósito bancario</strong>.',
          'Por este medio de pago podrás abonar con Tarjeta de Crédito y Débito.<br>Dependiendo de tu tarjeta de crédito podrás tener diferentes opciones de cuotas/mensualidades sin interés.<br>Este sistema de cobro agrega automáticamente un 5% por el servicio.'
        ];
       
    const metodosDePago: Array<{ metodo: string; text: string; leyenda: string }> = [];

    for (let i = 0; i < expectValues.length; i++) {
      if (valor & expectValues[i]) {
        metodosDePago.push({
          metodo: formasDePago[i],
          text: texts[i],
          leyenda: leyendas[i]
        });
      }
    }

    return metodosDePago.reverse();
  }


  export const slugify = (text: string) => {
    let slug = text.replace(/[^\w\s]/g, "");
    slug = slug.replace(/\s+/g, "_");
    slug = slug.toLowerCase();
    return slug;
  }


  export const getPriceByGroupPaypal = (dataEvento:any, cotizacion:any) => {

    const group = parseInt(getStorageKeyString('group'));

   if (group) {
     if (group === -1) {
       return (dataEvento?.Precio/cotizacion).toFixed(2);
     }
     if (group === 1) {
       return ((dataEvento?.PrecioGrupo1) && dataEvento?.PrecioGrupo1 > 0) ? dataEvento?.PrecioGrupo1 : dataEvento?.PrecioUsd;
     }
     if (group === 2) {
       return ((dataEvento?.PrecioGrupo2) && dataEvento?.PrecioGrupo2 > 0) ? dataEvento?.PrecioGrupo2 : dataEvento?.PrecioUsd;
     }
   }
   return dataEvento?.PrecioUsd;
 }


  export const getPriceByGroup = (dataEvento:any) => {

     const group = parseInt(getStorageKeyString('group'));

    if (group) {
      if (group === -1) {
        return dataEvento?.Precio;
      }
      if (group === 1) {
        return ((dataEvento?.PrecioGrupo1) && dataEvento?.PrecioGrupo1 > 0) ? dataEvento?.PrecioGrupo1 : dataEvento?.PrecioUsd;
      }
      if (group === 2) {
        return ((dataEvento?.PrecioGrupo2) && dataEvento?.PrecioGrupo2 > 0) ? dataEvento?.PrecioGrupo2 : dataEvento?.PrecioUsd;
      }
    }
    return dataEvento?.PrecioUsd;
  }

  export const getMedioDePagoValue = (dataEvento:any) => {

    const group = parseInt(getStorageKeyString('group'));
    if (dataEvento) {
      if (group) {
        return group === -1
          ? dataEvento.MedioPagoArg
          : dataEvento.MedioPagoExt
      }
      return dataEvento.MedioPagoArg;
    }
    return 0
  }
  export const getMoneda = () => {
    const group = parseInt(getStorageKeyString('group'));
    if (group) {
      if (group === -1) {
        return "ARS";
      }
    }
      return  "USD" ;
  }
  
  export const getPriceDescription = (dataEvento:any) => {

    const group = parseInt(getStorageKeyString('group'));

   if (group) {
     if (group === -1) {
       return "$ " + dataEvento?.Precio;
     }
     if (group === 1) {
       return  "U$D " + ((dataEvento?.PrecioGrupo1) && dataEvento?.PrecioGrupo1 > 0) ? dataEvento?.PrecioGrupo1 : dataEvento?.PrecioUsd;
     }
     if (group === 2) {
       return  "U$D " +  ((dataEvento?.PrecioGrupo2) && dataEvento?.PrecioGrupo2 > 0) ? dataEvento?.PrecioGrupo2 : dataEvento?.PrecioUsd;
     }
   }
   return  "U$D " +  dataEvento?.PrecioUsd;
 }


 export const obtenerParte = (numeroString:string ) => {
  const puntoDecimalIndex = numeroString.indexOf('.');
  const parteEntera = numeroString.substring(0, puntoDecimalIndex);
 
  return    parteEntera ;
};

 export const parseCopete = (dataEvento: any) => {
  const country = getStorageKeyString('countryDesc');
  return dataEvento.Copete.replace("{{pais}}", country).replace("{{precio}}",  obtenerParte(getPriceDescription(dataEvento)));
 
}

export const parseCuerpo = (dataEvento: any) => {
  const country = getStorageKeyString('countryDesc');
  return dataEvento.Cuerpo.replace("{{pais}}", country).replace("{{precio}}",  obtenerParte(getPriceDescription(dataEvento)));
 
}


export const parseLegales = (dataEvento: any, legales:string) => {
  const country = getStorageKeyString('countryDesc');
  const price = obtenerParte(getPriceDescription(dataEvento));

  return legales.replace("{{pais}}", country).replace("{{precio}}", price);
 
}


export const parseFecha = (fecha: string ) => {

  return fecha.toString().substring(8, 10)  + "/" +  fecha.toString().substring(7,5)   + "/" + fecha.toString().substring(0, 4)  
   
 
}


export const parseHora = (fecha: string ) => {

  /*const inputDate = new Date(fecha);

  // Obtener la ubicación del usuario (puede requerir permisos)
  //const userLocale = navigator.language;

 const formattedDateString = inputDate..toLocaleDateString({
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    return formattedDateString;*/

    const inputDate = new Date(fecha); // Fecha proporcionada en el ejemplo

    let hours = parseInt(inputDate.getUTCHours().toString().padStart(2, '0'));
    const minutes = inputDate.getUTCMinutes().toString().padStart(2, '0');
    let ampm = "am";
    if (hours>12)
    {

      hours= hours -12 ;
        ampm = "pm";
    }

    const formattedTime = `${hours}:${minutes} ${ampm}`;
      return " " + formattedTime;
 
}



 