import React,{useEffect} from 'react';
import { useAppSelector } from '@/hooks/redux';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import FormGroup from '@/components/molecules/FormGroup';
 

interface LoginProps {
  onSubmit: (params?: any) => any;
}
interface FormValues {
  email: string;
  codigo: string;
  password: string;
  password2: string;

  
}
interface MyFormProps {
  initialEmail?: string;
  initialcodigo?: string;
  initialpassword?: string;
  initialpassword2?: string;

}

 

const InnerForm = ({ handleSubmit, setFieldValue, values, errors, touched }: FormikProps<FormValues>) => {

 
 

  useEffect(() => {
    values.email =  localStorage.getItem('Email')?.toString() || '';

  }, []);

  const { loading, error } = useAppSelector(state => state.user);

  return (
    <>
    <Form noValidate onSubmit={handleSubmit}  >
        <div className="mb-3">
     
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Email
                      </label>
                      <FormGroup
                              controlId="formCIEmail"
                              inputType="Control"
                              type="text"
                              
                              readOnly={true}
                              placeholder="Email"
                              value={values.email}
                              onChange={e => setFieldValue('email', e?.target.value)}
                              isValid={touched.email && !errors.email}
                              isInvalid={!!errors.email}
                              error={errors.email}
                            />
                  </div>
                  <div className="col-md-12 mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Codigo Acceso - Enviado a su correo electrónico 
                        
                      </label>
                      <FormGroup
                            controlId="formPassword"
                            inputType="Control"
                            type="number"
                            value={values.codigo}
                            placeholder="Codigo Acceso"
                            onChange={e => setFieldValue('codigo', e?.target.value)}
                            isValid={touched.codigo && !errors.codigo}
                            isInvalid={!!errors.codigo}
                            error={errors.codigo}
                        
                          />
                    
          </div>

                  <div className="col-md-12 mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Contraseña
                      </label>
                      <FormGroup
                            controlId="formPassword"
                            inputType="Control"
                            type="password"
                            value={values.password}
                            placeholder="Contraseña"
                            onChange={e => setFieldValue('password', e?.target.value)}
                            isValid={touched.password && !errors.password2}
                            isInvalid={!!errors.password}
                            error={errors.password}
                          />
                    
          </div>
          <div className="col-md-12 mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Repetir Contraseña
                      </label>
                      <FormGroup
                            controlId="formPassword"
                            inputType="Control"
                            type="password"
                            value={values.password2}
                            placeholder="Contraseña"
                            onChange={e => setFieldValue('password2', e?.target.value)}
                            isValid={touched.password2 && !errors.password2}
                            isInvalid={!!errors.password2}
                            error={errors.password2}
                         
                          />
                    
          </div>
                  
 
       
       
      {!loading && error && <div className="invalid-feedback d-block mb-3">{error}</div>}

        <div className="mb-3 d-grid">
          <Button variant="primary" type="submit" className="btn btn-primary"> 
          Confirmar Cambio de Contraseña
          </Button>
        </div>
        </Form>
      </>
  );
};

const ForgottPassword2: React.FC<LoginProps> = ({ onSubmit }) => {
  const MyFormWithFormik = withFormik<MyFormProps, FormValues>({

    
    mapPropsToValues: props => ({
      email:   localStorage.getItem('Email')?.toString() || '',
      codigo: props.initialcodigo || '',
      password: props.initialpassword || '',
      password2: props.initialpassword2 || '',
    }),

    validationSchema: Yup.object().shape({
      email: Yup.string().email('El email no es válido').required('El email es obligatorio'),
      codigo: Yup.string().required('El campo es requerido').length(6, 'El codigo tener 6 digitos'),
      password: Yup.string().required('El campo es requerido').min(6, 'La contraseña debe tener como minimo 6 digitos'),
      password2: Yup.string().required('El campo es requerido').min(6, 'La contraseña debe tener como minimo 6 digitos').oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir'),
  
    }),

    handleSubmit({ email, codigo, password, password2  }: FormValues) {
      onSubmit({ Email: email, codigo, password, password2   });
    },
  })(InnerForm);

  return <MyFormWithFormik />;
};

export default ForgottPassword2;
